import { defineStore } from 'pinia'

import getters from './getters';
import actions from './actions';

export const useModelesZonesStore = defineStore('modeleszones', {
    state: () => ({
        modeleAffiche: null,
        entites: [],
        modeles: [],
        errorsList: {}
    }),
    getters,
    actions,
})