export default{
  getTables(){
    return this.tables;
  },

  getTable(){
    return this.table;
  },

  getLoaded(){
    return this.loaded;
  },

  getTableErrors(){
    return this.tableErrors;
  },

  getTableUpdateReturnCode(){
    return this.tableUpdateReturnCode;
  },

  getDataset(){
    return this.dataset;
  }
}