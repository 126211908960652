import 'mdb-vue-ui-kit/css/mdb.min.css';
import 'v-calendar/dist/style.css';

import { createApp }    from 'vue'
import pinia            from '@/pinia/index.js';

import App              from './App.vue'
import BaseButton       from './components/ui/BaseButton.vue'
import BaseCard         from './components/ui/BaseCard.vue'
import BasePageTitle    from './components/ui/BasePageTitle.vue'
import BaseModalConfirm from './components/ui/BaseModalConfirm.vue'
import BaseAlert        from './components/ui/BaseAlert.vue'
import BaseStyleBox     from './components/ui/BaseStyleBox.vue'
import BaseToast        from './components/ui/BaseToast.vue'
import BaseBreadcrump   from './components/ui/BaseBreadcrump.vue';
import BaseZoneError    from "@/components/ui/BaseZoneError.vue";
import BaseMessage      from "@/components/ui/BaseMessage.vue";

import router           from './router'
import {useConnexionsStore} from "@/pinia/connexions/connexions";
import {useAbonnesStore} from "@/pinia/subscribers/subscribers";
import {useAgendaStore} from "@/pinia/agenda/agenda";
import {useCommunesStore} from "@/pinia/communes/communes";
import {useLogsStore} from "@/pinia/logs/logs";
import {useMenusStore} from "@/pinia/menus/menus";
import {useParametresStore} from "@/pinia/parametres/parametres";
import {useTablesStore} from "@/pinia/tables/tables";
import {useUtilisateursStore} from "@/pinia/users/users";
import {useEntitesStore} from "@/pinia/entites/entites";
import {useZonesStore} from "@/pinia/zones/zones";
import {useClientsStore} from "@/pinia/clients/clients";

// import store  from './store'

const app = createApp(App);
// app.use(store);
app.use(router);

app.use(pinia);

window.connexionsStore   = useConnexionsStore();
window.abonnesStore      = useAbonnesStore();
window.agendaStore       = useAgendaStore();
window.communesStore     = useCommunesStore();
window.logsStore         = useLogsStore();
window.menusStore        = useMenusStore();
window.parametresStore   = useParametresStore();
window.tablesStore       = useTablesStore();
window.utilisateursStore = useUtilisateursStore();
window.entitesStore      = useEntitesStore();
window.zonesStore        = useZonesStore();
window.clientsStore      = useClientsStore();
app.component('base-button'       , BaseButton       );
app.component('base-card'         , BaseCard         );
app.component('base-page-title'   , BasePageTitle    );
app.component('base-modal-confirm', BaseModalConfirm );
app.component('base-alert'        , BaseAlert        );
app.component('base-style-box'    , BaseStyleBox     );
app.component('base-toast'        , BaseToast        );
app.component('base-breadcrump'   , BaseBreadcrump   );
app.component('base-zone-error'   , BaseZoneError    );
app.component('base-message'      , BaseMessage      );
app.config.errorHandler = (err, instance, info) => {
    if(err == 511 ){
        router.push('/auth');
    }
}
app.mount('#app');
