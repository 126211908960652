export default {
    getDataset(){
        return this.dataset;
    },

    getEntite(){
        return this.entite;
    },

    getEntiteError() {
        return this.entiteError;
    },

    getEntiteUpdateReturnCode(){
        return this.entiteUpdateReturnCode;
    }
}