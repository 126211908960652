import { defineStore } from 'pinia';
import { useUserStore } from '../user/user';

import getters   from './getters';
import actions   from './actions';

export const useAgendaStore = defineStore('agenda',{
  
  state: () => {
    return {
      events                 : [],
      isModalVisible         : false,
      selectingMode          : false,
      savedEvent             : null,
      typesOfEvents          : null,
      agendaErrors           : null,
      agendaUpdateReturnCode : 0,
      usersCombo             : [],
      clientsCombo           : [],
      parametresAffichage    : null
    };
  },

  // set getters from the "getters" object
  getters,

  // same thing for actions
  actions
});