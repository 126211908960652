import { useAuthStore }        from "../auth/auth";
import { useUserStore }        from "../user/user";
import { useApplicationStore } from "../application/application";

export default {

  async retrieveData(payload=null) {
    const applicationStore = useApplicationStore();
    const userStore        = useUserStore();
    const authStore        = useAuthStore();

    console.log('tables - actions retrieveTables - begin');

    let serverAddress = userStore.getServerAddress;

    console.log('tables - action retrieveTables - serverAddress='+serverAddress);

    let url = serverAddress + "/artizen/ws/htables/listedatatable";

    if(payload!=null && Object.keys(payload).length > 0){ // --- we first verify if our payload is not null and if it's not empty
      url+="?";
      let index = 0;

      // --- we now iterate over the payload containing the search filters the user chose
      for(const data in payload){
          url+=data+"="+payload[data]; // --- data corresponds to the name of the attribute (user for instance)
                                       // --- and payload[data] gets the value in the payload linked to the property name

          if(index < Object.keys(payload).length-1){ // --- in the end we check if we reached the end of the payload or not
              url+="&";                              // --- and if it's not, we add & to continue adding query elements
          }
      }
    }
    console.log("tables - action retrieveTables - url="+url);

    let token = authStore.getToken;

    // call the ws and wait for his answer
    const response = await fetch(url,
            {
                method: 'GET',
                headers: {
                    Authorization: 'JWT ' + token
                }
            });

    // if we had an error calling the ws
    if (!response.ok) {
        if(response.status == 511){
            authStore.tryLogin();
        } else {
            const error = new Error('Failed to get user info. Check your login data.');
            throw error;
        }

    }

        // retrieve the response from the ws
        let tableResponse = await response.text();

        console.log('********** tablesResponse **********');
        console.log("tables - action retrieveTables - tableResponse="+tableResponse);
        console.log("tables - action retrieveTables - tableResponse.type="+typeof tableResponse);
        
        // --- parse the response as a json message -----------------------------------------------
        const jsonTableResponse = JSON.parse(tableResponse);
        console.log(jsonTableResponse.dataset);
        let payloadTables = jsonTableResponse.dataset;
        applicationStore.commitSetMessage(jsonTableResponse.message);
        
        console.log("tables - action retrieveTables - payloadTables="+payloadTables);
        console.log("tables - action retrieveTables - payloadTables.type="+typeof payloadTables);
        
        try {
            let jsonPayloadTables = JSON.parse(payloadTables);
            if(jsonPayloadTables.rows.length>0){
                console.log(jsonPayloadTables.rows[0].code);
            } else {
                console.log('Recherche introuvable');
            }
            

            console.log("tables - action retrieveTables - jsonPayloadTables     ="+JSON.stringify(jsonPayloadTables));
            console.log("tables - action retrieveTables - jsonPayloadTables.type="+typeof jsonPayloadTables);
            // --- set the dataset value in the context -----------------------------------------------
            this.setDataset(jsonPayloadTables);

            console.log("tables - action retrieveTables - after commit setDataSet=");

        }
        catch (e) {
            console.log("error parsing payloadTables=" + e.toString())
        }
        finally {
            console.log('action retrieveTables - end');
        }
  },
  setDataset(payload) {
    console.log('mutation users.setDataset - begin');
    this.dataset = payload;
    console.log('mutation users.setDataset='+JSON.stringify(this.dataset));
    console.log('mutation users.setDataset - end');
  },

  async retrieveTable(payload) {
    const userStore = useUserStore();
    const authStore = useAuthStore();
    // context.commit('setUser', payload);

    // --- get the address of the web service server ------------------------------------------
    let serverAddress = userStore.getServerAddress;

    // --- construct the url ------------------------------------------------------------------
    let sub = !!payload.abonne ? 'abonne='+payload.abonne+'&' : ''; 
    let url = serverAddress + '/artizen/ws/htables/lire?'+ sub + 'table=' + payload.table.toUpperCase() + '&code=' + payload.code.toUpperCase();
    console.log(url);
    // --- retrieve the token from auth store
    let token = authStore.getToken;

    // call the ws and wait for his answer
    const response = await fetch(url,
        {
            method: 'GET',
            headers: {
                Authorization: 'JWT ' + token
            }
//        body: JSON.stringify({
//          email: payload.email,
//          password: payload.password,
//          returnSecureToken: true
//        })
        });

    // if we had an error calling the ws
    if (!response.ok) {
        if(response.status==511){
            authStore.tryLogin();
        } else {
            const error = new Error('Failed to get user info. Check your login data.');
            throw error;
        }

    }

    // retrieve the reponse from the ws
    let tableResponse = await response.text();

    console.log('********** tableResponse **********');
    console.log(tableResponse);

    // parse the response as a json message
    const jsonTableResponse = JSON.parse(tableResponse);
    console.log(jsonTableResponse);
    // we store the infos in the vueX calling the setUser mutation
    jsonTableResponse.actif = jsonTableResponse.actif=="O" ? true : false;
    this.commitSetTable(jsonTableResponse);
    console.log('action getUser - fin');

},

commitSetTable(table){
    console.log('PIJFOIJDJPDPOJFPOJDPO');
    this.table = table;
},

async deleteElement(payload) {
    const userStore = useUserStore();
    const authStore = useAuthStore();
    console.log('action users.deleteUser - begin');
    console.log(payload);


    // --- get the address of the web service server ------------------------------------------
    let serverAddress = userStore.getServerAddress;
    let sub  = !!payload.abonne ? "abonne=" + payload.abonne + '&' : "";
    let code = !!payload.code   ? "&code="  + payload.code         : '';
    // --- construct the url ------------------------------------------------------------------
    let url = serverAddress + '/artizen/ws/htables/supprimer?'+ sub + 'table=' + payload.table + code;

    // retrieve the token from auth store
    let token = authStore.getToken;

    // call the ws and wait for his answer
    const response = await fetch(url,
      {
          method: 'PUT',
          headers: {
              Authorization: 'JWT ' + token
          },
          // body: JSON.stringify(
          //   {
          //       "abonne":         subscriber,
          //       "utilisateur":    user,
          //       "email":          "",
          //       "idImage":        0,
          //       "motDePasse":     "",
          //       "motDePasseConf": "string",
          //       "nom":            "",
          //       "prenom":         "",
          //       "roles":          "",
          //       "actif":          "",
          //       "supprime":       ""
          //   })
      });

    // if we had an error calling the ws
    if (!response.ok) {
        if(response.status == 511){
           authStore.tryLogin();
        } else {
            const error = new Error('Failed to execute the web service.');
            throw error;
        }

    }

    // retrieve the reponse from the ws
    let userResponse = await response.text();

    console.log('********** userResponse **********');
    console.log(userResponse);

    console.log('action users.deleteUser - end');

},

    async createTable(payload){
        const userStore = useUserStore();
        const authStore = useAuthStore();
        console.log('action tables.createTable - start');
        console.log(payload);
        // --- get the address of the web service server ------------------------------------------
        let serverAddress = userStore.getServerAddress;

        // --- construct the url ------------------------------------------------------------------
        let url = serverAddress + '/artizen/ws/htables/creer';

        // --- retrieve the token from auth store -------------------------------------------------
        let token = authStore.getToken;

        let myActive  = (payload.actif ?  'O':'N')
        console.log(payload);
        // --- call the ws and wait for his answer ------------------------------------------------
        const response = await fetch(url,
          {
              method: 'PUT',
              headers: {
                  "Authorization": 'JWT ' + token,
                  "accept":        "application/json",
                  "Content-Type":  "application/json",
              },
              body: JSON.stringify({
                  abonne      : payload.abonne,
                  table       : payload.table,
                  code        : payload.code,
                  valeur      : payload.valeur,
                  actif       : myActive,
                  modifiable  : payload.modifiable,
                  supprimable : payload.supprimable
              })
          });

        if (!response.ok) {

            // --- if we had an error while calling the web service -------------------------------
            console.log("createTable, response is not ok");
            console.log(response);

            if (response.status === 404) {
                let errorResponse = await response.text();

                console.log("errorResponse=");
                console.log(errorResponse);

                let jsonErrorResponse = JSON.parse(errorResponse);

                console.log("jsonErrorResponse=");
                console.log(jsonErrorResponse);

                this.setTableErrors(jsonErrorResponse);
                this.setTableUpdateReturnCode(404);
            } else if(response.status == 511){
                this.setTableErrors(null);
                this.setTableUpdateReturnCode(511);
                throw 511;
            } else {
                this.setTableErrors(null);
                this.setTableUpdateReturnCode(999);
                const error = new Error('Failed to set user info. Check your login data.');
                throw error;
            }
        }
        else {
            // result is ok for the user creation
            this.setTableErrors(null);
            this.setTableUpdateReturnCode(200);
        }

        console.log('action users.createUser - end');
    },

    setTableErrors(payload) {
        console.log('mutation users.setTableErrors - begin');
        this.tableErrors = payload;

        console.log("state.userErrors=");
        console.log(this.tableErrors);

        console.log('mutation tables.setTableErrors - end');
    },

    // ----------------------------------------------------------------------------------
    // update the userUpdateReturnCode object in the store
    // ----------------------------------------------------------------------------------
    setTableUpdateReturnCode(payload) {
        console.log('mutation users.setTableUpdateReturnCode - begin');
        this.tableUpdateReturnCode = payload;

        console.log("state.userUpdateReturnCode=");
        console.log(this.tableUpdateReturnCode);

        console.log('mutation tables.setTableUpdateReturnCode - end');
    },

    async setTable(payload){
        const userStore = useUserStore();
        const authStore = useAuthStore();
        console.log('action tables.setTables - start');


        // --- get the address of the web service server ------------------------------------------
        let serverAddress = userStore.getServerAddress;

        // --- construct the url ------------------------------------------------------------------
        let url = serverAddress + '/artizen/ws/htables/modifier';

        // retrieve the token from auth store
        let token = authStore.getToken;

        let myActive  = (payload.actif ?  'O':'N')

        // call the ws and wait for his answer
        const response = await fetch(url,
            {
                method: 'PUT',
                headers: {
                    "Authorization": 'JWT ' + token,
                    "accept":        "application/json",
                    "Content-Type":  "application/json",
                },
                body: JSON.stringify({
                    abonne      : payload.abonne,
                    table       : payload.table,
                    code        : payload.code,
                    valeur      : payload.valeur,
                    actif       : myActive,
                    modifiable  : payload.modifiable,
                    supprimable : payload.supprimable
                })
            });

        if (!response.ok) {

            // if we had an error while calling the web service
            console.log("setTables, response is not ok");
            console.log(response);

            if (response.status === 404) {
                let errorResponse = await response.text();

                console.log("errorResponse=");
                console.log(errorResponse);

                let jsonErrorResponse = JSON.parse(errorResponse);

                console.log("jsonErrorResponse=");
                console.log(jsonErrorResponse);

                this.setTableErrors(jsonErrorResponse);
                this.setTableUpdateReturnCode(404)
            } else if(response.status == 511){
                this.setTableErrors(null);
                this.setTableUpdateReturnCode(511);
                throw 511;
            } else {
                this.setTableErrors(null);
                this.setTableUpdateReturnCode(999)
                const error = new Error('Failed to set user info. Check your login data.');
                throw error;
            }
        }
        else {
            // result is ok for the user update
            this.setTableErrors(null);
            this.setTableUpdateReturnCode(200)

            // we store the infos in the vueX calling the setUser mutation
            this.commitSetTable({
                abonne      : payload.abonne,
                table       : payload.table,
                code        : payload.code,
                valeur      : payload.valeur,
                actif       : myActive,
                modifiable  : payload.modifiable,
                supprimable : payload.supprimable
            });
        }

        console.log('action users.setUser - end');
    },

    updateTable(payload) {
        console.log('mutation tables.updateTable - begin');

        console.log(">>> tables.updateTable - payload");
        console.log(payload);
        console.log(">>> dataset before changes <<<")
        console.log(this.dataset.rows);

        let localSubscriber =  payload.abonne;


        this.dataset.rows.forEach((element, index) => {
            if ((this.dataset.rows[index].abonne === localSubscriber)) {
                this.dataset.rows[index].actif = payload.actif ? 'O' : 'N',
                this.dataset.rows[index].table = payload.table,
                this.dataset.rows[index].code = payload.code,
                this.dataset.rows[index].valeur = payload.valeur,
                this.dataset.rows[index].modifiable = payload.modifiable,
                this.dataset.rows[index].supprimable = payload.supprimable
            }
        });

        console.log(">>> tables.updateTable - dataset");
        console.log(this.dataset.rows);

        console.log('mutation tables.updateTable - end');
    },


}