export default {

    // Returns the value of the messages variable ---------------------------------------------------------------------- getMessages
    getMessages(){
        return this.messages;
    },

    // Returns the value of the users variable ------------------------------------------------------------------------- getUsers
    getUsers(){
        return this.users;
    },

    // Returns the value of the actions variable ----------------------------------------------------------------------- getActions
    getActions(){
        return this.actions;
    }
}