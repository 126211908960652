import { defineStore } from 'pinia';

import getters from './getters';
import actions from './actions';

export const useApplicationStore = defineStore('application', {
    state: () => {
        return {
            roles              : null, // will be equal to an array composed of all the roles
            message            : null,
            dataLoaded         : false,
            nbLignesDatatable  : null,
            logsMessage        : null,
            isSmallScreen      : null,
            lastPageOnList     : 0
        }
    },
    getters,
    actions
})