<template>
    <h2 class="lines">
        <slot></slot>
    </h2>
</template>

<script>
export default {
    props: {
        color: {
            Type: "String",
            required: false
        }
    }
}
</script>

<style scoped>
    .lines{
        overflow: hidden;
        text-align: center;
        margin: 2rem 1rem 1rem 1rem;
        color: #6d6d6d;
    }

    .lines::before, .lines::after{
        background-color:#6d6d6d ;
        content: "";
        display: inline-block;
        height: 2px;
        position: relative;
        vertical-align: middle;
        width: 50%;
        border-radius: 50px;
    }

    .lines::before{
        right: 0.5em;
        margin-left: -60%;
    }

    .lines::after{
        left: 0.5em;
        margin-right: -60%;
    }

    @media screen and (max-width:1195px){
        .lines{
            font-size: 1.4em;
        }
    }

    @media screen and (max-width:940px) {
        .lines{
            font-size: 1.2em;
        }
    }

    @media screen and (max-width:400px) {
        .lines{
            font-size: 1em;
        }
    }
</style>