export default {
    // --- Getter for the roles variable -------------------------------------------------------- getRoles
    getRoles(){
        return this.roles;
    },

    // --- Getter for the roles variable -------------------------------------------------------- getRoles
    getMessage(){
        return this.message;
    },

    // --- Getter for the dataLoaded variable --------------------------------------------------- getDataLoaded
    getDataLoaded(){
        return this.dataLoaded;
    },

    // --- Getter for the nbLignesDatatable variable -------------------------------------------- getNbLignesDatatable
    getNbLignesDatatable(){
        return this.nbLignesDatatable;
    },

    getIsSmallScreen(){
        return this.isSmallScreen;
    },

    getLastPageOnList(){
        return this.lastPageOnList;
    }

}