<template>
  <nav aria-label="breadcrumb" v-if="breadPath.length>0">
    <MDBBreadcrumb class='m-4'>
      <div v-for="menu in breadPath" :key="menu">
        <MDBBreadcrumbItem v-if="menu != breadPath[breadPath.length-1]">
          &nbsp;<router-link :to="`/menu/${menu.toLowerCase()}`">{{menu}}</router-link> /
        </MDBBreadcrumbItem>
        <MDBBreadcrumbItem v-else active>
          &nbsp;{{menu}}
        </MDBBreadcrumbItem>
      </div>
    </MDBBreadcrumb>
    </nav>
    <nav aria-label="breadcrumb" v-else>
    <MDBBreadcrumb class='m-4'>
      <div v-for="menu in tree" :key="menu">
        <MDBBreadcrumbItem v-if="menu != tree[tree.length-1]">
          &nbsp;<router-link :to="`/menu/${menu.toLowerCase()}`">{{menu}}</router-link> /
        </MDBBreadcrumbItem>
        <MDBBreadcrumbItem v-else active>
          &nbsp;{{menu[0].toUpperCase() + menu.substring(1)}}
        </MDBBreadcrumbItem>
      </div>
    </MDBBreadcrumb>
    </nav>
</template>

<script>
import { MDBBreadcrumb, MDBBreadcrumbItem } from 'mdb-vue-ui-kit';
import { useUserStore        }              from '@/pinia/user/user';
import { useRouter }                        from 'vue-router';
import { ref, computed }                    from 'vue';
export default {
  components: {
    MDBBreadcrumb,
    MDBBreadcrumbItem
  },
  props: {
    tree: {
      type: Array,
      required: false,
      default:  null,
    }
  },
  setup(props) {
    const userStore        = useUserStore();
    const router           = useRouter();
    let breadPath          = ref([]);
    if(props.tree == null){
      let menus          = userStore.getMenus;
      const routerPath   = '/' + router.currentRoute.value.fullPath.split('/')[1];
      console.log(routerPath);
      console.log(menus);
      let currentPage = menus.find(menu => menu.path == routerPath);
      breadPath.value = currentPage.arborescence.split(',');
      for(let path in breadPath.value){
        breadPath.value[path] = breadPath.value[path][0].toUpperCase() + breadPath.value[path].substring(1)
      }
    }
    console.log('=================BREAD=====================');
    console.log(breadPath);
    return {
      breadPath,
    }
}
}
</script>

<style scoped>

</style>