<template>
    <base-modal-confirm @confirm="cancelEdit" @cancel="exitModal = !exitModal" v-model="exitModal" btn-confirm="Quitter">
        <p>Êtes-vous sûr de vouloir quitter la page ? Toute information non enregistrée sera perdue</p>
    </base-modal-confirm>
    <MDBRow class="m-0">
    <MDBCol offsetMd="3" md="6">
    <base-card>
        <MDBCol>
            <base-page-title v-if      = "activeUserDetails"                      > Mes informations                      </base-page-title>
            <base-page-title v-else-if = "!activeUserDetails && isNotCreateMode " > Informations de l'utilisateur         </base-page-title>
            <base-page-title v-else                                               > Nouvel Utilisateur                    </base-page-title>
        </MDBCol>
    <div v-if="isLoading" class="d-flex justify-content-center mt-5">
        <MDBSpinner></MDBSpinner>
    </div>
    <form v-else-if="!isLoading" @submit.prevent="submitForm" class="m-4 row g-3" autocomplete="off">

        <input type="text"     style="display: none">
        <input type="password" style="display: none"><!-- the last two lines are there to prevent the browser to autofill the real inputs
                                                          with previously stored credentials -->
        <MDBCol md="fill">
        <MDBInput
            v-if             = "subscriberIsSeeable"
            type             = "text"
            label            = "Abonné"
            v-model          = "currentUser.subscriber"
            :disabled        = "isNotCreateMode || isAdminNotHaz"
            :invalidFeedback = "formMessages.messageSubscriber"
            :isValid         = "formMessages.validSubscriber"
            :isValidated     = "formMessages.formIsValidated"
        />
        </MDBCol>
        <MDBCol md="fill">
        <MDBInput
            v-if             = "userIsSeeable"
            type             = "text"
            label            = "Utilisateur"
            v-model          = "currentUser.user"
            :disabled        = "isNotCreateMode"
            :invalidFeedback = "formMessages.messageUser"
            :isValid         = "formMessages.validUser"
            :isValidated     = "formMessages.formIsValidated"
        />
        </MDBCol>
        
        <MDBCol md="fill">
        <MDBInput
            type             = "text"
            label            = "Adresse email"
            v-model          = "currentUser.email"
            :invalidFeedback = "formMessages.messageEmail"
            :isValid         = "formMessages.validEmail"
            :isValidated     = "formMessages.formIsValidated"
        />
        </MDBCol>
        <MDBCol md="fill">
        <MDBRow>
            <MDBCol>
                <MDBInput
                    type             ="text"
                    label            = "Prénom"
                    v-model          = "currentUser.firstname"
                    :invalidFeedback = "formMessages.messageFirstName"
                    :isValid         = "formMessages.validFirstName"
                    :isValidated     = "formMessages.formIsValidated"
                />
            </MDBCol>
            <MDBCol>
                <MDBInput
                    type             = "text"
                    label            = "Nom de famille"
                    v-model          = "currentUser.lastname"
                    :invalidFeedback = "formMessages.messageLastName"
                    :isValid         = "formMessages.validLastName"
                    :isValidated     = "formMessages.formIsValidated"
                />
            </MDBCol>
        </MDBRow>
        </MDBCol>
        <MDBCol md="fill">
        
        <MDBSelect label="Roles" 
                   v-model:options="rolesOptions" 
                   v-model:selected="selectedRoles" 
                   :disabled="!isAdmin"
                   multiple 
                   :selectAll="false"
                   :invalidFeedback = "formMessages.messageRoles"
                   :isValid         = "formMessages.validRoles"
                   :isValidated     = "formMessages.formIsValidated"
                   />
        </MDBCol>
        <MDBCol md="fill">
        <base-style-box v-if="isAdmin &&  isNotCreateMode" label="Etat de l'utilisateur">
            <div>
                <MDBSwitch label="Actif"    
                           v-model="currentUser.active"  
                           @click           = "setActiveState('actif')"
                           :invalidFeedback = "formMessages.messageActive"
                           :isValid         = "formMessages.validActive"
                           :isValidated     = "formMessages.formIsValidated"/>
            </div>
            <div class="mb-2">
                <MDBSwitch label="Supprimé" 
                           v-model="currentUser.deleted" 
                           @click           = "setActiveState('supprime')"
                           :invalidFeedback = "formMessages.messageDeleted"
                           :isValid         = "formMessages.validDeleted"
                           :isValidated     = "formMessages.formIsValidated"/>  
            </div>
        </base-style-box>
        </MDBCol>
        <MDBCol md="fill">
        <base-style-box v-if="passwordIsSeeable" label="Nouveau mot de passe">
        <MDBRow >
            <MDBCol>
                <MDBInput
                    type             = "password"
                    label            = "Mot de passe"
                    v-model          = "currentUser.password"
                    :invalidFeedback = "formMessages.messagePassword"
                    :isValid         = "formMessages.validPassword"
                    :isValidated     = "formMessages.formIsValidated"
                />
            </MDBCol>
            <MDBCol>
                <MDBInput
                    type             = "password"
                    label            = "Confirmation"
                    :disabled        = "!passwordIsFilled"
                    v-model          = "currentUser.passwordConf"
                    :invalidFeedback = "formMessages.messagePasswordConf"
                    :isValid         = "formMessages.validPasswordConf"
                    :isValidated     = "formMessages.formIsValidated"
                />
            </MDBCol>
        </MDBRow>
        </base-style-box>
        </MDBCol>
        <MDBCol md="fill">
        <div class="d-flex flex-row-reverse align-items-center">
            <base-button mode="enregistrer"  
                         type="submit" 
                         :disabled="noModificationDone" 
            > Enregistrer </base-button>
            
            <base-button mode="supprimer"   
                         @click.prevent = "tryExitting"         
            > Quitter     </base-button>
        </div>
        </MDBCol>
    </form>
    </base-card>
    
    </MDBCol>
    </MDBRow>
</template>

<script>
import { MDBRow, MDBCol, MDBInput, MDBSpinner, MDBModal, MDBModalBody } from 'mdb-vue-ui-kit'
import { MDBRadio, MDBModalFooter, MDBSelect, MDBBtn, MDBSwitch }       from 'mdb-vue-ui-kit'
import { useAuthStore }                                                 from '@/pinia/auth/auth.js'
import { useUserStore }                                                 from '@/pinia/user/user.js'
import { useUtilisateursStore }                                                from '@/pinia/users/users.js' 
import { useApplicationStore }                                          from '@/pinia/application/application.js'
import { useRouter }                                                    from 'vue-router'
import { ref, reactive, computed }                                      from 'vue'

export default {
    name: "User",
    components: {
        MDBRow,
        MDBCol,
        MDBInput,
        MDBSpinner,
        MDBModal,
        MDBModalBody,
        MDBModalFooter,
        MDBSelect,
        MDBBtn,
        MDBRadio,
        MDBSwitch
    },
    props: {
        subscriber: {
            Type     : String,
            required : false,
            default  : ''
        },
        user: {
            Type     : String,
            required : false,
            default  : ''
        },
        mode: {
            Type     : String,
            required : false,
            default  : 'consulter'
        }
    },
    setup(props) {
        // --- Stores definition ------------------------------------------------------------
        const authStore        = useAuthStore();
        const userStore        = useUserStore();
        const utilisateursStore       = useUtilisateursStore();
        const applicationStore = useApplicationStore();
    

        // --- Router definition ------------------------------------------------------------
        const router = useRouter();

        // --- Refs and Reactive definition -------------------------------------------------
        let currentUsername   = ref(props.user);
        let currentSubscriber = ref(props.subscriber);
        let currentMode       = ref(props.mode);
        let activeUserDetails = ref(false);
        let isLoading         = ref(false);
        let currentUser       = ref("");
        let exitModal         = ref(false);
        let initialUser       = reactive({});
        let formMessages      = reactive({
            validFirstName      : false,
            messageFirstName    : '',
            validLastName       : false,
            messageLastName     : '',
            validEmail          : false,
            messageEmail        : '',
            validSubscriber     : false,
            messageSubscriber   : '',
            validUser           : false,
            messageUser         : '',
            validPassword       : false,
            messagePassword     : '',
            validPasswordConf   : false,
            messagePasswordConf : '',
            validDeleted        : false,
            messageDeleted      : '',
            validActive         : false,
            messageActive       : '',
            validRoles          : false,
            messageRoles        : '',
            formIsValidated     : false
            
        })
        const selectedRoles     = ref("");
        const rolesOptions      = ref([]);
        
        // console.log(rolesOptions);
        // --- Actions to do on setup -------------------------------------------------------
        if (!authStore.getIsAuthenticated) {
            console.log("UserList - user not authenticated, go to the authentication page");
            router.replace("/auth");
        }
        for(const role of applicationStore.getRoles){
            rolesOptions.value.push({
                text     : role,
                value    : role,
                selected : false
            })
        }
        if(currentMode.value!=="creation"){
            
            if(!currentUsername.value && !currentSubscriber.value && currentMode!="creation"){
                currentUsername.value   = userStore.getUser;
                currentSubscriber.value = userStore.getSubscriber;
                activeUserDetails.value = true;
            }

            try{
              fetchDatas();
            } catch(e){
              throw 511;
            }
        } else{
            currentUser.value = reactive({
                user         : ""     ,
                subscriber   : ""     ,
                firstname    : ""     ,
                lastname     : ""     ,
                email        : ""     ,
                password     : ""     ,
                roles        : ""     ,
                passwordConf : ""     ,
                active       : true   ,
                deleted      : false  ,
                image        : -1
            })
            if(userStore.getIsAdmin && !userStore.getIsAdminHaz){
                currentUser.value.subscriber = authStore.getSubscriber;
            }
            initialUser = Object.assign({},currentUser.value);
        }
        // fetchDatas();
        
        // --- Computed properties ----------------------------------------------------------

        const isNotCreateMode = computed(()=>{
            return currentMode.value!=="creation";
        })

        const isAdminNotHaz = computed(() => {
            return userStore.getIsAdmin && !userStore.getIsAdminHaz;
        })

        const isAdmin = computed(() => {
            return userStore.getIsAdmin;
        }) 

        const passwordIsFilled = computed(() => {
            if(!!currentUser.value.password){
                return true;
            } else {
                currentUser.value.passwordConf = '';
                return false;
            }
        })


        const passwordIsSeeable = computed(() => {
            if(currentUser.value.subscriber === authStore.getSubscriber && currentUser.value.user === authStore.getUser 
            || userStore.getIsAdmin){
                return true;
            } else {
                return false;
            }
        })

        const subscriberIsSeeable = computed(()=>{
            if(currentMode.value!=="creation" || userStore.getIsAdmin){
                return true;
            } else {
                return false
            }
        })

        const userIsSeeable = computed(() => {
            if(currentMode.value!=="creation" || userStore.getIsAdmin){
                return true;
            } else {
                return false
            }
        })

        const noModificationDone = computed(() => {
            let currentUserObj = Object.assign({}, currentUser.value);
            console.log(currentUserObj);
            console.log(initialUser);
            currentUserObj.roles = selectedRoles.value;
            return JSON.stringify(currentUserObj) === JSON.stringify(initialUser);
        })

        // --- Functions -------------------------------------------------------------------
        function setActiveState(origin){
            if(origin==='supprime' &&currentUser.value.active && !currentUser.value.deleted){
                currentUser.value.active = false;
            } else if(origin==='actif' && !currentUser.value.active && currentUser.value.deleted){
                currentUser.value.deleted = false;
            }
        }

        async function fetchDatas(){
            let payload = {
                subscriber: currentSubscriber.value,
                user:       currentUsername.value
            }
            console.log(payload);
            isLoading.value   = true;
            try{
              await utilisateursStore.retrieveUser(payload);
            } catch(e){
              throw 511;
            }
            console.log(utilisateursStore.getUser)
            currentUser.value              = Object.assign({},utilisateursStore.getUser);
            console.log(currentUser.value)
            initialUser = Object.assign({},currentUser.value);
            console.log(initialUser)
            let rolesToArray = initialUser.roles.split(',');
            await setSelectedRoles(rolesToArray);
            console.log(initialUser.roles);
            console.log(selectedRoles);
            isLoading.value = false;
        }

        async function setSelectedRoles(roles){
            for (const role of rolesOptions.value){
                for (const roleUser of roles){
                    if(role.value == roleUser){
                        role.selected = true;
                    }
                }
            }
        }


        function tryExitting() {
                let currentUserObj = Object.assign({}, currentUser.value);
                currentUserObj.roles = selectedRoles.value;
                console.log(currentUserObj);
                console.log(initialUser);
                if(JSON.stringify(currentUserObj) !== JSON.stringify(initialUser)){
                    exitModal.value = true;
                }
                else {
                    cancelEdit();
                }
        }

        function cancelEdit(){
            exitModal.value = false;
            if(currentMode.value == "creation" 
            || !(currentUser.value.subscriber === authStore.getSubscriber && currentUser.value.user === authStore.getUser)){
                router.push('/utilisateurs');
            } else {
                router.back();
            }
        }

        async function submitForm(){
            let currentUserObj = Object.assign({}, currentUser.value);
            currentUserObj.roles = selectedRoles.value;
            if(JSON.stringify(currentUserObj) === JSON.stringify(initialUser)){
                return;
            }
            isLoading.value = true;

            
            try{
                if(currentMode.value==='creation'){
                    await utilisateursStore.createUser(currentUserObj);
                } else {
                    await utilisateursStore.setUser(currentUserObj);
                }
                const reponse = utilisateursStore.getUserUpdateReturnCode;
                formMessages.validFirstName    = true;
                formMessages.validLastName     = true;
                formMessages.validEmail        = true;
                formMessages.validSubscriber   = true;
                formMessages.validUser         = true;
                formMessages.validPassword     = true;
                formMessages.validPasswordConf = true;
                formMessages.validDeleted      = true;
                formMessages.validActive       = true;
                formMessages.validRoles        = true;
                switch(reponse){
                    case 200:
                        if(currentMode.value !== 'creation'){
                            await utilisateursStore.updateUser(currentUserObj);
                        }
                        router.back();
                        break;
                    case 404:
                        let validationErrors = utilisateursStore.getUserErrors;
                        console.log(validationErrors);

                        for (const alerte of validationErrors.listeDesAlertes){
                            console.log(alerte);

                            switch(alerte.nomDeZoneJson.toLowerCase()){
                                case 'abonne':
                                    formMessages.validSubscriber   = false;
                                    formMessages.messageSubscriber = alerte.messageAlerte;
                                    break;
                                case 'utilisateur':
                                    formMessages.validUser   = false;
                                    formMessages.messageUser = alerte.messageAlerte;
                                    break;
                                case 'prenom':
                                    formMessages.validFirstName   = false;
                                    formMessages.messageFirstName = alerte.messageAlerte;
                                    break;
                                case 'nom':
                                    formMessages.validLastName   = false;
                                    formMessages.messageLastName = alerte.messageAlerte;
                                    break;
                                case 'email':
                                    formMessages.validEmail   = false;
                                    formMessages.messageEmail = alerte.messageAlerte;
                                    break;
                                case 'roles':
                                    formMessages.validRoles   = false;
                                    formMessages.messageRoles = alerte.messageAlerte;
                                    break;
                                case 'actif':
                                    formMessages.validActive   = false;
                                    formMessages.messageActive = alerte.messageAlerte;
                                    break;
                                case 'supprime':
                                    formMessages.validDeleted   = false;
                                    formMessages.messageDeleted = alerte.messageAlerte;
                                    break;
                                case 'motdepasse':
                                    formMessages.validPassword   = false;
                                    formMessages.messagePassword = alerte.messageAlerte;
                                    break;
                                case 'motdepasseconf':
                                    formMessages.validPasswordConf = false;
                                    formMessages.validPassword     = false;
                                    formMessages.messagePassword   = alerte.messageAlerte;
                                    break;
                                case 'xxxxxx':
                                    break;
                            }
                        }
                        break;
                        case 511:
                          throw 511;
                          break;
                }
            } catch (err) {
                if(err == 511){
                  throw 511;
                } else{
                  console.log(utilisateursStore.getUserErrors);
                }
            }
            isLoading.value = false;
            formMessages.formIsValidated = true;
            


        }
        // --- Returned datas ---------------------------------------------------------------
        return {
            activeUserDetails,
            currentUsername,
            currentUser,
            isLoading,
            isNotCreateMode,
            passwordIsFilled,
            formMessages,
            passwordIsSeeable,
            subscriberIsSeeable,
            userIsSeeable,
            cancelEdit,
            isAdminNotHaz,
            tryExitting,
            exitModal,
            rolesOptions,
            selectedRoles,
            isAdmin,
            submitForm,
            noModificationDone,
            setActiveState
            
        }
    },
}
</script>

<style scoped>
</style>