<template>
    <MDBModal   tabindex = "-1"
                centered
                >
      <MDBModalBody>
        <slot></slot>
      </MDBModalBody>
      <MDBModalFooter>
        <base-button mode="supprimer"   @click="$emit('cancel') "  > {{btnCancel}}   </base-button>
        <base-button mode="enregistrer" @click="$emit('confirm')"  > {{btnConfirm}} </base-button>
      </MDBModalFooter>

    </MDBModal>
</template>

<script>
import { MDBModal, MDBModalBody, MDBModalFooter} from 'mdb-vue-ui-kit';
import { ref } from "vue";
export default {
  components: {
    MDBModal,
    MDBModalBody,
    MDBModalFooter
  },
  emits: ['confirm', 'cancel'],
  props: {
    btnConfirm: {
      type: String,
      required: false,
      default: "Supprimer"
    },
    btnCancel: {
      type: String,
      required: false,
      default: "Annuler"
    }
  },
  setup(props){
    let btnConfirm = ref(props.btnConfirm);
    let btnCancel  = ref(props.btnCancel);
    return {
      btnConfirm,
      btnCancel
    }
  }
}
</script>