export default {

    getUsers() {
        return this.users;
    },
    getUserold() {
        // TODO : remove this method
        let userFound = this.users.find(element => (element.selected === true ));
        return userFound;
    },
    getUser() {
        return this.user;
    },
    getDataset() {
        console.log('getter users.dataset - begin');
        console.log('getter users.dataset - state.dataset='+JSON.stringify(this.dataset));
        return this.dataset;
    },
    getPageCurrent() {
        return this.pageCurrent;
    },
    getPageTotal() {
        return this.pageTotal;
    },
    getPagePrevious() {
        return this.pagePrevious;
    },
    getPageNext() {
        return this.pageNext;
    },
    getSearchValues() {
        return this.searchValues;
    },
    // ----------------------------------------------------------------------------------
    // get the roles object from the store
    // ----------------------------------------------------------------------------------
    getRoles() {
        return this.roles;
    },
    // ----------------------------------------------------------------------------------
    // get the userErrors object from the store
    // ----------------------------------------------------------------------------------
    getUserErrors() {
        return this.userErrors;
    },
    // ----------------------------------------------------------------------------------
    // get the userUpdateReturnCode object from the store
    // ----------------------------------------------------------------------------------
    getUserUpdateReturnCode() {
        return this.userUpdateReturnCode;
    },

};