import { useApplicationStore } from "../application/application";
import { useUserStore } from "../user/user";
import { useAuthStore } from "../auth/auth";
export default {

    // --- Retrieves parameters from the web service to fill the datatable ---------------------- retrieveParametres
    async retrieveData(payload=null) {
        const applicationStore = useApplicationStore();
        const userStore        = useUserStore();
        const authStore        = useAuthStore();
    
        console.log('parametres - actions retrieveParametres - begin');
    
        let serverAddress = userStore.getServerAddress;
    
        console.log('parametres - actions retrieveParametres - serverAddress='+serverAddress);
    
        let url = serverAddress + "/artizen/ws/parametres/listedatatable";
    
        if(payload!=null && Object.keys(payload).length > 0){ // --- we first verify if our payload is not null and if it's not empty
        url+="?";
        let index = 0;
    
        // We now iterate over the payload containing the search filters the user chose
        for(const data in payload){
            url+=data+"="+payload[data]; // Data corresponds to the name of the attribute (user for instance)
                                        // and payload[data] gets the value in the payload linked to the property name
    
            if(index < Object.keys(payload).length-1){ // In the end we check if we reached the end of the payload or not
                url+="&";                              // and if it's not, we add & to continue adding query elements
            }
        }
        }
        console.log("parametres - actions retrieveParametres - url="+url);
    
        let token = authStore.getToken;
    
        const response = await fetch(url,
                {
                    method: 'GET',
                    headers: {
                        Authorization: 'JWT ' + token
                    }
                });
    
        if (!response.ok) {
            if(response.status==511){
                authStore.tryLogin();
            } else {
                const error = new Error('Failed to get user info. Check your login data.');
                throw error;
            }
        }
    
            let parametresResponse = await response.text();
    
            console.log('********** tablesResponse **********');
            console.log("parametres - action retrieveParametres - parametreResponse="+parametresResponse);
            console.log("parametres - action retrieveParametres - parametreResponse.type="+typeof parametresResponse);
            
            const jsonParametreResponse = JSON.parse(parametresResponse);
            console.log(jsonParametreResponse.dataset);
            let payloadParametres = jsonParametreResponse.dataset;
            applicationStore.commitSetMessage(jsonParametreResponse.message);
            
            console.log("parametres - action retrieveParametres - payloadParametres="+payloadParametres);
            console.log("parametres - action retrieveParametres - payloadParametres.type="+typeof payloadParametres);
            
            try {
                let jsonPayloadParametres = JSON.parse(payloadParametres);
                if(jsonPayloadParametres.rows.length>0){
                    console.log(jsonPayloadParametres.rows[0].code);
                } else {
                    console.log('Recherche introuvable');
                }
                
    
                console.log("parametres - action retrieveParametres - jsonPayloadParametres     ="+JSON.stringify(jsonPayloadParametres));
                console.log("parametres - action retrieveParametres - jsonPayloadParametres.type="+typeof jsonPayloadParametres);
                this.setDataset(jsonPayloadParametres);
    
                console.log("parametres - action retrieveParametres - after commit setDataSet=");
    
            }
            catch (e) {
                console.log("error parsing payloadParametres=" + e.toString())
            }
            finally {
                console.log('action retrieveParametres - end');
            }
    },


  setDataset(payload) {
    console.log('mutation parametres.setDataset - begin');
    this.dataset = payload;
    console.log('mutation parametres.setDataset='+JSON.stringify(this.dataset));
    console.log('mutation parametres.setDataset - end');
  },

  async retrieveParametre(payload) {
    const userStore = useUserStore();
    const authStore = useAuthStore();
    // context.commit('setUser', payload);

    // --- get the address of the web service server ------------------------------------------
    let serverAddress = userStore.getServerAddress;

    // --- construct the url ------------------------------------------------------------------
    let sub  = !!payload.abonne      ? 'abonne='      + payload.abonne      + '&' : ''; 
    let user = !!payload.utilisateur ? 'utilisateur=' + payload.utilisateur + '&' : ''; 
    let url = serverAddress + '/artizen/ws/parametres/lire?'+ sub + user + 'table=' + payload.table.toUpperCase() + '&code=' + payload.code.toUpperCase();
    console.log(url);
    // --- retrieve the token from auth store
    let token = authStore.getToken;

    // call the ws and wait for his answer
    const response = await fetch(url,
        {
            method: 'GET',
            headers: {
                Authorization: 'JWT ' + token
            }
//        body: JSON.stringify({
//          email: payload.email,
//          password: payload.password,
//          returnSecureToken: true
//        })
        });

    // if we had an error calling the ws
    if (!response.ok) {
        if(response.status==511){
                authStore.tryLogin();
            } else {
                const error = new Error('Failed to get user info. Check your login data.');
                throw error;
            }
    }

    // retrieve the reponse from the ws
    let parametreResponse = await response.text();

    console.log('********** parametreResponse **********');
    console.log(parametreResponse);

    // parse the response as a json message
    const jsonParametreResponse = JSON.parse(parametreResponse);
    console.log(jsonParametreResponse);
    // we store the infos in the vueX calling the setUser mutation
    jsonParametreResponse.actif = jsonParametreResponse.actif=="O" ? true : false;
    this.commitSetParametre(jsonParametreResponse);
    console.log('action retrieveParametre - fin');

},

commitSetParametre(parametre){
    this.parametre = parametre;
},
async deleteElement(payload) {
    const userStore = useUserStore();
    const authStore = useAuthStore();
    console.log('action users.deleteUser - begin');
    console.log(payload);


    //--- get the address of the web service server ------------------------------------------
    let serverAddress = userStore.getServerAddress;
    let abonne      = !!payload.abonne      ? "abonne="       + payload.abonne      + '&'  : '';
    let utilisateur = !!payload.utilisateur ? "&utilisateur=" + payload.utilisateur + '&'  : '';
    // --- construct the url ------------------------------------------------------------------
    let url = serverAddress + '/artizen/ws/parametres/supprimer?'+ abonne + utilisateur +'table=' + payload.table + '&code='+ payload.code;

    // retrieve the token from auth store
    let token = authStore.getToken;

    // call the ws and wait for his answer
    const response = await fetch(url,
      {
          method: 'PUT',
          headers: {
              Authorization: 'JWT ' + token
          }
      });

    // if we had an error calling the ws
    if (!response.ok) {
        if(response.status==511){
                authStore.tryLogin();
            } else {
                const error = new Error('Failed to get user info. Check your login data.');
                throw error;
            }
    }

    // retrieve the reponse from the ws
    let parametreResponse = await response.text();

    console.log('********** parametreResponse **********');
    console.log(parametreResponse);

    console.log('action parametres.deleteParametre - end');

},
async createParametre(payload){
        const userStore = useUserStore();
        const authStore = useAuthStore();
        console.log('action parametres.createParametre - start');
        console.log(payload);
        // --- get the address of the web service server ------------------------------------------
        let serverAddress = userStore.getServerAddress;

        // --- construct the url ------------------------------------------------------------------
        let url = serverAddress + '/artizen/ws/parametres/creer';

        // --- retrieve the token from auth store -------------------------------------------------
        let token = authStore.getToken;

        let myActive  = (payload.actif ?  'O':'N')
        console.log(payload);
        // --- call the ws and wait for his answer ------------------------------------------------
        const response = await fetch(url,
          {
              method: 'PUT',
              headers: {
                  "Authorization": 'JWT ' + token,
                  "accept":        "application/json",
                  "Content-Type":  "application/json",
              },
              body: JSON.stringify({
                  abonne      : payload.abonne,
                  utilisateur : payload.utilisateur,
                  table       : payload.table,
                  code        : payload.code,
                  valeuralpha : payload.valeuralpha,
                  valeurnum   : payload.valeurnum,
                  actif       : myActive,
                  modifiable  : payload.modifiable,
                  supprimable : payload.supprimable
              })
          });

        if (!response.ok) {

            // --- if we had an error while calling the web service -------------------------------
            console.log("createParametre, response is not ok");
            console.log(response);

            if (response.status === 404) {
                let errorResponse = await response.text();

                console.log("errorResponse=");
                console.log(errorResponse);

                let jsonErrorResponse = JSON.parse(errorResponse);

                console.log("jsonErrorResponse=");
                console.log(jsonErrorResponse);

                this.setParametreErrors(jsonErrorResponse);
                this.setParametreUpdateReturnCode(404);
            } else if(response.status == 511){
               this.setParametreErrors(null);
               this.setParametreUpdateReturnCode(511);
               throw 511;
            } else {
                this.setParametreErrors(null);
                this.setParametreUpdateReturnCode(999);
                const error = new Error('Failed to set user info. Check your login data.');
                throw error;
            }
        }
        else {
            // result is ok for the user creation
            this.setParametreErrors(null);
            this.setParametreUpdateReturnCode(200);
        }

        console.log('action parametres.createParametre - end');
    },
    setParametreErrors(payload) {
        console.log('mutation users.setTableErrors - begin');
        this.parametreErrors = payload;

        console.log("state.userErrors=");
        console.log(this.tableErrors);

        console.log('mutation tables.setTableErrors - end');
    },

    // ----------------------------------------------------------------------------------
    // update the userUpdateReturnCode object in the store
    // ----------------------------------------------------------------------------------
    setParametreUpdateReturnCode(payload) {
        console.log('mutation users.setTableUpdateReturnCode - begin');
        this.parametreUpdateReturnCode = payload;

        console.log("state.userUpdateReturnCode=");
        console.log(this.parametreUpdateReturnCode);

        console.log('mutation tables.setTableUpdateReturnCode - end');
    },

    async setParametre(payload){
        const userStore = useUserStore();
        const authStore = useAuthStore();
        console.log('action tables.setTables - start');


        // --- get the address of the web service server ------------------------------------------
        let serverAddress = userStore.getServerAddress;

        // --- construct the url ------------------------------------------------------------------
        let url = serverAddress + '/artizen/ws/parametres/modifier';

        // retrieve the token from auth store
        let token = authStore.getToken;

        let myActive  = (payload.actif ?  'O':'N')

        // call the ws and wait for his answer
        const response = await fetch(url,
            {
                method: 'PUT',
                headers: {
                    "Authorization": 'JWT ' + token,
                    "accept":        "application/json",
                    "Content-Type":  "application/json",
                },
                body: JSON.stringify({
                  abonne      : payload.abonne,
                  utilisateur : payload.utilisateur,
                  table       : payload.table,
                  code        : payload.code,
                  valeuralpha : payload.valeuralpha,
                  valeurnum   : payload.valeurnum,
                  actif       : myActive,
                  modifiable  : payload.modifiable,
                  supprimable : payload.supprimable
              })
            });

        if (!response.ok) {

            // if we had an error while calling the web service
            console.log("setTables, response is not ok");
            console.log(response);

            if (response.status === 404) {
                let errorResponse = await response.text();

                console.log("errorResponse=");
                console.log(errorResponse);

                let jsonErrorResponse = JSON.parse(errorResponse);

                console.log("jsonErrorResponse=");
                console.log(jsonErrorResponse);

                this.setParametreErrors(jsonErrorResponse);
                this.setParametreUpdateReturnCode(404)
            } else if(response.status == 511){
               this.setParametreErrors(null);
               this.setParametreUpdateReturnCode(511);
               throw 511;
            } else {
                this.setParametreErrors(null);
                this.setParametreUpdateReturnCode(999);
                const error = new Error('Failed to set user info. Check your login data.');
                throw error;
            }
        }
        else {
            // result is ok for the user update
            this.setParametreErrors(null);
            this.setParametreUpdateReturnCode(200)

            // we store the infos in the vueX calling the setUser mutation
            this.commitSetParametre({
                abonne      : payload.abonne,
                utilisateur : payload.utilisateur,
                table       : payload.table,
                code        : payload.code,
                valeuralpha : payload.valeuralpha,
                valeurnum   : payload.valeurnum,
                actif       : myActive,
                modifiable  : payload.modifiable,
                supprimable : payload.supprimable
            });
        }

        console.log('action users.setUser - end');
    },
    updateParametre(payload) {
        console.log('mutation tables.updateTable - begin');

        console.log(">>> tables.updateTable - payload");
        console.log(payload);
        console.log(">>> dataset before changes <<<")
        console.log(this.dataset.rows);

        let localSubscriber =  payload.abonne;
        let localUser       =  payload.utilisateur;
        let localTable      =  payload.table;
        let localCode       =  payload.code;


        this.dataset.rows.forEach((element, index) => {
            if ((this.dataset.rows[index].abonne === localSubscriber && this.dataset.rows[index].utilisateur == localUser
                 && this.dataset.rows[index].table === localTable && this.dataset.rows[index].code === localCode)) {
                this.dataset.rows[index].actif = payload.actif ? 'O' : 'N',
                this.dataset.rows[index].table = payload.table,
                this.dataset.rows[index].code = payload.code,
                this.dataset.rows[index].valeuralpha = payload.valeuralpha,
                this.dataset.rows[index].modifiable = payload.modifiable,
                this.dataset.rows[index].supprimable = payload.supprimable,
                this.dataset.rows[index].abonne = payload.abonne,
                this.dataset.rows[index].utilisateur = payload.utilisateur,
                this.dataset.rows[index].valeurnum   = payload.valeurnum;

            }
        });

        console.log(">>> tables.updateTable - dataset");
        console.log(this.dataset.rows);

        console.log('mutation tables.updateTable - end');
    },
}