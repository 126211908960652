export default{
  // --- Getter for the parametres variable ----------------------------------------------------- getParametres
  getParametres(){
    return this.tablesparametres;
  },

  // --- Getter for the parametre variable ------------------------------------------------------ getParametre
  getParametre(){
    return this.parametre;
  },

  // --- Getter for the loaded variable --------------------------------------------------------- getLoaded
  getLoaded(){
    return this.loaded;
  },

  // --- Getter for the parametreErrors variable ------------------------------------------------ getParametreErrors
  getParametreErrors(){
    return this.parametreErrors;
  },

  // --- Getter for the parametreUpdateReturnCode variable -------------------------------------- getParametreUpdateReturnCode
  getParametreUpdateReturnCode(){
    return this.parametreUpdateReturnCode;
  },

  // --- Getter for the dataset variable -------------------------------------------------------- getDataset
  getDataset(){
    return this.dataset;
  }
}