export default {

    // get the user
    getUser() {
        return this.user;
    },
    // get the subscriber
    getSubscriber() {
        return this.subscriber;
    },
    // get the firstname
    getFirstname() {
        return this.firstname;
    },
    // get the lastname
    getLastname() {
        return this.lastname;
    },
    // get the email
    getEmail() {
        return this.email;
    },
    // get the roles
    getRoles() {
        return this.roles;
    },
    // get the model
    getModel() {
        return this.model;
    },
    // return true if current user is admin-haz
    getIsAdminHaz() {
        return this.roles.includes("admin-haz");
    },
    // return true if current user is admin (admin or admin-haz)
    getIsAdmin() {
        return this.roles.includes("admin");
    },
    getServerAddress() {
        return this.serverAddress;
    },
    getMenus() {
        return this.menus;
    },
    getFavoriteMenus(){
        return this.favoriteMenus;
    },
    getSubscribedAs(){
        return this.subscribedAs;
    }
};