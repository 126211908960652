import { defineStore } from 'pinia'

import getters   from './getters';
import actions   from './actions';

export const useUtilisateursStore = defineStore('users', {
    state: () => ({
        users:                [],
        user:                 {},
        searchValues:         {},
        pageTotal:            1,
        loaded:               false,
        pageCurrent:          1,
        pagePrevious:         0,
        pageNext:             0,
        userErrors:           null,
        userUpdateReturnCode: 0,
        roles:                "",
        dataset:              {
            columns : ['Actions', 'Abonné', 'Code', 'Nom', 'Prénom', 'Email', 'Role(s)', 'Actif', 'Supprimé'],
            rows    : []
        }
    }),
    getters: getters,
    actions: actions
});