import { defineStore } from 'pinia'

import getters from './getters';
import actions from './actions';

export const useMenusStore = defineStore('menus', {
  state: () => ({
    menus:                [],
    menu:                 {},
    loaded:               false,
    menuErrors:           null,
    menuUpdateReturnCode: 0,
    dataset:               {
        columns : ['Actions', 'Menu', 'Bloc', 'Num. Bloc', 'Rôles', 'Entête', 'De', 'Pied', 'Type bloc', 'Menu parent',
          'Chemin', 'Arbo.', 'Icone', 'Coul. bordure', 'Coul.fond', 'Coul. texte'],
        rows    : []
    }
  }),
  getters,
  actions,
})