import { useUserStore } from "../user/user"
import { useAuthStore } from "../auth/auth"

export default {

    // --- Retrieve the list of all roles available for the current user ------------------------ retrieveRoles
    async retrieveRoles(){

        const authStore = useAuthStore();
        const userStore = useUserStore();
        let serverAddress = userStore.getServerAddress;

        let url = serverAddress + '/artizen/ws/utilisateurs/listeroles';

        let token = authStore.getToken;

        const response = await fetch(url, 
            {
                method: 'GET',
                headers: {
                    Authorization: 'JWT ' + token
                }
            }
        );

        if(!response.ok){
            throw new Error('Error while fetching datas');
        }

        let rolesResponse = await response.text();

        console.log('*********** rolesResponse ***********')
        console.log(rolesResponse);

        const jsonRolesResponse = JSON.parse(rolesResponse).listeDesRoles;

        let rolesInArray = [];

        for (const index in jsonRolesResponse){
            rolesInArray.push(jsonRolesResponse[index].role);
        }

        console.log(rolesInArray);
        this.commitSetRoles(rolesInArray);

    },

    // --- Setter for roles variable ------------------------------------------------------------ commitSetRoles
    commitSetRoles(roles){
        this.roles = roles;
    },

    // --- Setter for usersMessage variable ----------------------------------------------------- commitSetUtilisateursMessage
    commitSetMessage(message){
        this.message = message;
    },

    // --- Setter for subscribersMessage variable ----------------------------------------------- commitSetAbonnesMessage
    commitSetAbonnesMessage(message){
        this.subscribersMessage = message;
    },

    // --- Setter for tablesMessage variable ---------------------------------------------------- commitSetTablesMessage
    commitSetTablesMessage(message){
        this.tablesMessage = message;
    },

    // --- Setter for parametresMessage variable ------------------------------------------------ commitSetParametresMessage
    commitSetParametresMessage(message){
        this.parametresMessage = message;
    },

    // --- Setter for menusMessage variable ----------------------------------------------------- commitSetMenusMessage
    commitSetMenusMessage(message){
        this.menusMessage = message;
    },

    // --- Setter for logsMessage variable ------------------------------------------------------ commitSetLogsMessage
    commitSetLogsMessage(message){
        this.logsMessage = message;
    },

    // --- Setter for dataLoaded variable ------------------------------------------------------- setDataLoaded
    setDataLoaded(bool){
        this.dataLoaded = bool;
    },

    // --- Setter for nbLignesDatatable variable ------------------------------------------------ setNbLignesDatatable
    // --- Define the number of line to display in the datatable, based on the screen width -----
    setNbLignesDatatable(width){
        if(width <= 900){
            this.nbLignesDatatable = 6;
        } else {
            this.nbLignesDatatable = 10;
        }
    },

    setIsSmallScreen(width){
        if(width <= 1199){
            this.isSmallScreen = true;
        } else {
            this.isSmallScreen = false;
        }
    },

    setLastPageOnList(page){
        this.lastPageOnList = page;
    }

}