import { defineStore } from 'pinia'

import getters from './getters';
import actions from './actions';

export const useLogsStore = defineStore('logs', {
  state: () => ({
    dataset:               {
        columns : ['Id','Date', 'Ip', 'Session', 'Niveau', 'Tag', 'Pgm', 'Abonné', 'Utilisateur', 'Fonction', 'Texte'],
        rows    : []
    }
  }),
  getters,
  actions,
})