export default {

  getSubscribers(){
    return this.subscribers;
  },

  getSubscriber(){
    return this.subscriber;
  },

  getSubscriberErrors(){
    return this.subscriberErrors;
  },

  getSubscriberUpdateReturnCode(){
    return this.subscriberUpdateReturnCode;
  },

  getDataset(){
    return this.dataset;
  },
  
  getListeCommunes(){
    return this.listedescommunes;
  }
};