<template>

  <MDBRow :cols="['1', 'md-5']" class="g-4 p-3" >
    <MDBCol>
      <base-menu-card name="subscribers"
                      title="Abonnés"
                      text="Gêrer les abonnés."
                      footer="..."
                      path="/menuadmin"
                      :active="false"
                      v-on:action="menuSelected"/>
    </MDBCol>
    <MDBCol>
      <base-menu-card name="users"
                      title="Utilisateurs"
                      text="Gêrez les utilsateurs de votre entreprise."
                      footer="/utilisateurs"
                      :active="true"
                      v-on:action="menuSelected"/>
    </MDBCol>
    <MDBCol>
      <base-menu-card name="parameters"
                      title="Paramètres"
                      text="Gestion des paramètres."
                      footer="..."
                      :active="false"
                      v-on:action="menuSelected"/>
    </MDBCol>
    <MDBCol>
      <base-menu-card name="menuBack"
                      title="Retour"
                      text="Revenir au menu précédent"
                      footer="..."
                      textcolor="white"
                      bgcolor="dark"
                      :active="true"
                      v-on:action="menuSelected"/>
    </MDBCol>
  </MDBRow>

</template>

<script>

import { computed }  from 'vue';
import { ref      }  from "vue";
import { useRouter } from 'vue-router';
import { useAuthStore } from '@/pinia/auth/auth.js';

import { MDBCol, MDBRow, MDBCardGroup, MDBCard, MDBCardBody, MDBCardTitle, MDBCardText, MDBCardImg, MDBCardFooter } from "mdb-vue-ui-kit";
import { MDBIcon } from 'mdb-vue-ui-kit';

import BaseMenuCard from "@/components/ui/BaseMenuCard";

export default {
  name: 'HomeView',
  components: {
    BaseMenuCard,
    MDBCol,
    MDBRow,
    MDBCardGroup,
    MDBCard,
    MDBCardBody,
    MDBCardTitle,
    MDBCardText,
    MDBCardImg,
    MDBCardFooter,
    MDBIcon
  },

  setup() {

    // --- Router declaration ---------------------------------------------------------------------
    const router = useRouter();

    // --- Store declaration ----------------------------------------------------------------------
    const authStore = useAuthStore();

    // --- References ----------------------------------------------------------------------------- REFERENCES
    const collapseMenu           = ref(false);

    // --- Computed ------------------------------------------------------------------------------- COMPUTED
    // -------------------------------------------------------------------------------------------- isUserAuthenticated
    const isUserAuthenticated = computed( function () {
      console.log("isAuthenticated=" + authStore.getIsAuthenticated);
      return authStore.getIsAuthenticated;
    });

    // --- Methods -------------------------------------------------------------------------------- METHODS
    // -------------------------------------------------------------------------------------------- menuSelected
    function menuSelected(menuItem) {

      // ___ Manage the dropdownAdministration and dropdownAvatar variables in order to make the menu disappear on small devices
      console.log("Menu selected name="+menuItem.name);
      console.log("Menu selected path="+menuItem.path);
      if (menuItem.name === "menuBack") {
        router.back();
      }
      if (menuItem.path !== undefined && menuItem.path !== "") {
        console.log("Menu on part=");
        router.push(menuItem.path);
      }
    }
    // -------------------------------------------------------------------------------------------- roleValue
    function roleValue() {
      return "button";
      // if (this.active) {
      //   return "button"
      // } else {
      //   return "false"
      // }
    }
    // --- Returned objects ----------------------------------------------------------------------- RETURNED
    return {
      collapseMenu,
      isUserAuthenticated,
      menuSelected,
      roleValue
    }
  }
}
</script>
