import { defineStore } from 'pinia';

import getters from './getters';
import actions from './actions';

export const useZonesStore = defineStore('zones', {
    state: () => {
        return {
            zone: {},
            zoneError: '',
            zoneUpdateReturnCode: 200,
            dataset: {
                columns : ['Actions', 'Entité', 'Zone', 'Désignation', 'Désignation col.', 'Alignement Col.'],
                rows    : []
            },
            objetsGraphiques : {},
            typesDeZones     : {}
        };
    },

    getters,
    actions
})