import { useUserStore } from "../user/user";
import { useAuthStore } from "../auth/auth";
export default {
    // --- Retrieves the list of all communes --------------------------------------------------- retrieveCommunes
    async retrieveData(payload=null){
        const userStore = useUserStore();
        const authStore = useAuthStore();
        let serverAddress = userStore.getServerAddress;

        let url = serverAddress + "/artizen/ws/connexions/listedatatable";

        if(payload!=null && Object.keys(payload).length > 0){ // -- we first verify if our payload is not null and if it's not empty
            url+="?";
            let index = 0;
            // -- we now iterate over the payload containing the search filters the user chose
            for(const data in payload){
                url+=data+"="+payload[data]; // -- data corresponds to the name of the attribute (user for instance)
                // -- and payload[data] gets the value in the payload linked to the property name
                if(index < Object.keys(payload).length-1){ // -- in the end we check if we reached the end of the payload or not
                    url+="&";                              // -- and if it's not, we add & to continue adding query elements
                }
            }
        }
        console.log(url);
        let token = authStore.getToken;
        const response = await fetch(url,
            {
                method: 'GET',
                headers: {
                    Authorization: 'JWT ' + token
                }
            });

        if (!response.ok){
            const error = new Error('Failed to get user info. Check your login data.');
            throw error;
        }

        let connexionsResponse       = await response.text();
        const jsonConnexionsResponse = JSON.parse(connexionsResponse);
        let payloadConnexions        = jsonConnexionsResponse.dataset;

        try {
            let jsonPayloadConnexions = JSON.parse(payloadConnexions);
            console.log(jsonPayloadConnexions);
            this.setDataset(jsonPayloadConnexions);
        }
        catch(e){
            console.log('Error parsing payloadConnexions=' + e.toString());
        }
        finally{
            console.log('action retrieveConnexions - end');
        }
    },

    // --- Unlocks a user, allowing him to reconnect on the website ------------------------------ unlockUser
    async unlockUser(payload){
        const userStore   = useUserStore();
        const authStore   = useAuthStore();
        let serverAddress = userStore.getServerAddress;
        let abonne        = payload.abonne=='' ? userStore.getSubscriber : payload.abonne;
        let url           = serverAddress + "/artizen/ws/connexions/debloquer?abonne="+abonne+"&utilisateur="+payload.utilisateur;
        let token         = authStore.getToken;
        const response    = await fetch(url, {
            method: 'PUT',
            headers: {
                Authorization: 'JWT ' + token
            }
        })
        console.log(response);
        // if(!response.ok){
        //     const error = new Error('Failed to get user info. Check your login data.');
        //     throw error;
        // }
    },

    // --- Locks a user, allowing him to reconnect on the website ------------------------------ unlockUser
    async lockUser(payload){
        const userStore   = useUserStore();
        const authStore   = useAuthStore();
        let serverAddress = userStore.getServerAddress;
        let abonne        = payload.abonne=='' ? userStore.getSubscriber : payload.abonne;
        let url           = serverAddress + "/artizen/ws/connexions/bloquer?abonne="+abonne+"&utilisateur="+payload.utilisateur;
        let token         = authStore.getToken;
        const response    = await fetch(url, {
            method: 'PUT',
            headers: {
                Authorization: 'JWT ' + token
            }
        })
        console.log(response);
        if(!response.ok){
            if(response.status==511){
                authStore.tryLogin();
            } else {
                const error = new Error('Failed to get user info. Check your login data.');
                throw error;
            }
        }
    },

    // --- Setter for the dataset variable ------------------------------------------------------ setDataset
    setDataset(payload){
        this.dataset = payload;
    }
}
