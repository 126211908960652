export default {
    getModeleAffiche(){
        return this.modeleAffiche;
    },

    getEntites(){
        return this.entites;
    },

    getModeles(){
        return this.modeles;
    },

    getErrorsList(){
        return this.errorsList;
    }
}