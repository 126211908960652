<template>
  <base-card class="d-flex flex-row" >
    <div class="me-4 d-flex align-items-center ms-4">
      <MDBCheckbox v-model="$props.value.lu" @change="updateReadState"/>
    </div>
    <div @click="showMessage">
      <h2>{{value.titre}}</h2>
      <div class="text">
        {{value.texte}}
      </div>
    </div>
  </base-card>
</template>

<script>
import {MDBCheckbox} from "mdb-vue-ui-kit";
import {computed, ref} from "vue";
import {useBoiteReceptionStore} from "@/pinia/boitereception/boitereception";
export default {
  name: "BaseMessage",
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  emits: ['showMessage'],
  components: {
    MDBCheckbox
  },
  setup(props, {emit}){
    const boiteReception = useBoiteReceptionStore();
    console.log(props.value);
    let estLu = ref(props.value.lu);
    console.log(estLu.value);

    async function showMessage(){
      await updateReadState();
      props.value.lu = true;
      emit('showMessage');
    }
    async function updateReadState(){
      await boiteReception.changeMessageReadStatus(props.value.lu, props.value.id);
    }

    return {
      estLu,
      showMessage,
      updateReadState,
    }
  }
}
</script>

<style scoped>

.text {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
</style>