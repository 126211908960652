import { createRouter, createWebHistory } from 'vue-router'
import HomeAdministration                 from "@/views/menu/HomeAdministration";
import AboutView                          from '../views/AboutView.vue'
import { useAuthStore }                   from '@/pinia/auth/auth';
import { useApplicationStore }            from '@/pinia/application/application';
import { useUserStore }                   from '@/pinia/user/user';
import user from "@/views/user/User.vue";

const User           = () => import("../views/user/User");

const Home           = () => import("../views/menu/Home.vue"                  );
const UserAuth       = () => import("../views/auth/UserAuth"                  );
const UserList       = () => import("../views/user/UserList"                  );
const TableList      = () => import("../views/table/TableList"                );
const Table          = () => import("../views/table/Table.vue"                );
const SubscriberList = () => import("../views/subscriber/SubscriberList.vue"  );
const Subscriber     = () => import("../views/subscriber/Subscriber.vue"      );
const TechnicalPB    = () => import("../views/errors/TechnicalPB.vue"         );
const Agenda         = () => import("../views/agenda/Agenda.vue"              );
const ParametreList  = () => import("../views/parametres/ParametreList.vue"   );
const Parametre      = () => import("../views/parametres/Parametre.vue"       );
const LogsList       = () => import("../views/logs/LogsList.vue"              );
const MenusList      = () => import("../views/menus/MenuList.vue"             );
const Menu           = () => import("../views/menus/Menu.vue"                 );
const CommunesList   = () => import("../views/communes/CommuneList.vue"       );
const ConnexionsList = () => import("../views/connexions/ConnexionList"       );
const EntitesList    = () => import("../views/entites/EntitesList"            );
const Entite         = () => import("../views/entites/Entite"                 );
const ZonesList      = () => import("../views/zones/ZonesList"                );
const Zone           = () => import("../views/zones/Zone"                     );
const ModelesZones   = () => import("../views/modeleszones/ModelesZones.vue"  );
const ClientList     = () => import("../views/clients/ClientList.vue"         );
const PassUniversel  = () => import("../views/passuniversel/PassUniversel.vue");
const Unauthorized   = () => import("../views/errors/RessourceNonAutorisee.vue");
const BoiteReception = () => import("../views/boitereception/BoiteDeReception.vue");
const ForgottenPassword = () => import('../views/auth/ForgottenPassword.vue');
const NewPass           = () => import('../views/auth/NewPass.vue');
const TestMask          = () => import ('../views/tests/TestMask.vue');

const routes = [
  {
    path: '/about',
    name: 'about',
    component: AboutView
  },
  {
    path: '/agenda',
    name: 'agenda',
    component: Agenda,
    meta: { secRequiresNonAuth: false, secRequiresAdminHaz: false}
  },
  {
    path: '/erreur',
    name: 'erreur',
    component: TechnicalPB,
    meta: { secRequiresNonAuth: true, secRequiresAdminHaz: false}
  },
  {
    path: '/ressourcenonautorisee',
    name: 'unauthorized',
    component: Unauthorized,
    meta: { secRequiresNonAuth: false, secRequiresAdminHaz: false }
  },
  {
    path: '/',
    name: 'home',
    alias: '/menu/',
    redirect: '/menu/accueil',
    component: Home,
    meta: { secRequiresNonAuth: false, secRequiresAdminHaz: false }
  },
  {
    path: "/menu/:menu",
    component: Home,
    props: true,
    name: "home menus",
    meta: { secRequiresNonAuth: false, secRequiresAdminHaz: false }
  },
  {
    path: '/menuadmin',
    name: 'menu admin',
    component: HomeAdministration,
    meta: { secRequiresNonAuth: false, secRequiresAdminHaz: true }
  },
  {
    path: "/auth",
    component: UserAuth,
    name: 'auth',
    meta: { secRequiresNonAuth: true, secRequiresAdminHaz: false }
  },
  {
    path: "/motdepasseoublie",
    component: ForgottenPassword,
    name: 'auth mot de passe oublie',
    meta: {secRequiresNonAuth: true, secRequiresAdminHaz: false }
  },
  {
    path: "/nouveaupass",
    component: NewPass,
    name: 'auth nouveau mdp',
    meta: {secRequiresNonAuth: true, secRequiresAdminHaz: false}
  },
  {
    path: "/utilisateurs",
    component: UserList,
    name: 'utilisateurs',
    meta: { secRequiresNonAuth: false, secRequiresAdminHaz: false }
  },
  {
    path: "/utilisateur/:subscriber/:user",
    component: User,
    name: 'utilisateur détails',
    props: true,
    meta: { secRequiresNonAuth: false, secRequiresAdminHaz:false }
  },
  {
    path: "/utilisateur/creation",
    component: User,
    name: 'utilisateur création',
    props: { subscriber: null, user: null, mode: "creation"},
    meta: { secRequiresNonAuth: false, secRequiresAdminHaz: false }
  },
  {
    path: "/tables",
    component: TableList,
    name: 'tables',
    meta: { secRequiresNonAuth: false, secRequiresAdminHaz: false }
  },
  {
    path: "/table/:subscriber/:table/:code",
    component: Table,
    name: "table d'un abonné",
    props: true,
    meta: { secRequiresNonAuth: false, secRequiresAdminHaz: false }
  },
  {
    path: "/table/:table/:code",
    component: Table,
    name: 'table détails',
    props: true,
    meta: { secRequiresNonAuth: false, secRequiresAdminHaz: false }
  },
  {
    path: "/table/creation",
    component: Table,
    name: 'table création',
    props: { subscriber: null, table: null, code: null, mode: "creation"},
    meta: { secRequiresNonAuth: false, secRequiresAdminHaz: false }
  },
  {
    path: "/abonnes",
    component: SubscriberList,
    name: 'abonnes',
    meta: { secRequiresNonAuth: false, secRequiresAdminHaz: false }
  },
  {
    path: "/abonne/:subscriber",
    component: Subscriber,
    name: 'abonne détails',
    props: true,
    meta: { secRequiresNonAuth: false, secRequiresAdminHaz: false }
  },
  {
    path: "/abonne/creation",
    component: Subscriber,
    name: 'abonne création',
    props: { subscriber: null, mode: "creation"},
    meta: { secRequiresNonAuth: false, secRequiresAdminHaz: false }
  },
  // {
  //   path: "/abonne",
  //   component: Subscriber,
  //   meta: { secRequiresNonAuth: false }
  // },
  {
    path: "/parametres",
    component: ParametreList,
    name: 'parametres',
    meta: { secRequiresNonAuth: false, secRequiresAdminHaz: true }
  },
  {
    path: "/parametre/:subscriber/:utilisateur/:table/:code",
    component: Parametre,
    name: "parametre d'un utilisateur pour admin-haz",
    props: true,
    meta: { secRequiresNonAuth: false, secRequiresAdminHaz: true }
  },
  {
    path: "/parametre/:subscriber/:table/:code",
    component: Parametre,
    name: "parametre d'un abonné",
    props: true,
    meta: { secRequiresNonAuth: false, secRequiresAdminHaz: true }
  },
  {
    path: "/parametre/:utilisateur/:table/:code",
    component: Parametre,
    name: "parametre d'un utilisateur pour admin",
    props: true,
    meta: { secRequiresNonAuth: false, secRequiresAdminHaz: true }
  },
  {
    path: "/parametre/:table/:code",
    component: Parametre,
    name: 'parametre détails',
    props: true,
    meta: { secRequiresNonAuth: false, secRequiresAdminHaz: true }
  },
  {
    path: "/parametre/creation",
    component: Parametre,
    name: 'parametre création',
    props: { subscriber: null, utilisateur: null, table: null, code: null, mode: "creation"},
    meta: { secRequiresNonAuth: false, secRequiresAdminHaz: true }
  },
  {
    path: "/logs",
    component: LogsList,
    name: 'logs',
    meta: { secRequiresNonAuth: false, secRequiresAdminHaz: true }
  },
  {
    path: "/menus",
    component: MenusList,
    name: 'menus',
    meta: { secRequiresNonAuth: false, secRequiresAdminHaz: true }
  },
  {
    path: "/detailsMenu/:menu/:bloc",
    component: Menu,
    props: true,
    name: 'menu détails',
    meta: { secRequiresNonAuth: false, secRequiresAdminHaz: true }
  },
  {
    path: "/detailsMenu/creation",
    component: Menu,
    name: 'menu création',
    props: { menu: null, bloc: null, mode: "creation"},
    meta: { secRequiresNonAuth: false, secRequiresAdminHaz: true }
  },
  {
    path: "/communes",
    component: CommunesList,
    name: 'communes',
    meta: { secRequiresNonAuth: false, secRequiresAdminHaz: false }
  },
  {
    path: "/connexions",
    name: 'connexions',
    component: ConnexionsList,
    meta: { secRequiresNonAuth: false, secRequiresAdminHaz: false }
  },
  {
    path: "/entites",
    name: 'entites',
    component: EntitesList,
    meta: { secRequiresNonAuth: false, secRequiresAdminHaz: true }
  },
  {
    path: "/entite/creation",
    component: Entite,
    name: 'entite création',
    props: { codeEntite: null, mode: "creation"},
    meta: { secRequiresNonAuth: false, secRequiresAdminHaz: true }
  },
  {
    path: "/entite/:codeEntite",
    component: Entite,
    props: true,
    name: 'entite détails',
    meta: { secRequiresNonAuth: false, secRequiresAdminHaz: true }
  },
  {
    path: "/zones",
    component: ZonesList,
    name: 'zones',
    meta: { secRequiresNonAuth: false, secRequiresAdminHaz: true }
  },
  {
    path: "/zone/creation",
    component: Zone,
    name: 'zone création',
    props: { codeEntite: null, mode: "creation"},
    meta: { secRequiresNonAuth: false, secRequiresAdminHaz: true }
  },
  {
    path: "/zone/:codeEntite/:codeZone",
    component: Zone,
    props: true,
    name: 'zone détails',
    meta: { secRequiresNonAuth: false, secRequiresAdminHaz: true }
  },
  {
    path: "/modeleszones",
    component: ModelesZones,
    name: 'modeleszones',
    meta: { secRequiresNonAuth: false, secRequiresAdminHaz: false}
  },
  {
    path: "/clients",
    component: ClientList,
    name: 'clients',
    meta: { secRequiresNonAuth: false, secRequiresAdminHaz: false}
  },
  {
    path: "/passuniversel",
    component: PassUniversel,
    name: 'pass universel',
    meta: { secRequiresNonAuth: false, secRequiresAdminHaz: true}
  },
  {
    path: "/boitereception",
    component: BoiteReception,
    name: 'boite de reception',
    meta: {secRequiresNonAuth: false, secRequiresAdminHaz: false}
  },
  {
    path: "/test/mask",
    component: TestMask,
    name: 'tests mask',
    meta: {secRequiresNonAuth: false, secRequiresAdminHaz: true}
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

router.beforeEach(function(to, _, next){
  const  authStore      = useAuthStore();
  const applicationStore = useApplicationStore();
  const userStore        = useUserStore();

  console.log('============================ROUTER===================================');
  console.log(router.currentRoute.value);
  console.log(to);
  console.log('============================ROUTER===================================');
  if(to.name == "erreur" || to.name=="unauthorized"){
    next();
  }
  else if(applicationStore.getDataLoaded){
    console.log(to.name.substring(0, to.name.length-1))
    console.log(to.name.split(' ')[0])
    let currentRouteName = router.currentRoute.value.name;
    if(!currentRouteName.includes(to.name.split(' ')[0]) &&
        !currentRouteName.includes(to.name.substring(0, to.name.length-1))){
      applicationStore.setLastPageOnList(0);
    }
    if(!currentRouteName==undefined && !currentRouteName.includes('auth') &&
        !currentRouteName.includes('home') && !currentRouteName.includes('agenda')&&
        (
            !currentRouteName.includes(to.name.split(' ')[0]) &&
            !currentRouteName.includes(to.name.substring(0, to.name.length-1))
        )
    ) {
      let storeToUse;
      if(currentRouteName.split(' ').length>1){
        storeToUse = currentRouteName.split(' ')[0] + 's';
      } else {
        storeToUse = currentRouteName;
      }
      let storeToGenerate = storeToUse + "Store";
      console.log('start reset');
      let store = window[storeToGenerate];
      console.log(store.getDataset);
      store.$reset();
      console.log(store.getDataset);
      console.log('end reset');
    }
    if((!to.meta.secRequiresNonAuth)&& !authStore.getIsAuthenticated){
      next('/auth');
    }else if(to.meta.secRequiresAdminHaz && (!userStore.getIsAdminHaz && authStore.getIsAuthenticated)){
      next('/ressourcenonautorisee');
    } else{
      if(to.name.includes('auth') && authStore.getIsAuthenticated){
        next('/menu/accueil');
      } else {
        next()
      }
    }
  } else {
    authStore.tryLogin().then(() => {
      if((!to.meta.secRequiresNonAuth)&& !authStore.getIsAuthenticated ){
        next('/auth');
      } else if(to.meta.secRequiresAdminHaz && (!userStore.getIsAdminHaz && authStore.getIsAuthenticated)){
        next('/ressourcenonautorisee');
      } else{
        if(to.name.includes('auth') && authStore.getIsAuthenticated){
          next('/menu/accueil');
        } else {
          next();
        }
      }
    })
  }
})

export default router
