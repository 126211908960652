import { useAuthStore } from "../auth/auth";
import { useUserStore } from "../user/user";

export default {

  // --- Getter for the events variable --------------------------------------------------------- getAllEvents
  getAllEvents(){
    return this.events;
  },

  // --- Getter for the selectingMode variable -------------------------------------------------- getSelectingMode
  getSelectingMode(){
    return this.selectingMode;
  },

  // --- Getter for the savedEvent variable ----------------------------------------------------- getSavedEvent
  getSavedEvent(){
    return this.savedEvent;
  },

  // --- Getter for the isModalVisible variable ------------------------------------------------- getModalVisible
  getModalVisible(){
    return this.isModalVisible;
  },

  // --- Getter for the typesOfEvents variable -------------------------------------------------- getTypesOfEvents
  getTypesOfEvents(){
    return this.typesOfEvents;
  },

  // --- Get the last event created from the events list ---------------------------------------- getLastEvent
  getLastEvent(){
    return this.events[this.events.length-1];
  },

  // --- Get the list of alert message returned by the web service ------------------------------ getAgendaErrors
  getAgendaErrors(){
    return this.agendaErrors;
  },

  // --- Get the update code returned by the web service (404, 200, ...) ------------------------ getAgendaUpdateReturnCode
  getAgendaUpdateReturnCode(){
    return this.agendaUpdateReturnCode;
  },

  // --- Get the users list to display in the autoselect list ----------------------------------- getUsersCombo
  getUsersCombo(){
    return this.usersCombo;
  },

  // --- Get the clients list to display in the autoselect list --------------------------------- getClientsCombo
  getClientsCombo(){
    return this.clientsCombo;
  },

  getParametresAffichage(){
    return this.parametresAffichage;
  }

}