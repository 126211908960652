import { useUserStore }        from '../user/user';
import { useAuthStore }        from '../auth/auth';
import { useApplicationStore } from '../application/application';

export default {

    async retrieveData(payload=null) {
        const applicationStore = useApplicationStore();
        const userStore        = useUserStore();
        const authStore        = useAuthStore();
        console.log('users - action getUser - begin');
        
        // let searchSubscriber = payload.search.subscriber;
        // let searchUser       = payload.search.user;
        // let searchLastname   = payload.search.lastname;
        // let searchFirstname  = payload.search.firstname;
        // let searchActive     = payload.search.active;
        // let searchDeleted    = payload.search.deleted;
        // let searchPage       = payload.page;

        // --- get the address of the web service server ------------------------------------------
        let serverAddress = userStore.getServerAddress;

        console.log("subscribers - action retrieveSubscribers - serverAddress="+serverAddress);

        // --- construct the url ------------------------------------------------------------------
        let url = serverAddress + '/artizen/ws/abonnes/listedatatable'; //url to define
        
        //query string to define depending on the ws
        // --- sets automatically the query string for the call to the ws -------------------------
        if(payload!=null && Object.keys(payload).length > 0){ // --- we first verify if our payload is not null and if it's not empty
            url+="?";
            let index = 0;

        //     // --- we now iterate over the payload containing the search filters the user chose
            for(const data in payload){
                url+=data+"="+payload[data]; // --- data corresponds to the name of the attribute (user for instance)
        //                                      // --- and payload[data] gets the value in the payload linked to the property name

                if(index < Object.keys(payload).length-1){ // --- in the end we check if we reached the end of the payload or not
                    url+="&";                              // --- and if it's not, we add & to continue adding query elements
                }
            }
        }
        console.log("subscribers - action getSubscribers - url="+url);

        // --- retrieve the token from auth store -------------------------------------------------
        let token = authStore.getToken;

        // call the ws and wait for his answer
        const response = await fetch(url,
            {
                method: 'GET',
                headers: {
                    Authorization: 'JWT ' + token
                }
            });

        // if we had an error calling the ws
        if (!response.ok) {
            if(response.status==511){
                authStore.tryLogin();
            } else {
                const error = new Error('Failed to get user info. Check your login data.');
                throw error;
            }

        }

        // retrieve the response from the ws
        let subscribersResponse = await response.text();

        console.log('********** subscribersResponse **********');
        console.log("subscribers - action getSubscribers - subscribersResponse="+subscribersResponse);
        console.log("subscribers - action getSubscribers - subscribersResponse.type="+typeof subscribersResponse);
        
        // --- parse the response as a json message -----------------------------------------------
        const jsonUserResponse = JSON.parse(subscribersResponse);
        
        let payloadSubscribers = jsonUserResponse.dataset;
        applicationStore.commitSetMessage(jsonUserResponse.message);
        
        console.log("subscribers - action getSubscribers - payloadsubscribers="+payloadSubscribers);
        console.log("subscribers - action getSubscribers - payloadSubscribers.type="+typeof payloadSubscribers);
        
        try {
            let jsonPayloadSubscribers = JSON.parse(payloadSubscribers);
            if(jsonPayloadSubscribers.rows.length>0){
                console.log(jsonPayloadSubscribers.rows[0].code); //penser à changer code par attribut propre à abonnés
            } else {
                console.log('Recherche introuvable');
            }
            

            console.log("subscribers - action getSubscribers - jsonPayloadSubscribers     ="+JSON.stringify(jsonPayloadSubscribers));
            console.log("subscribers - action getSubscribers - jsonPayloadSubscribers.type="+typeof jsonPayloadSubscribers);
            // --- set the dataset value in the context -----------------------------------------------
            this.setDataset(jsonPayloadSubscribers);

            console.log("subscribers - action getSubscribers - after commit setDataSet=");

        }
        catch (e) {
            console.log("error parsing payloadSubscribers=" + e.toString())
        }
        finally {
            console.log('action getSubscribers - end');
        }

    },
    async retrieveAbonne(payload) {
        const userStore = useUserStore();
        const authStore = useAuthStore();

        // --- get the address of the web service server ------------------------------------------
        let serverAddress = userStore.getServerAddress;

        // --- construct the url ------------------------------------------------------------------
        //url à redéfinir
        let url = serverAddress + '/artizen/ws/abonnes/lire?abonne=' + payload.abonne;

        // --- retrieve the token from auth store
        let token = authStore.getToken;

        // call the ws and wait for his answer
        const response = await fetch(url,
            {
                method: 'GET',
                headers: {
                    Authorization: 'JWT ' + token
                }
            });

        // if we had an error calling the ws
        if (!response.ok) {
            if(response.status==511){
                authStore.tryLogin();
            } else {
                const error = new Error('Failed to get user info. Check your login data.');
                throw error;
            }
        }

        // retrieve the reponse from the ws
        let subscriberResponse = await response.text();

        console.log('********** subscriberResponse **********');
        console.log(subscriberResponse);

        // parse the response as a json message
        const jsonSubscriberResponse = JSON.parse(subscriberResponse);
        console.log(jsonSubscriberResponse);
        // we store the infos in the vueX calling the setUser mutation
        this.commitSetAbonne({
            abonne:      jsonSubscriberResponse.abonne,
            designation: jsonSubscriberResponse.designation,
            siren:       jsonSubscriberResponse.siren,
            siret:       jsonSubscriberResponse.siret,
            email:       jsonSubscriberResponse.email,
            telFixe:     jsonSubscriberResponse.telFixe,
            telMobile:   jsonSubscriberResponse.telMobile,
            urlsite:     jsonSubscriberResponse.urlsite,
            actif:       (jsonSubscriberResponse.actif==='O'),
            supprime:    (jsonSubscriberResponse.supprime==='O'),
            numadresse : jsonSubscriberResponse.numadresse,
            iddocument : jsonSubscriberResponse.iddocument,
            nummodele :  jsonSubscriberResponse.nummodele,
            adresses:    jsonSubscriberResponse.adresses
        });
        console.log('action getSubscriber - fin');

    },
    async deleteElement(payload) {
        const userStore = useUserStore();
        const authStore = useAuthStore();
        console.log('action subscribers.deleteSubscriber - begin');
        console.log(payload);


        // --- get the address of the web service server ------------------------------------------
        let serverAddress = userStore.getServerAddress;

        // --- construct the url ------------------------------------------------------------------
        // à redéfinir
        let url = serverAddress + '/artizen/ws/abonnes/supprimer?abonne='+ payload.abonne;

        // retrieve the token from auth store
        let token = authStore.getToken;

        // call the ws and wait for his answer
        const response = await fetch(url,
          {
              method: 'PUT',
              headers: {
                  Authorization: 'JWT ' + token
              },
          });

        // if we had an error calling the ws
        if (!response.ok) {
            if(response.status==511){
                authStore.tryLogin();
            } else {
                const error = new Error('Failed to get user info. Check your login data.');
                throw error;
            }
        }

        // retrieve the reponse from the ws
        let subscriberResponse = await response.text();

        console.log('********** subscriberResponse **********');
        console.log(subscriberResponse);

        console.log('action subscribers.deleteSubscriber - end');

    },

    setDataset(dataset){
        this.dataset = dataset;
    },

    commitSetAbonne(abonne){
        this.subscriber = abonne;
    },

    async createAbonne(abonne){
        const userStore = useUserStore();
        const authStore = useAuthStore();
        console.log('action users.createUser - start');
        console.log(abonne);
        // --- get the address of the web service server ------------------------------------------
        let serverAddress = userStore.getServerAddress;

        // --- construct the url ------------------------------------------------------------------
        let url = serverAddress + '/artizen/ws/abonnes/creer';

        // --- retrieve the token from auth store -------------------------------------------------
        let token = authStore.getToken;

        let myActive  = (abonne.actif ?  'O':'N')
        let myDeleted = (abonne.supprime ?  'O':'N')
        console.log(abonne);
        // --- call the ws and wait for his answer ------------------------------------------------
        const response = await fetch(url,
          {
              method: 'PUT',
              headers: {
                  "Authorization": 'JWT ' + token,
                  "accept":        "application/json",
                  "Content-Type":  "application/json",
              },
              body: JSON.stringify({
                  abonne : abonne.abonne,
                  designation : abonne.designation,
                  siren : abonne.siren,
                  siret : abonne.siret,
                  email : abonne.email,
                  telFixe : abonne.telFixe,
                  telMobile : abonne.telMobile,
                  urlsite : abonne.urlsite,
                  actif : myActive,
                  supprime : myDeleted,
                  numadresse : abonne.numadresse,
                  iddocument : abonne.iddocument,
                  nummodele : abonne.nummodele,
                  adresses : abonne.adresses
              })
          });

        if (!response.ok) {

            // --- if we had an error while calling the web service -------------------------------
            console.log("createUser, response is not ok");
            console.log(response);

            if (response.status === 404) {
                let errorResponse = await response.text();

                console.log("errorResponse=");
                console.log(errorResponse);

                let jsonErrorResponse = JSON.parse(errorResponse);

                console.log("jsonErrorResponse=");
                console.log(jsonErrorResponse);

                this.setAbonneError(jsonErrorResponse);
                this.setAbonneUpdateReturnCode(404);
            } else if(response.status == 511){
                this.setAbonneError(null);
                this.setAbonneUpdateReturnCode(511);
                throw 511;
            } else {
                this.setAbonneError(null);
                this.setAbonneUpdateReturnCode(999)
                const error = new Error('Failed to set user info. Check your login data.');
                throw error;
            }
        }
        else {
            // result is ok for the user creation
            this.setAbonneError(null);
            this.setAbonneUpdateReturnCode(200)
        }

        console.log('action users.createUser - end');
    },

    setAbonneError(error){
        this.subscriberErrors = error
    },

    setAbonneUpdateReturnCode(code){
        this.subscriberUpdateReturnCode = code;
    },

    async setAbonne(payload) {

        const userStore = useUserStore();
        const authStore = useAuthStore();
        console.log('action users.setUser - start');


        // --- get the address of the web service server ------------------------------------------
        let serverAddress = userStore.getServerAddress;

        // --- construct the url ------------------------------------------------------------------
        let url = serverAddress + '/artizen/ws/abonnes/modifier';

        // retrieve the token from auth store
        let token = authStore.getToken;

        let myActive  = (payload.actif ?  'O':'N')
        let myDeleted = (payload.supprime ?  'O':'N')
        console.log(payload)
        
        // call the ws and wait for his answer
        const response = await fetch(url,
            {
                method: 'PUT',
                headers: {
                    "Authorization": 'JWT ' + token,
                    "accept":        "application/json",
                    "Content-Type":  "application/json",
                },
                body: JSON.stringify({
                  abonne : payload.abonne,
                  designation : payload.designation,
                  siren : payload.siren,
                  siret : payload.siret,
                  email : payload.email,
                  telFixe : payload.telFixe,
                  telMobile : payload.telMobile,
                  urlsite : payload.urlsite,
                  actif : myActive,
                  supprime : myDeleted,
                  numadresse : payload.numadresse,
                  iddocument : payload.iddocument,
                  nummodele : payload.nummodele,
                  adresses : payload.adresses
                })
            });

        if (!response.ok) {

            // if we had an error while calling the web service
            console.log("setUser, response is not ok");
            console.log(response);

            if (response.status === 404) {
                let errorResponse = await response.text();

                console.log("errorResponse=");
                console.log(errorResponse);

                let jsonErrorResponse = JSON.parse(errorResponse);

                console.log("jsonErrorResponse=");
                console.log(jsonErrorResponse);

                this.setAbonneError(jsonErrorResponse);
                this.setAbonneUpdateReturnCode(404)
            } else if(response.status == 511){
                this.setAbonneError(null);
                this.setAbonneUpdateReturnCode(511);
                throw 511;
            } else {
                this.setAbonneError(null);
                this.setAbonneUpdateReturnCode(999)
                const error = new Error('Failed to set user info. Check your login data.');
                throw error;
            }
        }
        else {
            // result is ok for the user update
            this.setAbonneError(null);
            this.setAbonneUpdateReturnCode(200)

            // we store the infos in the vueX calling the setUser mutation
            this.commitSetAbonne({
                  abonne : payload.abonne,
                  designation : payload.designation,
                  siren : payload.siren,
                  siret : payload.siret,
                  email : payload.email,
                  telFixe : payload.telFixe,
                  telMobile : payload.telMobile,
                  urlsite : payload.urlsite,
                  actif : myActive,
                  supprime : myDeleted,
                  numadresse : payload.numadresse,
                  iddocument : payload.iddocument,
                  nummodele : payload.nummodele,
                  adresses : payload.adresses
            });
        }

        console.log('action users.setUser - end');
    },

    async updateAbonne(payload) {

        console.log('action users.updateUser - start');

        // we update the infos in the vueX calling the updateUser mutation
        this.commitUpdateAbonne(payload);

        console.log('action users.updateUser - end');
    },

    commitUpdateAbonne(payload){
        console.log('mutation users.updateUser - begin');

        console.log(">>> users.updateUser - payload");
        console.log(payload);
        console.log(">>> dataset before changes <<<")
        console.log(this.dataset.rows);

        let localSubscriber =  payload.abonne;


        this.dataset.rows.forEach((element, index) => {
            if ((this.dataset.rows[index].abonne === localSubscriber)) {
                this.dataset.rows[index].actif = payload.active ? 'O' : 'N';
                this.dataset.rows[index].designation = payload.adresses[0].adrdesignation;
                this.dataset.rows[index].email = payload.email;
                this.dataset.rows[index].supprime = payload.deleted ? 'O' : 'N';
            }
        });

        console.log(">>> users.updateUser - dataset");
        console.log(this.dataset.rows);

        console.log('mutation users.updateUser - end');
    },

    async retrieveCommune(codepostal){
        const userStore = useUserStore();
        const authStore = useAuthStore();
        console.log('action subscribers.retrieveCommune - start');


        // --- get the address of the web service server ------------------------------------------
        let serverAddress = userStore.getServerAddress;

        // --- construct the url ------------------------------------------------------------------
        let url = serverAddress + '/artizen/ws/combo/communes?codepostal='+codepostal;

        // retrieve the token from auth store
        let token = authStore.getToken;
        this.setListeCommunes([]);
        const response = await fetch(url,
            {
                method: 'GET',
                headers: {
                    "Authorization": 'JWT ' + token,
                }
            });
        if(response.ok){
            let subscribersResponse = await response.text();

            console.log('********** subscribersResponse **********');
            console.log("subscribers - action getSubscribers - subscribersResponse="+subscribersResponse);
            console.log("subscribers - action getSubscribers - subscribersResponse.type="+typeof subscribersResponse);
            
            // --- parse the response as a json message -----------------------------------------------
            const jsonUserResponse = JSON.parse(subscribersResponse);
            
            let payloadCommunes = jsonUserResponse.listedescommunes;
            this.setListeCommunes(payloadCommunes)
        }
    },
    setListeCommunes(payload){
        this.listedescommunes = payload;
    }


};