<template>
  <div class="globalDiv">
    <div class="labelDiv" :style="{ width: `${labelSize}px` }">
      <p ref="labelRef"  >{{ label }}</p>
    </div>
    <div class="slotDiv">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, watch } from 'vue'
export default {
  props: ['label'],
  setup(props){
    const labelRef = ref(props.label);
    const labelSize  = ref(null);
    
    function calcLabelNotch(){
        labelSize.value = labelRef.value.clientWidth + 10;
    }
    watch(labelRef, (_, _2) => {
      calcLabelNotch();
    })

    onMounted(() => {
      calcLabelNotch();
    })

    return {
      labelRef,
      labelSize
    }

  }
}
</script>

<style scoped>
.globalDiv {
  border: 1px solid #bdbdbd;
  border-radius: 0.25rem;
}
.slotDiv{
  margin: 0rem 1rem 1rem 1rem;
}
p{
  font-size: 0.85rem;
  color: #616161;
  margin-bottom: 0;
}
.labelDiv{
  position: relative;
  display: flex;
  background-color:white;
  left: 0.47rem;
  top: -0.7rem;
  padding-left: 0.25rem;
  margin-bottom: 0;
}


</style>