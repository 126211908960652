import { useUserStore }        from '../user/user';
import { useAuthStore }        from '../auth/auth';
import { useApplicationStore } from '../application/application';

export default {

    async retrieveData(payload=null) {
        const applicationStore = useApplicationStore();
        const userStore        = useUserStore();
        const authStore        = useAuthStore();
        console.log('users - action getEntites - begin');


        // --- get the address of the web service server ------------------------------------------
        let serverAddress = userStore.getServerAddress;

        console.log("entites - action retrieveEntites - serverAddress="+serverAddress);

        // --- construct the url ------------------------------------------------------------------
        let url = serverAddress + '/artizen/ws/zones/listedatatable'; //url to define
        if(!!payload && (!!payload['entite'] || !!payload['zone'])){
            url+="?";
            let index = 0;

            // --- we now iterate over the payload containing the search filters the user chose
            for(const data in payload){
                url+=data+"="+payload[data]; // --- data corresponds to the name of the attribute (user for instance)
                                             // --- and payload[data] gets the value in the payload linked to the property name

                if(index < Object.keys(payload).length-1){ // --- in the end we check if we reached the end of the payload or not
                    url+="&";                              // --- and if it's not, we add & to continue adding query elements
                }
            }
        }
        console.log("zones - action getZones - url="+url);

        // --- retrieve the token from auth store -------------------------------------------------
        let token = authStore.getToken;

        // call the ws and wait for his answer
        const response = await fetch(url,
            {
                method: 'GET',
                headers: {
                    Authorization: 'JWT ' + token
                }
            });

        // if we had an error calling the ws
        if (!response.ok) {
            if(response.status==511){
                authStore.tryLogin();
            } else {
                const error = new Error('Failed to get user info. Check your login data.');
                throw error;
            }

        }

        // retrieve the response from the ws
        let zonesResponse = await response.text();

        console.log('********** subscribersResponse **********');
        console.log("zones - action getZones - zonesResponse="+zonesResponse);
        console.log("zones - action getZones - zonesResponse.type="+typeof zonesResponse);

        // --- parse the response as a json message -----------------------------------------------
        const jsonZonesResponse = JSON.parse(zonesResponse);

        let payloadZones = jsonZonesResponse.dataset;
        applicationStore.commitSetMessage(jsonZonesResponse.message);

        console.log("zones - action getZones - payloadZones="+payloadZones);
        console.log("zones - action getZones - payloadZones.type="+typeof payloadZones);

        try {
            let jsonPayloadZones = JSON.parse(payloadZones);
            if(jsonPayloadZones.rows.length>0){
                console.log(jsonPayloadZones.rows[0].code); //penser à changer code par attribut propre à abonnés
            } else {
                console.log('Recherche introuvable');
            }


            console.log("zones - action getZones - jsonPayloadZones     ="+JSON.stringify(jsonPayloadZones));
            console.log("zones - action getZones - jsonPayloadZones.type="+typeof jsonPayloadZones);
            // --- set the dataset value in the context -----------------------------------------------
            this.setDataset(jsonPayloadZones);

            console.log("zones - action getZones - after commit setDataSet=");

        }
        catch (e) {
            console.log("error parsing payloadZones=" + e.toString())
        }
        finally {
            console.log('action getZones - end');
        }

    },
    async retrieveZone(zone) {
        const userStore = useUserStore();
        const authStore = useAuthStore();

        // --- get the address of the web service server ------------------------------------------
        let serverAddress = userStore.getServerAddress;

        // --- construct the url ------------------------------------------------------------------
        //url à redéfinir
        let url = serverAddress + '/artizen/ws/zones/lire?entite=' + zone.entite + '&zone=' + zone.zone;

        // --- retrieve the token from auth store
        let token = authStore.getToken;

        // call the ws and wait for his answer
        const response = await fetch(url,
            {
                method: 'GET',
                headers: {
                    Authorization: 'JWT ' + token
                }
            });

        // if we had an error calling the ws
        if (!response.ok) {
            if(response.status==511){
                authStore.tryLogin();
            } else {
                const error = new Error('Failed to get user info. Check your login data.');
                throw error;
            }
        }

        // retrieve the reponse from the ws
        let zoneResponse = await response.text();

        console.log('********** zoneResponse **********');
        console.log(zoneResponse);

        // parse the response as a json message
        const jsonZoneResponse = JSON.parse(zoneResponse);
        console.log(jsonZoneResponse);
        // we store the infos in the vueX calling the setUser mutation
        this.commitSetZone({
            codeEntite             : jsonZoneResponse.codeEntite                             ,
            codeZone               : jsonZoneResponse.codeZone                               ,
            designationZone        : jsonZoneResponse.designationZone                        ,
            designationZoneColonne : jsonZoneResponse.designationZoneColonne                 ,
            alignementColonne      : jsonZoneResponse.alignementColonne                      ,
            typeDeZone             : jsonZoneResponse.typeDeZone                             ,
            taille                 : jsonZoneResponse.taille.toString()                      ,
            nombreDecimales        : jsonZoneResponse.nombreDecimales.toString()             ,
            objetGraphique         : jsonZoneResponse.objetGraphique                         ,
            traitementAssocie      : jsonZoneResponse.traitementAssocie                      ,
            valeurGeneree          : jsonZoneResponse.valeurGeneree     == 'O' ? true : false,
            zoneVirtuelleBloc      : jsonZoneResponse.zoneVirtuelleBloc == 'O' ? true : false,
            identifiantentite      : jsonZoneResponse.identifiantentite == 'O' ? true : false,
            triableEnListe         : jsonZoneResponse.triableEnListe    == 'O' ? true : false,
            numeroidentifiant      : jsonZoneResponse.numeroidentifiant.toString()           ,
        });
        await this.retrieveComboObjGraphique(token, serverAddress);
        await this.retrieveComboTypeDeZone(token, serverAddress);
        console.log('action getZone - fin');

    },
    async retrieveComboObjGraphique(token, serverAddress){
      const url          = serverAddress+'/artizen/ws/combo/htables?table=OBJ_GRAPHIQUE';
      const request      = await fetch(url,
          {
              method: 'GET',
              headers: {
                  Authorization : 'JWT ' + token
              }
          }
      );
      let response = JSON.parse(await request.text())['listeDesElements'];
      console.log('PARSED JSON COMBO OBJET GRAPHIQUE = ');
      console.log(response);
      let objGraphiques = [];
      for(const elem of response){
          objGraphiques.push(
              {
                  text: elem.valeur,
                  value: elem.code,
                  selected: (elem.code == this.zone.objetGraphique)
              }
          )
      }
      this.objetsGraphiques = objGraphiques;
    },
    async retrieveComboTypeDeZone(token, serverAddress){
        const url          = serverAddress+'/artizen/ws/combo/htables?table=TYPE_ZONE';
        const request      = await fetch(url,
            {
                method: 'GET',
                headers: {
                    Authorization : 'JWT ' + token
                }
            }
        );
        let response = JSON.parse(await request.text())['listeDesElements'];
        console.log('PARSED JSON COMBO TYPE DE ZONE = ' + response);
        console.log(response);
        let typesZones = [];
        for(const elem of response){
            typesZones.push(
                {
                    text: elem.valeur,
                    value: elem.code,
                    selected: (elem.code == this.zone.typeDeZone)
                }
            )
        }
        this.typesDeZones = typesZones;
    },
    async deleteElement(payload) {
        const userStore = useUserStore();
        const authStore = useAuthStore();
        console.log('action zones.deleteZones - begin');
        console.log(payload);


        // --- get the address of the web service server ------------------------------------------
        let serverAddress = userStore.getServerAddress;

        // --- construct the url ------------------------------------------------------------------
        // à redéfinir
        let url = serverAddress + '/artizen/ws/zones/supprimer?entite='+ payload.entite + '&zone=' + payload.zone;

        // retrieve the token from auth store
        let token = authStore.getToken;

        // call the ws and wait for his answer
        const response = await fetch(url,
          {
              method: 'PUT',
              headers: {
                  Authorization: 'JWT ' + token
              },
          });

        // if we had an error calling the ws
        if (!response.ok) {
            if(response.status==511){
                authStore.tryLogin();
            } else {
                const error = new Error('Failed to get user info. Check your login data.');
                throw error;
            }
        }

        // retrieve the reponse from the ws
        let zoneResponse = await response.text();

        console.log('********** zoneResponse **********');
        console.log(zoneResponse);

        console.log('action zones.deleteZones - end');

    },

    setDataset(dataset){
        this.dataset = dataset;
    },

    commitSetZone(zone){
        this.zone = zone;
    },

    async createZone(zone){
        const userStore = useUserStore();
        const authStore = useAuthStore();
        console.log('action zones.createZone - start');
        console.log(zone);
        // --- get the address of the web service server ------------------------------------------
        let serverAddress = userStore.getServerAddress;

        // --- construct the url ------------------------------------------------------------------
        let url = serverAddress + '/artizen/ws/zones/creer';

        // --- retrieve the token from auth store -------------------------------------------------
        let token = authStore.getToken;

        // --- call the ws and wait for his answer ------------------------------------------------
        const response = await fetch(url,
          {
              method: 'PUT',
              headers: {
                  "Authorization": 'JWT ' + token,
                  "accept":        "application/json",
                  "Content-Type":  "application/json",
              },
              body: JSON.stringify({
                  codeEntite             : zone.codeEntite                   ,
                  codeZone               : zone.codeZone                     ,
                  designationZone        : zone.designationZone              ,
                  designationZoneColonne : zone.designationZoneColonne       ,
                  alignementColonne      : zone.alignementColonne            ,
                  typeDeZone             : zone.typeDeZone                   ,
                  taille                 : parseInt(zone.taille)             ,
                  nombreDecimales        : parseInt(zone.nombreDecimales)    ,
                  objetGraphique         : zone.objetGraphique               ,
                  traitementAssocie      : zone.traitementAssocie            ,
                  valeurGeneree          : zone.valeurGeneree     ? 'O' : 'N',
                  zoneVirtuelleBloc      : zone.zoneVirtuelleBloc ? 'O' : 'N',
                  identifiantentite      : zone.identifiantentite ? 'O' : 'N',
                  triableEnListe         : zone.triableEnListe    ? 'O' : 'N',
                  numeroidentifiant      : parseInt(zone.numeroidentifiant)  ,
              })
          });

        if (!response.ok) {

            // --- if we had an error while calling the web service -------------------------------
            console.log("createUser, response is not ok");
            console.log(response);

            if (response.status === 404) {
                let errorResponse = await response.text();

                console.log("errorResponse=");
                console.log(errorResponse);

                let jsonErrorResponse = JSON.parse(errorResponse);

                console.log("jsonErrorResponse=");
                console.log(jsonErrorResponse);

                this.setZoneError(jsonErrorResponse);
                this.setZoneUpdateReturnCode(404);
            } else if(response.status == 511){
                this.setZoneError(null);
                this.setZoneUpdateReturnCode(511);
                throw 511;
            } else {
                this.setZoneError(null);
                this.setZoneUpdateReturnCode(999)
                throw new Error('Failed to set user info. Check your login data.');
            }
        }
        else {
            // result is ok for the user creation
            this.setZoneError(null);
            this.setZoneUpdateReturnCode(200)
        }

        console.log('action zones.createZone - end');
    },

    setZoneError(error){
        this.zoneError = error
    },

    setZoneUpdateReturnCode(code){
        this.zoneUpdateReturnCode = code;
    },

    async setZone(zone) {

        const userStore = useUserStore();
        const authStore = useAuthStore();
        console.log('action zones.setZones - start');


        // --- get the address of the web service server ------------------------------------------
        let serverAddress = userStore.getServerAddress;

        // --- construct the url ------------------------------------------------------------------
        let url = serverAddress + '/artizen/ws/zones/modifier';

        // retrieve the token from auth store
        let token = authStore.getToken;

        console.log(zone)

        // call the ws and wait for his answer
        const response = await fetch(url,
            {
                method: 'PUT',
                headers: {
                    "Authorization": 'JWT ' + token,
                    "accept":        "application/json",
                    "Content-Type":  "application/json",
                },
                body: JSON.stringify({
                    codeEntite             : zone.codeEntite                   ,
                    codeZone               : zone.codeZone                     ,
                    designationZone        : zone.designationZone              ,
                    designationZoneColonne : zone.designationZoneColonne       ,
                    alignementColonne      : zone.alignementColonne            ,
                    typeDeZone             : zone.typeDeZone                   ,
                    taille                 : parseInt(zone.taille)             ,
                    nombreDecimales        : parseInt(zone.nombreDecimales)    ,
                    objetGraphique         : zone.objetGraphique               ,
                    traitementAssocie      : zone.traitementAssocie            ,
                    valeurGeneree          : zone.valeurGeneree     ? 'O' : 'N',
                    zoneVirtuelleBloc      : zone.zoneVirtuelleBloc ? 'O' : 'N',
                    identifiantentite      : zone.identifiantentite ? 'O' : 'N',
                    triableEnListe         : zone.triableEnListe    ? 'O' : 'N',
                    numeroidentifiant      : parseInt(zone.numeroidentifiant)  ,
                })
            });

        if (!response.ok) {

            // if we had an error while calling the web service
            console.log("setZones, response is not ok");
            console.log(response);

            if (response.status === 404) {
                let errorResponse = await response.text();

                console.log("errorResponse=");
                console.log(errorResponse);

                let jsonErrorResponse = JSON.parse(errorResponse);

                console.log("jsonErrorResponse=");
                console.log(jsonErrorResponse);

                this.setZoneError(jsonErrorResponse);
                this.setZoneUpdateReturnCode(404)
            } else if(response.status == 511){
                this.setZoneError(null);
                this.setZoneUpdateReturnCode(511);
                throw 511;
            } else {
                this.setZoneError(null);
                this.setZoneUpdateReturnCode(999)
                throw new Error('Failed to set user info. Check your login data.');
            }
        }
        else {
            // result is ok for the user update
            this.setZoneError(null);
            this.setZoneUpdateReturnCode(200)

            // we store the infos in the vueX calling the setUser mutation
            this.commitSetZone({
                codeEntite             : zone.codeEntite                   ,
                codeZone               : zone.codeZone                     ,
                designationZone        : zone.designationZone              ,
                designationZoneColonne : zone.designationZoneColonne       ,
                alignementColonne      : zone.alignementColonne            ,
                typeDeZone             : zone.typeDeZone                   ,
                taille                 : parseInt(zone.taille)             ,
                nombreDecimales        : parseInt(zone.nombreDecimales)    ,
                objetGraphique         : zone.objetGraphique               ,
                traitementAssocie      : zone.traitementAssocie            ,
                valeurGeneree          : zone.valeurGeneree                ,
                zoneVirtuelleBloc      : zone.zoneVirtuelleBloc            ,
                identifiantentite      : zone.identifiantentite            ,
                triableEnListe         : zone.triableEnListe               ,
                numeroidentifiant      : parseInt(zone.numeroidentifiant)
            });
        }

        console.log('action users.setUser - end');
    },

    async updateZone(payload) {

        console.log('action entites.updateEntite - start');

        // we update the infos in the vueX calling the updateUser mutation
        this.commitUpdateZone(payload);

        console.log('action entites.updateEntite - end');
    },

    commitUpdateZone(payload){
        console.log('mutation entite.updateEntite - begin');

        console.log(">>> entites.updateEntites - payload");
        console.log(payload);
        console.log(">>> dataset before changes <<<")
        console.log(this.dataset.rows);

        let entite = payload.codeEntite;
        let zone   = payload.codeZone;


        this.dataset.rows.forEach((element, index) => {
            if ((this.dataset.rows[index].entite === entite && this.dataset.rows[index].zone === zone)) {
                this.dataset.rows[index].designationZone        = payload.designationZone;
                this.dataset.rows[index].designationZoneColonne = payload.designationZoneColonne;
                this.dataset.rows[index].alignementColonne      = payload.alignementColonne;
                this.dataset.rows[index].typeDeZone             = payload.typeDeZone;
                this.dataset.rows[index].taille                 = payload.taille;
                this.dataset.rows[index].nombreDecimales        = payload.nombreDecimales;
                this.dataset.rows[index].objetGraphique         = payload.objetGraphique;
                this.dataset.rows[index].traitementAssocie      = payload.traitementAssocie;
                this.dataset.rows[index].valeurGeneree          = payload.valeurGeneree;
                this.dataset.rows[index].zoneVirtuelleBloc      = payload.zoneVirtuelleBloc;
                this.dataset.rows[index].identifiantentite      = payload.identifiantentite;
                this.dataset.rows[index].numeroidentifiant      = payload.numeroidentifiant;
            }
        });

        console.log(">>> entites.updateEntites - dataset");
        console.log(this.dataset.rows);

        console.log('mutation entites.updateEntite - end');
    },

};