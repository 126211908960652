<template>
    <MDBBtn :color="typeToColor" :type="type" :class="btnClass">

        <MDBIcon     v-if="icon" :icon="icon" size="lg" />
        <slot        v-if="!link"></slot>
        <router-link v-else :to="to" >

            <slot></slot>
            
        </router-link>

    </MDBBtn>

</template>

<script>
import {MDBBtn, MDBIcon, MDBPopconfirm} from "mdb-vue-ui-kit"
import {computed, ref} from "vue";

export default {
    components: {
        MDBBtn,
        MDBIcon,
        MDBPopconfirm
    },
    props:{
        mode:{
            type: String,
            required: false,
            default: null
        },
        link: {
            type: Boolean,
            required: false,
            default: false
        },
        to: {
            type: String,
            required: false,
            default: '/'
        },
        icon: {
            type: String,
            required: false,
            default: null
        },
        type: {
            type: String,
            required: false,
            default: null
        },
        floating: {
            type     : Boolean,
            required : false,
            default  : false
        }
    },
    setup(props){
      let btnClass = ref('me-1' + (props.floating==true ? ' floating' : ''));
      const typeToColor = computed(() => {
        switch(props.mode){
                case "modifier":
                    return "primary";
                case "supprimer":
                    return "danger";
                case "annuler":
                    return "warning";
                case "effacer":
                    return "danger";
                case "enregistrer":
                    return "success";
                case "rechercher":
                    return "primary";
                case "creer":
                    return "success";
                case "actualiser":
                    return "secondary";
                default:
                    return "primary";
            }
      })


      return {
        typeToColor,
        btnClass
      }
    }
}
</script>

<style scoped>
    a {
        color: white;
    }
    button{
        display: flex;
        align-items: center;
    }
    i {
        margin-right: 0.4em;
    }
    .floating {
      position:fixed;
      bottom: 20px;
      right: 20px;
      z-index: 100;
    }
</style>