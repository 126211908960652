import { defineStore } from 'pinia';

import getters from './getters';
import actions from './actions';

export const useCommunesStore = defineStore('communes', {
    state: () => ({
        dataset: 
        {
            columns : ['Actions', 'Code postal', 'Code insee', 'Commune', 'Département', 'Région', 'GPS', 'Actif'],
            rows    : []
        }
        }),
        getters,
        actions
})