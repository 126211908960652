<template>
  <MDBAlert :autohide="autohide" color="info" static class="ms-3 me-3">
    <slot></slot>
  </MDBAlert>
</template>

<script>
import { MDBAlert } from 'mdb-vue-ui-kit'
export default {
  components: {
    MDBAlert
  },
  props: {
    autohide: {
      type: Boolean,
      required: false,
      default: false
    },
  }

}
</script>

<style scoped>
  div {
    margin: 1rem;
  }
</style>