/* eslint-disable */
<template>

  <MDBNavbar expand=   "lg"
             dark
             bg=       "dark"
             position= "sticky"
             container>
    <MDBNavbarBrand href="#">hexArtizen</MDBNavbarBrand>
    <MDBNavbarToggler
        @click="collapseMenu = !collapseMenu"
        target="#navbarSupportedContent"
    ></MDBNavbarToggler>
    <MDBCollapse v-model="collapseMenu" id="navbarSupportedContent">
      <MDBNavbarNav class="mb-2 mb-lg-0">
        <MDBNavbarItem to="/"
                       :active="currentItem=='itemHome'">
          Accueil
        </MDBNavbarItem>
        <MDBNavbarItem href="#"
                       :active="currentItem=='itemCustomers'"
                       v-if="isUserAuthenticated">
          Clients
        </MDBNavbarItem>
        <MDBNavbarItem href="#"
                       :active="currentItem=='itemProducts'"
                       v-if="isUserAuthenticated">
          Produits
        </MDBNavbarItem>
        <MDBNavbarItem href="#"
                       to="/agenda"
                       :active="currentItem=='itemAgenda'"
                       v-if="isUserAuthenticated">
          Agenda
        </MDBNavbarItem>
        <MDBNavbarItem href="#"
                       :active="currentItem=='itemInvoices'"
                       v-if="isUserAuthenticated">
          Factures
        </MDBNavbarItem>
        <MDBNavbarItem v-if="isUserAuthenticated">
          <!-- Navbar dropdown -->
          <MDBDropdown class="nav-item" v-model="dropdownAdministration">
            <MDBDropdownToggle tag="a"
                               class="nav-link"
                               @click="dropdownAdministration = !dropdownAdministration"
            >Administration</MDBDropdownToggle>

            <MDBDropdownMenu aria-labelledby="dropdownMenuButton">
              <MDBDropdownItem href="#" disabled :active="currentItem=='itemParameters'">Paramètres  </MDBDropdownItem>
              <MDBDropdownItem href="#" disabled :active="currentItem=='itemSubscribers'">Abonnés</MDBDropdownItem>
              <MDBDropdownItem href="#" to="/utilisateurs" :active="currentItem=='itemUsers'">Utilisateurs</MDBDropdownItem>
            </MDBDropdownMenu>
          </MDBDropdown>
        </MDBNavbarItem>

        <MDBNavbarItem to="/auth"
                       @click="collapseMenu=false;"
                       :active="currentItem=='itemAuthentication'"
                       v-else>
          Authentification
        </MDBNavbarItem>

        <!-- Avatar -->
        <MDBDropdown class="nav-item"
                     v-model="dropdownAvatar"
                     v-if="isUserAuthenticated">
          <MDBDropdownToggle tag="a" class="nav-link" @click="dropdownAvatar = !dropdownAvatar"
          ><img
              src="https://mdbootstrap.com/img/Photos/Avatars/img (31).webp"
              class="rounded-circle"
              height="22"
              alt=""
              loading="lazy"
          />
          </MDBDropdownToggle>
          <MDBDropdownMenu>
            <MDBDropdownItem :to="personalDatasRoute" @click="closeDropDownMenu">
              Mes informations
            </MDBDropdownItem>
            <MDBDropdownItem :to="compagnyDatasRoute" @click="closeDropDownMenu">
              Ma société
            </MDBDropdownItem>
            <MDBDropdownItem href="#">
              Paramètres
            </MDBDropdownItem>
            <MDBDropdownItem v-if="isAdminHaz && !isSubscribedAs" to="/abonnes" @click="closeDropDownMenu">
              Sélectionner un "abonné en tant que"
            </MDBDropdownItem>
            <MDBDropdownItem v-else href="#" @click="removeSubscribedAs" >
              Annuler "abonné en tant que"
            </MDBDropdownItem>
            <MDBDropdownItem v-if="isAdminHaz" to="/test/mask" @click="closeDropDownMenu">
              Tests mask
            </MDBDropdownItem>
            <MDBDropdownItem href="#" @click="menuLogout">
              Me déconnecter
            </MDBDropdownItem>
          </MDBDropdownMenu>
        </MDBDropdown>
      </MDBNavbarNav>

      <!-- Search form -->
      <form class="d-flex input-group w-auto">
        <input
            type="search"
            class="form-control"
            placeholder="Type query"
            aria-label="Search"
        />
        <MDBBtn outline="primary">
          Search
        </MDBBtn>
      </form>

    </MDBCollapse>
  </MDBNavbar>

</template>

<script>
import {
  MDBBtn,
  MDBNavbar,
  MDBNavbarToggler,
  MDBNavbarBrand,
  MDBNavbarNav,
  MDBNavbarItem,
  MDBCollapse,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem
} from 'mdb-vue-ui-kit';

import { ref       } from 'vue';
import { computed  } from 'vue';
// import { useStore  } from 'vuex';
import { useAuthStore } from '@/pinia/auth/auth.js';
import { useUserStore } from '@/pinia/user/user.js';
import { useRouter } from 'vue-router';

export default {
  components: {
    MDBBtn,
    MDBNavbar,
    MDBNavbarToggler,
    MDBNavbarBrand,
    MDBNavbarNav,
    MDBNavbarItem,
    MDBCollapse,
    MDBDropdown,
    MDBDropdownToggle,
    MDBDropdownMenu,
    MDBDropdownItem
  },

  setup() {

    // --- Store declaration ----------------------------------------------------------------------
    // const store = useStore();
    const authStore = useAuthStore();
    const userStore = useUserStore();

    // --- Router declaration ---------------------------------------------------------------------
    const router = useRouter();

    // --- References -----------------------------------------------------------------------------
    const collapseMenu           = ref(false);
    const dropdownAdministration = ref(false);
    const dropdownAvatar         = ref(false);
    console.log("ArtHeader - menu setup start");

    // --- Computed -------------------------------------------------------------------------------
    const currentActiveItem = computed(function() {

      console.log("ArtHeader - currentActiveItem start");

      // ___ get the active menu item from the vueX context
      return 'itemAuthentication';
    });

    const isUserAuthenticated = computed( function () {

      console.log("ArtHeader - isUserAuthenticated start");

      // ___ is user authenticated ? get the info from the store.
      return authStore.getIsAuthenticated;
    });

    const personalDatasRoute = computed(() => {
      if(isUserAuthenticated){
        return '/utilisateur/' + userStore.getSubscriber + '/' + userStore.getUser;
      } else {
        return '';
      }
    });

    const compagnyDatasRoute = computed(() => {
      if(isUserAuthenticated){
        return '/abonne/' + userStore.getSubscriber;
      } else {
        return '';
      }
    })

    // --- Methods --------------------------------------------------------------------------------
    function closeDropDownMenu(){
      collapseMenu.value           = false;
      dropdownAvatar.value         = false;
      dropdownAdministration.value = false;
    }

    function menuLogout() {

      // ___ Manage the dropdownAdministration and dropdownAvatar variables in order to make the menu disappear on small devices
      closeDropDownMenu();

      // ___ trigger the "clearUser" actions (from auth and user)
      // store.dispatch('userNS/clearUser');
      userStore.clearUser();
      // store.dispatch('clearUser');
      authStore.clearUser();

      // ___ go to the authentication page
      router.push("/auth");
    }

    function mainMenu(){
      router.push('/');
    }

    function removeSubscribedAs() {
      userStore.setSubscribedAs(null);
    }

    const isAdminHaz = computed(() => {
      return userStore.getIsAdminHaz;
    })

    const isSubscribedAs = computed(() => {
      return userStore.subscribedAs!==null;
    })


    // --- Returned objects -----------------------------------------------------------------------
    return {
      collapseMenu,
      dropdownAdministration,
      dropdownAvatar,
      currentItem : currentActiveItem,
      isUserAuthenticated,
      menuLogout,
      closeDropDownMenu,
      mainMenu,
      personalDatasRoute,
      compagnyDatasRoute,
      isAdminHaz,
      isSubscribedAs,
      removeSubscribedAs,
    }
  }
};

</script>

<style scoped>
li:hover {
  cursor: pointer;
}
</style>