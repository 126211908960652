import { useUserStore }        from '../user/user';
import { useAuthStore }        from '../auth/auth';
import { useApplicationStore } from '../application/application';

export default {

    async retrieveData(payload=null) {
        const applicationStore = useApplicationStore();
        const userStore        = useUserStore();
        const authStore        = useAuthStore();
        console.log('users - action getEntites - begin');


        // --- get the address of the web service server ------------------------------------------
        let serverAddress = userStore.getServerAddress;

        console.log("entites - action retrieveEntites - serverAddress="+serverAddress);

        // --- construct the url ------------------------------------------------------------------
        let url = serverAddress + '/artizen/ws/entites/listedatatable'; //url to define
        if(!!payload){
            url += !!(payload.entite) ? '?entite='+payload.en : '';
        }
        console.log("entites - action getEntites - url="+url);

        // --- retrieve the token from auth store -------------------------------------------------
        let token = authStore.getToken;

        // call the ws and wait for his answer
        const response = await fetch(url,
            {
                method: 'GET',
                headers: {
                    Authorization: 'JWT ' + token
                }
            });

        // if we had an error calling the ws
        if (!response.ok) {
            if(response.status==511){
                authStore.tryLogin();
            } else {
                const error = new Error('Failed to get user info. Check your login data.');
                throw error;
            }

        }

        // retrieve the response from the ws
        let entitesResponse = await response.text();

        console.log('********** subscribersResponse **********');
        console.log("entites - action getEntites - entitesResponse="+entitesResponse);
        console.log("entites - action getEntites - entitesResponse.type="+typeof entitesResponse);

        // --- parse the response as a json message -----------------------------------------------
        const jsonEntitesResponse = JSON.parse(entitesResponse);

        let payloadEntites = jsonEntitesResponse.dataset;
        applicationStore.commitSetMessage(jsonEntitesResponse.message);

        console.log("entites - action getEntites - payloadEntites="+payloadEntites);
        console.log("entites - action getEntites - payloadEntites.type="+typeof payloadEntites);

        try {
            let jsonPayloadEntites = JSON.parse(payloadEntites);
            if(jsonPayloadEntites.rows.length>0){
                console.log(jsonPayloadEntites.rows[0].code); //penser à changer code par attribut propre à abonnés
            } else {
                console.log('Recherche introuvable');
            }


            console.log("entites - action getEntites - jsonPayloadEntites     ="+JSON.stringify(jsonPayloadEntites));
            console.log("entites - action getEntites - jsonPayloadEntites.type="+typeof jsonPayloadEntites);
            // --- set the dataset value in the context -----------------------------------------------
            this.setDataset(jsonPayloadEntites);

            console.log("entites - action getEntites - after commit setDataSet=");

        }
        catch (e) {
            console.log("error parsing payloadEntites=" + e.toString())
        }
        finally {
            console.log('action getEntites - end');
        }

    },
    async retrieveEntite(entite) {
        const userStore = useUserStore();
        const authStore = useAuthStore();

        // --- get the address of the web service server ------------------------------------------
        let serverAddress = userStore.getServerAddress;

        // --- construct the url ------------------------------------------------------------------
        //url à redéfinir
        let url = serverAddress + '/artizen/ws/entites/lire?entite=' + entite.entite;

        // --- retrieve the token from auth store
        let token = authStore.getToken;

        // call the ws and wait for his answer
        const response = await fetch(url,
            {
                method: 'GET',
                headers: {
                    Authorization: 'JWT ' + token
                }
            });

        // if we had an error calling the ws
        if (!response.ok) {
            if(response.status==511){
                authStore.tryLogin();
            } else {
                const error = new Error('Failed to get user info. Check your login data.');
                throw error;
            }
        }

        // retrieve the reponse from the ws
        let entiteResponse = await response.text();

        console.log('********** subscriberResponse **********');
        console.log(entiteResponse);

        // parse the response as a json message
        const jsonEntiteResponse = JSON.parse(entiteResponse);
        console.log(jsonEntiteResponse);
        // we store the infos in the vueX calling the setUser mutation
        this.commitSetEntite({
            codeEntite        : jsonEntiteResponse.codeEntite,
            designationEntite : jsonEntiteResponse.designationEntite,
            nbrMaxCol         : jsonEntiteResponse.nbrMaxCol,
            nbrMinCol         : jsonEntiteResponse.nbrMinCol,
            titreFiche        : jsonEntiteResponse.titreFiche,
            titreListe        : jsonEntiteResponse.titreListe,
            topEntiteSysteme  : jsonEntiteResponse.topEntiteSysteme,
        });
        console.log('action getSubscriber - fin');

    },
    async deleteElement(payload) {
        const userStore = useUserStore();
        const authStore = useAuthStore();
        console.log('action entites.deleteEntites - begin');
        console.log(payload);


        // --- get the address of the web service server ------------------------------------------
        let serverAddress = userStore.getServerAddress;

        // --- construct the url ------------------------------------------------------------------
        // à redéfinir
        let url = serverAddress + '/artizen/ws/entites/supprimer?entite='+ payload.entite;

        // retrieve the token from auth store
        let token = authStore.getToken;

        // call the ws and wait for his answer
        const response = await fetch(url,
          {
              method: 'PUT',
              headers: {
                  Authorization: 'JWT ' + token
              },
          });

        // if we had an error calling the ws
        if (!response.ok) {
            if(response.status==511){
                authStore.tryLogin();
            } else {
                const error = new Error('Failed to get user info. Check your login data.');
                throw error;
            }
        }

        // retrieve the reponse from the ws
        let entiteResponse = await response.text();

        console.log('********** entiteResponse **********');
        console.log(entiteResponse);

        console.log('action entites.deleteEntites - end');

    },

    setDataset(dataset){
        this.dataset = dataset;
    },

    commitSetEntite(entite){
        this.entite = entite;
    },

    async createEntite(entite){
        const userStore = useUserStore();
        const authStore = useAuthStore();
        console.log('action entites.createEntite - start');
        console.log(entite);
        // --- get the address of the web service server ------------------------------------------
        let serverAddress = userStore.getServerAddress;

        // --- construct the url ------------------------------------------------------------------
        let url = serverAddress + '/artizen/ws/entites/creer';

        // --- retrieve the token from auth store -------------------------------------------------
        let token = authStore.getToken;

        // --- call the ws and wait for his answer ------------------------------------------------
        const response = await fetch(url,
          {
              method: 'PUT',
              headers: {
                  "Authorization": 'JWT ' + token,
                  "accept":        "application/json",
                  "Content-Type":  "application/json",
              },
              body: JSON.stringify({
                  codeEntite        : entite.codeEntite,
                  designationEntite : entite.designationEntite,
                  nbrMaxCol         : entite.nbrMaxCol,
                  nbrMinCol         : entite.nbrMinCol,
                  titreFiche        : entite.titreFiche,
                  titreListe        : entite.titreListe,
                  topEntiteSysteme  : entite.topEntiteSysteme,
              })
          });

        if (!response.ok) {

            // --- if we had an error while calling the web service -------------------------------
            console.log("createUser, response is not ok");
            console.log(response);

            if (response.status === 404) {
                let errorResponse = await response.text();

                console.log("errorResponse=");
                console.log(errorResponse);

                let jsonErrorResponse = JSON.parse(errorResponse);

                console.log("jsonErrorResponse=");
                console.log(jsonErrorResponse);

                this.setEntiteError(jsonErrorResponse);
                this.setEntiteUpdateReturnCode(404);
            } else if(response.status == 511){
                this.setEntiteError(null);
                this.setEntiteUpdateReturnCode(511);
                throw 511;
            } else {
                this.setEntiteError(null);
                this.setEntiteUpdateReturnCode(999)
                const error = new Error('Failed to set user info. Check your login data.');
                throw error;
            }
        }
        else {
            // result is ok for the user creation
            this.setEntiteError(null);
            this.setEntiteUpdateReturnCode(200)
        }

        console.log('action entites.createEntite - end');
    },

    setEntiteError(error){
        this.entiteError = error
    },

    setEntiteUpdateReturnCode(code){
        this.entiteUpdateReturnCode = code;
    },

    async setEntite(entite) {

        const userStore = useUserStore();
        const authStore = useAuthStore();
        console.log('action entites.setEntite - start');


        // --- get the address of the web service server ------------------------------------------
        let serverAddress = userStore.getServerAddress;

        // --- construct the url ------------------------------------------------------------------
        let url = serverAddress + '/artizen/ws/entites/modifier';

        // retrieve the token from auth store
        let token = authStore.getToken;

        console.log(entite)

        // call the ws and wait for his answer
        const response = await fetch(url,
            {
                method: 'PUT',
                headers: {
                    "Authorization": 'JWT ' + token,
                    "accept":        "application/json",
                    "Content-Type":  "application/json",
                },
                body: JSON.stringify({
                    codeEntite        : entite.codeEntite,
                    designationEntite : entite.designationEntite,
                    nbrMaxCol         : entite.nbrMaxCol,
                    nbrMinCol         : entite.nbrMinCol,
                    titreFiche        : entite.titreFiche,
                    titreListe        : entite.titreListe,
                    topEntiteSysteme  : entite.topEntiteSysteme,
                })
            });

        if (!response.ok) {

            // if we had an error while calling the web service
            console.log("setEntite, response is not ok");
            console.log(response);

            if (response.status === 404) {
                let errorResponse = await response.text();

                console.log("errorResponse=");
                console.log(errorResponse);

                let jsonErrorResponse = JSON.parse(errorResponse);

                console.log("jsonErrorResponse=");
                console.log(jsonErrorResponse);

                this.setEntiteError(jsonErrorResponse);
                this.setEntiteUpdateReturnCode(404)
            } else if(response.status == 511){
                this.setEntiteError(null);
                this.setEntiteUpdateReturnCode(511);
                throw 511;
            } else {
                this.setEntiteError(null);
                this.setEntiteUpdateReturnCode(999)
                const error = new Error('Failed to set user info. Check your login data.');
                throw error;
            }
        }
        else {
            // result is ok for the user update
            this.setEntiteError(null);
            this.setEntiteUpdateReturnCode(200)

            // we store the infos in the vueX calling the setUser mutation
            this.commitSetEntite({
                codeEntite        : entite.codeEntite,
                designationEntite : entite.designationEntite,
                nbrMaxCol         : entite.nbrMaxCol,
                nbrMinCol         : entite.nbrMinCol,
                titreFiche        : entite.titreFiche,
                titreListe        : entite.titreListe,
                topEntiteSysteme  : entite.topEntiteSysteme,
            });
        }

        console.log('action users.setUser - end');
    },

    async updateEntite(payload) {

        console.log('action entites.updateEntite - start');

        // we update the infos in the vueX calling the updateUser mutation
        this.commitUpdateEntite(payload);

        console.log('action entites.updateEntite - end');
    },

    commitUpdateEntite(payload){
        console.log('mutation entite.updateEntite - begin');

        console.log(">>> entites.updateEntites - payload");
        console.log(payload);
        console.log(">>> dataset before changes <<<")
        console.log(this.dataset.rows);

        let entite =  payload.codeEntite;


        this.dataset.rows.forEach((element, index) => {
            if ((this.dataset.rows[index].entite === entite)) {
                this.dataset.rows[index].designationEntite = payload.designationEntite;
                this.dataset.rows[index].nbrMaxCol         = payload.nbrMaxCol;
                this.dataset.rows[index].nbrMinCol         = payload.nbrMinCol;
                this.dataset.rows[index].titreFiche        = payload.titreFiche;
                this.dataset.rows[index].titreListe        = payload.titreListe;
                this.dataset.rows[index].topEntiteSysteme  = payload.topEntiteSysteme;
            }
        });

        console.log(">>> entites.updateEntites - dataset");
        console.log(this.dataset.rows);

        console.log('mutation entites.updateEntite - end');
    },

};