import { useAuthStore} from "@/pinia/auth/auth";
import { useUserStore} from "@/pinia/user/user";

export default {
    async retrieveData(abonne=null, numeromodele=0){
        const userStore = useUserStore();
        const authStore = useAuthStore();
        const token     = authStore.getToken;
        let url         = userStore.getServerAddress + '/artizen/ws/modeles/listezonesmodele';
        url            += abonne!=null ? '?abonne='+abonne : '?abonne='+userStore.getSubscriber;
        url            += '&numeromodele='+numeromodele;
        const response  = await fetch(url, {
            method: 'GET',
            headers: {
                Authorization : 'JWT ' + token
            }
        })
        if(!response.ok && response.status!==400){
            console.log('Error while fetching datas');
        }
        let responseToText = await response.text();
        let parsedResponse = JSON.parse(responseToText);
        console.log(parsedResponse);
        this.setModeleAffiche(parsedResponse);
        await this.setEntites();
        await this.setModeles();
    },

    setModeleAffiche(modele){
        let formatedModel = JSON.parse(JSON.stringify(modele));
        console.log(modele.listeDesZones);
        for(const row of formatedModel.listeDesZones){
            row.gerableParAbonne        = row.gerableParAbonne        == 'O' ? true : false;
            row.saisissable             = row.saisissable             == 'O' ? true : false;
            row.obligatoireEnSaisie     = row.obligatoireEnSaisie     == 'O' ? true : false;
            row.valeurGeneree           = row.valeurGeneree           == 'O' ? true : false;
            row.critereGerableParAbonne = row.critereGerableParAbonne == 'O' ? true : false;
            row.critereDeSelection      = row.critereDeSelection      == 'O' ? true : false;
            row.blocGerableParAbonne    = row.blocGerableParAbonne    == 'O' ? true : false;
            row.blocActifEtPresent      = row.blocActifEtPresent      == 'O' ? true : false;
            row.zoneVirtuelleBloc       = row.zoneVirtuelleBloc       == 'O' ? true : false;
            row.zoneVisibleListe        = row.zoneVisibleListe        == 'O' ? true : false;
            row.topGerableListe         = row.topGerableListe         == 'O' ? true : false;
            row.error                   = false;
            row.errorText               = "";
        }
        console.log(formatedModel.listeDesZones);
        this.modeleAffiche = formatedModel;
    },

    async setEntites(entite=null){
        const userStore  = useUserStore();
        const authStore  = useAuthStore();
        const token      = authStore.getToken;
        let   url        = userStore.getServerAddress + '/artizen/ws/combo/entites';
        url             += entite!=null ? 'valeur=' + entite : '';
        const response   = await fetch(url, {
            method: 'GET',
            headers: {
                Authorization: 'JWT ' + token
            }
        })
        if(!response.ok){
            console.log(response.status);
        }
        let resToText  = await response.text();
        console.log(resToText);
        let   fetchedRes = JSON.parse(resToText)["listeDesElements"];
        let listeEntites =  [{text:'Toutes les entités', value:'TOUTES', selected: false}];
        for(const entite of fetchedRes){
            listeEntites.push({
                text: entite.nom,
                value: entite.code,
                selected: false
            })
        }
        this.entites = listeEntites;
    },

    async setModeles(modele=null){
        const userStore  = useUserStore();
        const authStore  = useAuthStore();
        const token      = authStore.getToken;
        let   url        = userStore.getServerAddress + '/artizen/ws/combo/modeles';
        url             += modele!=null ? 'valeur=' + modele : '';
        console.log(url);
        const response   = await fetch(url, {
            method: 'GET',
            headers: {
                Authorization: 'JWT ' + token
            }
        })
        if(!response.ok){
            console.log(response.status);
        }
        let resToText  = await response.text();
        console.log(resToText);
        let   fetchedRes = JSON.parse(resToText)["listeDesElements"];
        let listeModeles =  [];
        for(const modele of fetchedRes){
            listeModeles.push({
                text: modele.nom,
                value: modele.code,
                selected: modele.nom == this.modeleAffiche.designation ? true : false
            })
        }
        this.modeles = listeModeles;
    },

    async updateModele(modele){
        console.log('toto')
       const userStore = useUserStore();
       const authStore = useAuthStore();
       const token     = authStore.getToken;
       let url         = userStore.getServerAddress + '/artizen/ws/modeles/modifier?numeromodele='+modele['numeroModele'];
       let body        = modele;
       console.log(body)
       for(const row of body['listeDesZones']){
           row.gerableParAbonne        = row.gerableParAbonne        ? 'O' : 'N';
           row.saisissable             = row.saisissable             ? 'O' : 'N';
           row.obligatoireEnSaisie     = row.obligatoireEnSaisie     ? 'O' : 'N';
           row.valeurGeneree           = row.valeurGeneree           ? 'O' : 'N';
           row.critereGerableParAbonne = row.critereGerableParAbonne ? 'O' : 'N';
           row.critereDeSelection      = row.critereDeSelection      ? 'O' : 'N';
           row.blocGerableParAbonne    = row.blocGerableParAbonne    ? 'O' : 'N';
           row.blocActifEtPresent      = row.blocActifEtPresent      ? 'O' : 'N';
           row.zoneVisibleListe        = row.zoneVisibleListe        ? 'O' : 'N';
           row.zoneVirtuelleBloc       = row.zoneVirtuelleBloc       ? 'O' : 'N';
           row.topGerableListe         = row.topGerableListe         ? 'O' : 'N';
           delete row.error;
           delete row.errorText;
       }
       console.log(body);
       const response = await fetch(url, {
           method: 'PUT',
           headers: {
               "Authorization": 'JWT ' + token,
               "accept":        "application/json",
               "Content-Type":  "application/json"
           },
           body: JSON.stringify(body)
       });
       this.errorsList = {};
       if(!response.ok){
           console.log(response.status);
           let responseText           = await response.text();
           let alertesTrieesParEntite = {};
           let listeAlertes           = JSON.parse(responseText)['listeDesAlertes'];
           for(const alerte of listeAlertes){
               if(!alertesTrieesParEntite[alerte['codeEntite']]){
                   alertesTrieesParEntite[alerte['codeEntite']] = [alerte];
               } else {
                   alertesTrieesParEntite[alerte['codeEntite']].push(alerte)
               }
           }
           console.log(alertesTrieesParEntite);
           this.errorsList = alertesTrieesParEntite;
       }
       console.log(response);
    }
}