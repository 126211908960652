<template>
  <MDBToast :autohide="autohide"
            :delay = 2000
            position="bottom-right"
            appendToBody
            stacking
            :color="color"
            text="white">
    <template #title>
      Hexartizen
    </template>
    {{ text }}
  </MDBToast>
</template>

<script>
import { MDBToast } from 'mdb-vue-ui-kit'
export default {
  components: {
    MDBToast
  },
  props: {
    text: {
      required: true,
    },
    color: {
      type: String,
      required: false,
      default: 'success'
    },
    autohide: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  setup() {
    
  },
}
</script>