import { defineStore } from 'pinia';

import getters from './getters';
import actions from './actions';

export const useClientsStore = defineStore('clients', {
    state: () => ({
        dataset:
            {
                columns : [],
                rows    : []
            }
    }),
    getters,
    actions
})
