import { useUserStore } from "../user/user";
import { useAuthStore } from "../auth/auth";
export default {
    // --- Retrieves the list of all communes --------------------------------------------------- retrieveCommunes
    async retrieveData(payload=null){
        const userStore = useUserStore();
        const authStore = useAuthStore();
        let serverAddress = userStore.getServerAddress;

        let url = serverAddress + "/artizen/ws/communes/listedatatable";

        if(payload!=null && Object.keys(payload).length > 0){ // -- we first verify if our payload is not null and if it's not empty
            url+="?";
            let index = 0;
            // -- we now iterate over the payload containing the search filters the user chose
            for(const data in payload){
                url+=data+"="+payload[data]; // -- data corresponds to the name of the attribute (user for instance)
                                            // -- and payload[data] gets the value in the payload linked to the property name
                if(index < Object.keys(payload).length-1){ // -- in the end we check if we reached the end of the payload or not
                    url+="&";                              // -- and if it's not, we add & to continue adding query elements
                }
            }
        }
        console.log(url);
        let token = authStore.getToken;
        const response = await fetch(url,
            {
                method: 'GET',
                headers: {
                    Authorization: 'JWT ' + token
                }
            });

        if (!response.ok){
            if(response.status==511){
                authStore.tryLogin();
            } else {
                const error = new Error('Failed to get user info. Check your login data.');
                throw error;
            }
        }

        let communesResponse       = await response.text();
        const jsonCommunesResponse = JSON.parse(communesResponse);
        let payloadCommunes        = jsonCommunesResponse.dataset;

        try {
            let jsonPayloadCommunes = JSON.parse(payloadCommunes);
            this.setDataset(jsonPayloadCommunes);
        }
        catch(e){
            console.log('Error parsing payloadCommunes=' + e.toString());
        }
        finally{
            console.log('action retrieveCommunes - end');
        }
    },

    // --- Setter for the dataset variable ------------------------------------------------------ setDataset
    setDataset(payload){
        this.dataset = payload;
    }
}