<template>
  <art-header />
  <router-view></router-view>
</template>

<script>
import HelloWorld from './components/HelloWorld.vue'
import ArtHeader from "@/components/header/ArtHeader";
import {useAuthStore} from '@/pinia/auth/auth.js';
import {useApplicationStore} from '@/pinia/application/application.js';
import { useRouter } from 'vue-router';
import { onMounted } from 'vue';

export default {
  name: 'App',
  components: {
    HelloWorld,
    ArtHeader
  },
  setup(){

    const authStore        = useAuthStore();
    const applicationStore = useApplicationStore();
    const router    = useRouter();
    applicationStore.setNbLignesDatatable(window.innerWidth);
    applicationStore.setIsSmallScreen(window.innerWidth);
    
    authStore.$subscribe((mutation, state) => {
      if(state.didAutoLogout){
        router.push('/auth');
      }
    })
    
  }
}
</script>

<style>
#app {
  font-family: Roboto, Helvetica, Arial, sans-serif;
}
i.elementDetails:hover, i.elementDelete:hover, i.elementUsers:hover, i.elementZones:hover{
  cursor: pointer;
}
i.elementDelete,i.elementUsers, i.elementZones{
  margin-left: 0.5rem;
}
.scrollable{
  position: sticky;
  top:4.5%;
  z-index: 100;
}

.tooltip{
  width: max-content;
}
/* #draggable{
  cursor:move;
} */
</style>
