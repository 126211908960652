import { useAuthStore }        from "../auth/auth";
import { useUserStore }        from "../user/user";
import { useApplicationStore } from "../application/application";

export default {

  // --- Retrieve menu's list to display in the dataset ----------------------------------------- retrieveMenus
  async retrieveData(payload=null) {
    const applicationStore = useApplicationStore();
    const userStore        = useUserStore();
    const authStore        = useAuthStore();

    console.log('menus - actions retrieveMenus - begin');

    let serverAddress = userStore.getServerAddress;

    console.log('menus - action retrieveMenus - serverAddress='+serverAddress);

    let url = serverAddress + "/artizen/ws/menu/listedatatable";

    if(payload!=null && Object.keys(payload).length > 0){ // -- we first verify if our payload is not null and if it's not empty
      url+="?";
      let index = 0;

      // -- we now iterate over the payload containing the search filters the user chose
      for(const data in payload){
          url+=data+"="+payload[data]; // -- data corresponds to the name of the attribute (user for instance)
                                       // -- and payload[data] gets the value in the payload linked to the property name

          if(index < Object.keys(payload).length-1){ // -- in the end we check if we reached the end of the payload or not
              url+="&";                              // -- and if it's not, we add & to continue adding query elements
          }
      }
    }
    console.log("menus - action retrieveMenus - url="+url);

    let token = authStore.getToken;

    const response = await fetch(url,
            {
                method: 'GET',
                headers: {
                    Authorization: 'JWT ' + token
                }
            });

    if (!response.ok) {
        if(response.status==511){
                authStore.tryLogin();
            } else {
                const error = new Error('Failed to get user info. Check your login data.');
                throw error;
            }
    }

    let menuResponse = await response.text();

    console.log('********** menusResponse **********');
    console.log("menus - action retrieveMenus - menuResponse="+menuResponse);
    console.log("menus - action retrieveMenus - menuResponse.type="+typeof menuResponse);
    
    // --- parse the response as a json message -----------------------------------------------
    const jsonMenuResponse = JSON.parse(menuResponse);
    console.log(jsonMenuResponse.dataset);
    let payloadMenus = jsonMenuResponse.dataset;
    applicationStore.commitSetMessage(jsonMenuResponse.message);
    
    console.log("menus - action retrieveMenus - payloadMenus="+payloadMenus);
    console.log("menus - action retrieveMenus - payloadMenus.type="+typeof payloadMenus);
    
    try {
        let jsonPayloadMenus = JSON.parse(payloadMenus);
        if(jsonPayloadMenus.rows.length>0){
            console.log(jsonPayloadMenus.rows[0].code);
        } else {
            console.log('Recherche introuvable');
        }
        

        console.log("menus - action retrieveMenus - jsonPayloadMenus     ="+JSON.stringify(jsonPayloadMenus));
        console.log("menus - action retrieveMenus - jsonPayloadMenus.type="+typeof jsonPayloadMenus);
        // --- set the dataset value in the context -----------------------------------------------
        this.setDataset(jsonPayloadMenus);

        console.log("menus - action retrieveMenus - after commit setDataSet=");

    }
    catch (e) {
        console.log("error parsing payloadMenus=" + e.toString())
    }
    finally {
        console.log('action retrieveMenus - end');
    }
  },

  // --- Setter for the dataset variable -------------------------------------------------------- setDataset
  setDataset(payload) {
    console.log('mutation users.setDataset - begin');
    console.log(payload);
    this.dataset = payload;
    console.log('mutation users.setDataset='+JSON.stringify(this.dataset));
    console.log('mutation users.setDataset - end');
  },

  // --- Retrieve the informations of the menu the user clicked on ------------------------------ retrieveMenu
  async retrieveMenu(payload) {
    const userStore = useUserStore();
    const authStore = useAuthStore();

    let serverAddress = userStore.getServerAddress;

    let url = serverAddress + '/artizen/ws/menu/lire?'+ 'menu=' + payload.menu + '&bloc=' + payload.bloc;
    console.log(url);
    let token = authStore.getToken;

    const response = await fetch(url,
        {
            method: 'GET',
            headers: {
                Authorization: 'JWT ' + token
            }
        });

    if (!response.ok) {
        if(response.status==511){
                authStore.tryLogin();
            } else {
                const error = new Error('Failed to get user info. Check your login data.');
                throw error;
            }
    }

    let menuResponse = await response.text();

    console.log('********** menuResponse **********');
    console.log(menuResponse);

    const jsonMenuResponse = JSON.parse(menuResponse);
    console.log(jsonMenuResponse);
    this.commitSetMenu(jsonMenuResponse);
    console.log('action getUser - fin');

    },

    // --- Setter for the menu variable ------------------------------------------------------------- commitSetMenu
    commitSetMenu(menu){
        console.log('PIJFOIJDJPDPOJFPOJDPO');
        this.menu = menu;
    },

    // --- Marks a menu as deleted in the database -------------------------------------------------- deleteMenu
    async deleteElement(payload) {
        const userStore = useUserStore();
        const authStore = useAuthStore();
        console.log('action menus.deleteMenu - begin');
        console.log(payload);
    
    
        let serverAddress = userStore.getServerAddress;
        // -- We set the menu value depending on the payload to build the query string more easily
        let menu = !!payload.menu     ? "menu="  + payload.menu + '&' : "";
    
        // -- We set the bloc value depending on the payload to build the query string more easily
        let bloc = !!payload.bloccode ? "bloc=" + payload.bloccode   : '';
    
        let url = serverAddress + '/artizen/ws/menu/supprimer?'+ menu + bloc;
    
        let token = authStore.getToken;
    
        const response = await fetch(url,
        {
            method: 'PUT',
            headers: {
                Authorization: 'JWT ' + token
            },
        });
    
        if (!response.ok) {
            if(response.status==511){
                authStore.tryLogin();
            } else {
                const error = new Error('Failed to get user info. Check your login data.');
                throw error;
            }
        }
    
        let menuResponse = await response.text();
    
        console.log('********** menuResponse **********');
        console.log(menuResponse);
    
        console.log('action menus.deleteMenu - end');
    
    },

    // --- Send to the web service a new menu to create in the database ------------------------- createMenu
    async createMenu(payload){
        const userStore = useUserStore();
        const authStore = useAuthStore();
        console.log('action menus.createMenu - start');
        console.log(payload);
        let serverAddress = userStore.getServerAddress;

        let url = serverAddress + '/artizen/ws/menu/creer';

        let token = authStore.getToken;

        console.log(payload);
        const response = await fetch(url,
          {
              method: 'PUT',
              headers: {
                  "Authorization": 'JWT ' + token,
                  "accept":        "application/json",
                  "Content-Type":  "application/json",
              },
              body: JSON.stringify(payload)
          });

        if (!response.ok) {

            console.log("createMenu, response is not ok");
            console.log(response);

            if (response.status === 404) {
                let errorResponse = await response.text();

                console.log("errorResponse=");
                console.log(errorResponse);

                let jsonErrorResponse = JSON.parse(errorResponse);

                console.log("jsonErrorResponse=");
                console.log(jsonErrorResponse);

                this.setMenuErrors(jsonErrorResponse);
                this.setMenuUpdateReturnCode(404);
            } else if(response.status == 511){
               this.setParametreErrors(null);
               this.setParametreUpdateReturnCode(511);
               throw 511;
            } else {
                this.setParametreErrors(null);
                this.setParametreUpdateReturnCode(999);
                const error = new Error('Failed to set user info. Check your login data.');
                throw error;
            }
        }
        else {
            this.setMenuErrors(null);
            this.setMenuUpdateReturnCode(200);
        }

        console.log('action menus.createMenu - end');
    },

    // --- Setter for the menuErrors variable --------------------------------------------------- setMenuErrors
    setMenuErrors(payload) {
        console.log('mutation menus.setMenuErrors - begin');
        this.menuErrors = payload;

        console.log("state.userErrors=");
        console.log(this.menuErrors);

        console.log('mutation menus.setMenuErrors - end');
    },

    // --- Setter for the menuUpdateReturnCode variable ----------------------------------------- setMenuUpdateReturnCode
    setMenuUpdateReturnCode(payload) {
        console.log('mutation menus.setMenuUpdateReturnCode - begin');
        this.menuUpdateReturnCode = payload;

        console.log("state.menuUpdateReturnCode=");
        console.log(this.menuUpdateReturnCode);

        console.log('mutation menus.setMenuUpdateReturnCode - end');
    },

    // --- Updates an already existing menu in the database ------------------------------------- setMenu
    async setMenu(payload){
        const userStore = useUserStore();
        const authStore = useAuthStore();
        console.log('action menus.setMenu - start');


        let serverAddress = userStore.getServerAddress;

        let url = serverAddress + '/artizen/ws/menu/modifier';

        let token = authStore.getToken;
        console.log(payload);

        const response = await fetch(url,
            {
                method: 'PUT',
                headers: {
                    "Authorization": 'JWT ' + token,
                    "accept":        "application/json",
                    "Content-Type":  "application/json",
                },
                body: JSON.stringify(payload)
            });

        if (!response.ok) {

            console.log("setMenu, response is not ok");
            console.log(response);

            if (response.status === 404) {
                let errorResponse = await response.text();

                console.log("errorResponse=");
                console.log(errorResponse);

                let jsonErrorResponse = JSON.parse(errorResponse);

                console.log("jsonErrorResponse=");
                console.log(jsonErrorResponse);

                this.setMenuErrors(jsonErrorResponse);
                this.setMenuUpdateReturnCode(404)
            } else if(response.status == 511){
               this.setParametreErrors(null);
               this.setParametreUpdateReturnCode(511);
               throw 511;
            } else {
                this.setParametreErrors(null);
                this.setParametreUpdateReturnCode(999);
                const error = new Error('Failed to set user info. Check your login data.');
                throw error;
            }
        }
        else {
            this.setMenuErrors(null);
            this.setMenuUpdateReturnCode(200)

            this.commitSetMenu(payload);
        }

        console.log('action menus.setMenu - end');
    },

    // --- Updates the menu's dataset in menusStore --------------------------------------------- updateMenu
    async updateMenu(payload) {
        console.log('mutation menus.updateMenu - begin');

        console.log(">>> menus.updateMenu - payload");
        console.log(payload);
        console.log(">>> dataset before changes <<<")
        console.log(this.dataset.rows);

        let localBloc =  payload.bloccode;


        this.dataset.rows.forEach((element, index) => {
            if ((this.dataset.rows[index].bloccode === localBloc)) {
                this.dataset.rows[index].menu           = payload.menu,
                this.dataset.rows[index].bloccode       = payload.bloccode,
                this.dataset.rows[index].blocnum        = payload.blocnum,
                this.dataset.rows[index].roles          = payload.roles,
                this.dataset.rows[index].texteentete    = payload.texteentete,
                this.dataset.rows[index].texte          = payload.texte,
                this.dataset.rows[index].textepied      = payload.textepied,
                this.dataset.rows[index].typebloc       = payload.typebloc,
                this.dataset.rows[index].menuparent     = payload.menuparent,
                this.dataset.rows[index].chemin         = payload.chemin,
                this.dataset.rows[index].arborescence   = payload.arborescence,
                this.dataset.rows[index].couleurbordure = payload.couleurbordure,
                this.dataset.rows[index].couleurfond    = payload.couleurfond,
                this.dataset.rows[index].couleurtexte   = payload.couleurtexte
            }
        });

        console.log(">>> tables.updateTable - dataset");
        console.log(this.dataset.rows);

        console.log('mutation tables.updateTable - end');
    },


}