import { NamedTimeZoneImpl } from '@fullcalendar/core';
import {useAuthStore} from '../auth/auth.js';
import { useUserStore } from '../user/user';

export default {
  
  // --- Retrieve the list of events from the web service --------------------------------------- retrieveEvents
  async retrieveEvents(datedebut=null, datefin=null){
    const authStore     = useAuthStore();
    const userStore     = useUserStore();
    const token         = authStore.getToken;
    const serverAddress = userStore.getServerAddress;
    const user          = 'utilisateur='+ userStore.getUser;
    const subscriber    ='abonne='+ userStore.getSubscriber + '&';
    const startValue    = !!datedebut ? 'datedebut=' + datedebut + '&' : ''
    const endValue      = !!datefin   ? 'datefin='   + datefin   + '&' : ''
    const url           = serverAddress+'/artizen/ws/agenda/liste?' + startValue + endValue + subscriber + user; 
    console.log(url);
    const response = await fetch(url, 
      {
        method: 'GET',
        headers: {
          Authorization: 'JWT ' + token
        }
      }
    );

    // -- We throw an error in case the web service returns one
    if(!response.ok){
      if(response.status == 511){
        authStore.tryLogin();
      } else {
        console.log(response);
        const error = new Error("Erreur lors de la récupération des évènements");
        throw error;
      }

    }

    let eventsResponse = await response.text();
    console.log('******* EVENTS RESPONSE *******');
    console.log(eventsResponse);

    const jsonEventsResponse = JSON.parse(eventsResponse).listeDesEvenements;
    console.log('JSON EVENTS RESPONSE');
    console.log(jsonEventsResponse);
    let eventsToShow = [];

    // -- We loop over the list of events returned by the web service to generate
    // -- a list of events in the correct format
    for(const event in jsonEventsResponse){
      let currentEvent = jsonEventsResponse[event];
      let usersFormated = [];
      let privateEvent  = currentEvent.prive=='O' ? true : false;

      // -- We have to loop on the users list to format it in a way that the MDBSelect component
      // -- will be able to understand
      for(const user of currentEvent.listeDesUtilisateurs){
        usersFormated.push({
          text     : user.nom + ' ' + user.prenom + ' | ' + user.utilisateur,
          value    : user,
          selected : true
        })
      }
      console.log(usersFormated);
      eventsToShow.push({
        title                : currentEvent.title,
        abonne               : currentEvent.abonne,
        utilisateur          : currentEvent.utilisateur,
        start                : currentEvent.start,
        end                  : currentEvent.end,
        id                   : currentEvent.id,
        allDay               : currentEvent.allDay,
        listeDesUtilisateurs : usersFormated,
        eventIsEdited        : false,
        type                 : currentEvent.type,
        prospectNom          : currentEvent.prospectNom,
        prospectPrenom       : currentEvent.prospectPrenom,
        prospectAdr          : currentEvent.prospectAdr,
        prospectTel          : currentEvent.prospectTel,
        clientDesignation    : currentEvent.clientDesignation,
        commentaire          : currentEvent.commentaire,
        prive                : privateEvent
      })
    }
    this.setEvents(eventsToShow);
  },

  // --- Send a request to the web service to create an event based on the payload -------------- createEvent
  async createEvent(event){
    const authStore          = useAuthStore();
    const userStore          = useUserStore();
    const token              = authStore.getToken;
    const serverAddress      = userStore.getServerAddress;
    const url                = serverAddress + '/artizen/ws/agenda/creer';
    let eventTypeDescription = this.typesOfEvents.filter(type => type.value==event.type)[0].text;
    let usersSelected        = event.listeDesUtilisateurs.filter(user => user.selected);
    let usersList            = [];
    let privateEvent         = event.prive ? 'O' : 'N';
    let client               = this.retrieveClient(event.clientDesignation);
    // -- We format the users list so it follows the web service's users list format
    for(const user of usersSelected){
      usersList.push(user.value);
    }
    let formatedevent = {
      title                : event.title,
      start                : event.start,
      end                  : event.end,
      abonne               : userStore.getSubscriber,
      utilisateur          : userStore.getUser,
      nom                  : userStore.getLastname,
      prenom               : userStore.getFirstname,
      allDay               : event.allDay,
      type                 : event.type,
      typeDesignation      : eventTypeDescription,
      prospectNom          : event.prospectNom,
      prospectPrenom       : event.prospectPrenom,
      prospectAdr          : event.prospectAdr,
      prospectTel          : event.prospectTel,
      client               : !!client ? client.client : 0,
      clientDesignation    : !!client ? client.nom + ' | ' + client.abonne : '',
      listeDesUtilisateurs : usersList,
      prive                : privateEvent,
      commentaire          : event.commentaire
    }
    console.log(JSON.stringify(formatedevent));

    const response = await fetch(url, 
      {
        method: 'PUT',
        headers: {
          "Authorization": 'JWT ' + token,
          "accept":        "application/json",
          "Content-Type":  "application/json",
        },
        body: JSON.stringify(formatedevent)
      }
    );
    console.log(response.status);
    if(!response.ok){
      if(response.status == 404){
        let responseCreate = await response.text();
        let jsonRes        = JSON.parse(responseCreate);
        let errorResponse = jsonRes.listeDesAlertes.filter(value => value.typeAlerte != 'K');
        console.log(errorResponse);
        this.setAgendaErrors(errorResponse);
        this.setAgendaUpdateReturnCode(404);
      } else if(response.status == 511){
        authStore.tryLogin();
      }else {
        this.setAgendaErrors(null);
        this.setAgendaUpdateReturnCode(999);
      }
    } else {
      let responseCreate = await response.text();
      let jsonRes        = JSON.parse(responseCreate);
      this.setAgendaErrors(null);
      this.setAgendaUpdateReturnCode(200);
      let newEventId = jsonRes.listeDesAlertes.filter(value => value.typeAlerte == 'K')[0].messageAlerte;
      event.id   = newEventId;
      this.addEvent(event);
    }
    
      },

  // --- Sends a request to the agenda/modifier web service to update an event ------------------ modifyEvent
  async modifyEvent(event){
    const authStore          = useAuthStore();
    const userStore          = useUserStore();
    const token              = authStore.getToken;
    const serverAddress      = userStore.getServerAddress;
    const url                = serverAddress + '/artizen/ws/agenda/modifier';
    let privateEvent         = event.prive ? 'O' : 'N'
    let eventTypeDescription = this.typesOfEvents.filter(type => type.value==event.type)[0].text;
    let usersSelected        = event.listeDesUtilisateurs.filter(user => user.selected);
    let usersList = [];
    let client = this.retrieveClient(event.clientDesignation);

    // -- We format the users list so it follows the web service's users list format
    for(const user of usersSelected){
      usersList.push(user.value);
    }
    let formatedevent = {
      id                   : event.id,
      title                : event.title,
      start                : event.start,
      end                  : event.end,
      abonne               : userStore.getSubscriber,
      utilisateur          : userStore.getUser,
      nom                  : userStore.getLastname,
      prenom               : userStore.getFirstname,
      allDay               : event.allDay,
      type                 : event.type,
      typeDesignation      : eventTypeDescription,
      prospectNom          : event.prospectNom,
      prospectPrenom       : event.prospectPrenom,
      prospectAdr          : event.prospectAdr,
      prospectTel          : event.prospectTel,
      client               : !!client ? client.client : 0,
      clientDesignation    : !!client ? client.nom + ' | ' + client.abonne : '',
      listeDesUtilisateurs : usersList,
      prive                : privateEvent,
      commentaire          : event.commentaire
    }
    console.log(JSON.stringify(formatedevent));

    const response = await fetch(url, 
      {
        method: 'PUT',
        headers: {
          "Authorization": 'JWT ' + token,
          "accept":        "application/json",
          "Content-Type":  "application/json",
        },
        body: JSON.stringify(formatedevent)
      }
    );
    if(!response.ok){
      if(response.status == 404){
        let errorResponse = jsonRes.listeDesAlertes.filter(value => value.typeAlerte != 'K');
        console.log(errorResponse);
        this.setAgendaErrors(errorResponse);
        this.setAgendaUpdateReturnCode(404);
      } else if(response.status == 511){
        authStore.tryLogin();
      } else {
        this.setAgendaErrors(null);
        this.setAgendaUpdateReturnCode(999);
      }
    } else {
      this.setAgendaErrors(null);
      this.setAgendaUpdateReturnCode(200);
      this.updateEvent(event);
    }
  },

  // --- Sends a request to the agenda/supprimer web service to delete an event from db --------- deleteEvent
  async deleteEvent(eventId){
    const authStore     = useAuthStore();
    const userStore     = useUserStore();
    const token         = authStore.getToken;
    const serverAddress = userStore.getServerAddress;
    const url           = serverAddress + '/artizen/ws/agenda/supprimer?agenda=' + eventId;

    const response = await fetch(url, 
      {
        method: 'PUT',
        headers: {
          "Authorization": 'JWT ' + token
        },
      }
    );
    if(!response.ok){
      console.log(response);
    }
    this.removeEvent(eventId);
  },

  // --- Setter for the events variable --------------------------------------------------------- setEvents
  setEvents(events){
    this.events = events;
  },

  // --- Setter for the selectingMode variable -------------------------------------------------- setSelectingMode
  setSelectingMode(bool){
    this.selectingMode = bool;
  },

  // --- Setter for the savedEvent variable ----------------------------------------------------- setSavedEvent
  setSavedEvent(event){
    this.savedEvent = event;
  },

  // --- Setter for the isModalVisible variable ------------------------------------------------- setModalVisible
  setModalVisible(bool){
    this.isModalVisible = bool;
  },

  // --- Adds an event to the events variable --------------------------------------------------- addEvent 
  addEvent(event){
    this.events.push(event);
  },

  // --- Update an event in the events list ----------------------------------------------------- updateEvent
  updateEvent(event){
    this.events = this.events.filter(item => (item.id != event.id));
    this.events.push(event);
  },

  // --- Removes an event from the events list -------------------------------------------------- removeEvent
  removeEvent(eventId){
    this.events = this.events.filter(item => item.id!=eventId);
  },

  retrieveClient(client){
    if(!!client){
      console.log(client);
      let clientSplitted = client.split(' ');
      clientSplitted.splice(clientSplitted.length-2,1);
      const clientAbonne = clientSplitted[clientSplitted.length-1];
      clientSplitted.splice(clientSplitted.length-1, 1);
      const clientName   = clientSplitted.join(' ');
      let clientObj = this.getClientsCombo.find(client => client.abonne == clientAbonne && client.nom == clientName);
      return clientObj;
    }
    return null; 
  },

  // --- Retrieves all the type of events as a list from the web service ------------------------ retrieveTypesOfEvents
  async retrieveTypesOfEvents(){
    const authStore   = useAuthStore();
    const userStore   = useUserStore();
    let serverAddress = userStore.getServerAddress;
    let url = serverAddress + "/artizen/ws/combo/htables?table=AGENDA_EVT_TYPE";
    let token = authStore.getToken;
    const response = await fetch(url, 
      {
        method: 'GET',
        headers: {
          Authorization: 'JWT ' + token
        }
      }
    );
    if(!response.ok){
      if(response.status == 511){
        authStore.tryLogin();
      } else {
        console.log(response);
        const error = new Error("Erreur lors de la récupération des évènements");
        throw error;
      }
    }

    let eventsTypeResponse = await response.text();

    console.log('******* Type of events response *******');
    console.log(eventsTypeResponse);

    const jsonEventsTypeResponse = JSON.parse(eventsTypeResponse).listeDesElements;

    let typesInArray = [];

    for (const index in jsonEventsTypeResponse){
      typesInArray.push({
        text            : jsonEventsTypeResponse[index].valeur,
        value           : jsonEventsTypeResponse[index].code,
        selected        : false
      })
    }

    console.log(typesInArray);
    this.commitSetTypesOfEvents(typesInArray);  
  },

  // --- Setter for the typesOfEvents variable -------------------------------------------------- commitSetTypesOfEvents
  commitSetTypesOfEvents(types){
    this.typesOfEvents = types;
  },

  // --- Set the agenda errors list in case web service returns one ----------------------------- setAgendaErrors
  setAgendaErrors(errors){
    this.agendaErrors = errors;
  },

  // --- Set the agenda update code from the code returned by the web service ------------------- setAgendaUpdateReturnCode
  setAgendaUpdateReturnCode(code){
    this.agendaUpdateReturnCode = code;
  },

  // --- Retrieves a list of users to store in an autocomplete list ----------------------------- retrieveUsersCombo
  async retrieveUsersCombo(payload){
    const authStore = useAuthStore();
    const userStore = useUserStore();
    const token     = authStore.getToken;
    const server    = userStore.getServerAddress + "/artizen/ws/combo/utilisateurs";
    const wsLink    = server + "?valeur=" + payload;
    const response = await fetch(wsLink,
      {
        method: 'GET',
        headers: {
          Authorization: 'JWT ' + token,
        }
      })
    if(!response.ok){
      this.setUsersCombo({});
    } else {
      const resJSON      = await response.json();
      console.log(resJSON.listeDesElements);
      this.setUsersCombo(resJSON.listeDesElements);
    }
  },

  // --- Retrieves a list of clients to store in an autocomplete list --------------------------- retrieveClientsCombo
  async retrieveClientsCombo(payload){
    const authStore = useAuthStore();
    const userStore = useUserStore();
    const token     = authStore.getToken;
    const server    = userStore.getServerAddress + "/artizen/ws/combo/clients";
    const wsLink    = server + "?valeur=" + payload;
    const response = await fetch(wsLink,
      {
        method: 'GET',
        headers: {
          Authorization: 'JWT ' + token,
        }
      })
    if(!response.ok){
      this.setClientsCombo({});
    } else {
      const resJSON      = await response.json();
      console.log(resJSON.listeDesElements);
      this.setClientsCombo(resJSON.listeDesElements);
    }
  },

  // --- Stores the users list passed in parameters to use it in an autoselect list ------------- setUsersCombo
  setUsersCombo(users){
    this.usersCombo = users;
  },

  // --- Stores the clients list passed in parameters to use it in an autoselect list ----------- setClientsCombo
  setClientsCombo(clients){
    this.clientsCombo = clients;
  },

  async retrieveParametresAffichage(){
    const userStore = useUserStore();
    const authStore = useAuthStore();

    let url   = userStore.getServerAddress + '/artizen/ws/agenda/parametres';
    let token = authStore.getToken;

    let response = await fetch(url, {
      method: 'GET',
      headers: {
        Authorization: 'JWT ' + token
      }
    })

    if(!response.ok) {
      if (response.status == 511) {
        authStore.tryLogin();
      } else {
        console.log(response);
        const error = new Error("Erreur lors de la récupération des évènements");
        throw error;
      }
    }

    let eventsParametresResponse = await response.text();

    console.log('******* Paramètres utilisateurs response *******');
    console.log(eventsParametresResponse);

    const jsonEventsParametresResponse = JSON.parse(eventsParametresResponse);

    let parametresAfficage = {
      vue: jsonEventsParametresResponse.vue,
      weekend: jsonEventsParametresResponse.weekend
    }

    this.commitSetParametresAffichage(parametresAfficage);
  },

  commitSetParametresAffichage(parametres){
    this.parametresAffichage = parametres;
  }
}