import { defineStore } from 'pinia';

import getters   from './getters';
import actions   from './actions';

export const useUserStore = defineStore('user',{

    state : () => {
        return {
            subscriber:    null,
            user:          null,
            firstname:     null,
            lastname:      null,
            email:         null,
            roles:         null,
            model:         null,
            serverAddress: "https://wsdev.hexartizen.com",
            // 
            menus:         null,
            favoriteMenus: [],
            subscribedAs: null,
        };
    },

    // set getters from the "getters" object
    getters: getters,

    // same thing for actions
    actions
});