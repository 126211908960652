import { defineStore } from 'pinia';

import getters from './getters';
import actions from './actions';

export const useConnexionsStore = defineStore('connexions', {
    state: () => ({
        dataset:
            {
                columns : ['Actions', 'Abonné', 'Code', 'Nom', 'Prénom', 'Connexion', 'Bloqué', "Jusqu'à", 'IP',
                    'Actif', 'Supprimé'],
                rows    : []
            }
    }),
    getters,
    actions
})
