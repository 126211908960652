import { defineStore } from 'pinia';

import getters from './getters';
import actions from './actions';

export const useEntitesStore = defineStore('entites', {
    state: () => {
        return {
            entite: {},
            entiteError: '',
            entiteUpdateReturnCode: 200,
            dataset: {
                columns : ['Actions', 'Entité', 'Désignation', 'Nb. Max col.', 'Nb. min col', 'Titre fiche', 'Entité système'],
                rows    : []
            }

        };
    },

    getters,
    actions
})