export default{

  // --- Getter for menus variable -------------------------------------------------------------- getMenus
  getMenus(){
    return this.menus;
  },

  // --- Getter for menu variable --------------------------------------------------------------- getMenu
  getMenu(){
    return this.menu;
  },

  // --- Getter for loaded variable ------------------------------------------------------------- getLoaded
  getLoaded(){
    return this.loaded;
  },

  // --- Getter for menuErrors variable --------------------------------------------------------- getMenuErrors
  getMenuErrors(){
    return this.menuErrors;
  },

  // --- Getter for menuUpdateReturnCode variable ----------------------------------------------- getMenuUpdateReturnCode
  getMenuUpdateReturnCode(){
    return this.menuUpdateReturnCode;
  },

  // --- Getter for dataset variable ------------------------------------------------------------ getDataset
  getDataset(){
    return this.dataset;
  }
}