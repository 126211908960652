import { useApplicationStore } from "../application/application";
import { useUserStore } from "../user/user";
import { useAuthStore } from "../auth/auth";
export default {

  // --- Retrieves the list of all logs from the database --------------------------------------- retrieveLogs
  async retrieveData(payload=null) {
    const applicationStore = useApplicationStore();
    const userStore        = useUserStore();
    const authStore        = useAuthStore();

    console.log('logs - actions retrieveLogs - begin');

    let serverAddress = userStore.getServerAddress;

    console.log('logs - actions retrieveLogs - serverAddress='+serverAddress);

    let url = serverAddress + "/artizen/ws/logs/listedatatable";

    if(payload!=null && Object.keys(payload).length > 0){ // -- we first verify if our payload is not null and if it's not empty
      url+="?";
      let index = 0;

      // -- we now iterate over the payload containing the search filters the user chose
      for(const data in payload){
          url+=data+"="+payload[data]; // -- data corresponds to the name of the attribute (user for instance)
                                       // -- and payload[data] gets the value in the payload linked to the property name

          if(index < Object.keys(payload).length-1){ // -- in the end we check if we reached the end of the payload or not
              url+="&";                              // -- and if it's not, we add & to continue adding query elements
          }
      }
    }
    console.log("logs - actions retrieveLogs - url="+url);

    let token = authStore.getToken;

    const response = await fetch(url,
            {
                method: 'GET',
                headers: {
                    Authorization: 'JWT ' + token
                }
            });

    if (!response.ok) {
        if(response.status==511){
                authStore.tryLogin();
            } else {
                const error = new Error('Failed to get user info. Check your login data.');
                throw error;
            }
    }

        let logsResponse = await response.text();

        console.log('********** logsResponse **********');
        console.log("logs - action retrieveLogs - logsResponse="+logsResponse);
        console.log("logs - action retrieveLogs - logsResponse.type="+typeof logsResponse);
        
        const jsonLogResponse = JSON.parse(logsResponse);
        console.log(jsonLogResponse.dataset);
        let payloadLogs = jsonLogResponse.dataset;
        applicationStore.commitSetMessage(jsonLogResponse.message);
        
        console.log("logs - action retrieveLogs - payloadLogs="+payloadLogs);
        console.log("logs - action retrieveLogs - payloadLogs.type="+typeof payloadLogs);
        
        try {
            let jsonPayloadLogs = JSON.parse(payloadLogs);
            if(jsonPayloadLogs.rows.length>0){
                console.log(jsonPayloadLogs.rows[0].code);
            } else {
                console.log('Recherche introuvable');
            }
            

            console.log("logs - action retrieveLogs - jsonPayloadLogs     ="+JSON.stringify(jsonPayloadLogs));
            console.log("logs - action retrieveLogs - jsonPayloadLogs.type="+typeof jsonPayloadLogs);
            this.setDataset(jsonPayloadLogs);

            console.log("logs - action retrieveLogs - after commit setDataSet=");

        }
        catch (e) {
            console.log("error parsing payloadLogs=" + e.toString())
        }
        finally {
            console.log('action retrieveLogs - end');
        }
  },

  // --- Setter for dataset variable ------------------------------------------------------------ setDataset
  setDataset(payload) {
    console.log('mutation parametres.setDataset - begin');
    this.dataset = payload;
    console.log('mutation parametres.setDataset='+JSON.stringify(this.dataset));
    console.log('mutation parametres.setDataset - end');
  },
}