import { defineStore } from 'pinia';

import getters from './getters';
import actions from './actions';

export const useAbonnesStore = defineStore('subscribers', {
  state: () => {
    return {
      subscribers: [],
      subscriber: {},
      subscriberErrors: null,
      subscriberUpdateReturnCode: 0,
      listedescommunes: [],
      dataset: {
        columns : ['Actions', 'Abonné', 'Désignation', 'Email', 'Actif', 'Supprimé'],
        rows    : []
      }

    };
  },

  getters,
  actions
})