export default {

  // --- Getter for the userId variable --------------------------------------------------------- getUserId
  getUserId() {
    return this.userId;
  },

  // --- Getter for the user variable ----------------------------------------------------------- getUser
  getUser() {
    return this.user;
  },

  // --- Getter for the subscriber variable ----------------------------------------------------- getSubscriber
  getSubscriber() {
    return this.subscriber;
  },

  // --- Getter for the token variable ---------------------------------------------------------- getToken
  getToken() {
    return this.token;
  },

  // --- Returns true if the current user is authenticated (so if we find a token) -------------- getIsAuthenticated
  /*
    @IF (token not null) : returns true
    @ELSE                : returns false
  */
  getIsAuthenticated() {
    console.log("isAuthenticated getter");
    console.log("state.token=");
    console.log(this.token);
    if (this.token === null || this.token<0) {
      return false;
    } else {
      return true;
    }
  },

  // --- Getter for the didAutoLogout variable -------------------------------------------------- getDidAutoLogout
  getDidAutoLogout() {
    return this.didAutoLogout;
  }
};