import { defineStore } from 'pinia'

import getters from './getters';
import actions from './actions';

export const useTablesStore = defineStore('tables', {
  state: () => ({
    tables:                [],
    table:                 {},
    loaded:                false,
    tableErrors:           null,
    tableUpdateReturnCode: 0,
    dataset:               {
        columns : ['Actions', 'Abonné', 'Table', 'Code', 'Valeur', 'Actif'],
        rows    : []
    }
  }),
  getters,
  actions,
})