
import { useUserStore        } from "../user/user.js";
import { useAuthStore        } from "../auth/auth.js"
import { useApplicationStore } from "../application/application.js";
import { useAgendaStore      } from "@/pinia/agenda/agenda";
import router from "@/router/index.js";

// - Global timer to only have one timer at a time
let timer;

export default {

  // --- Removes the user from the store -------------------------------------------------------- clearUser
  clearUser() {

    // -- Removes data from vueX
    this.commitClearUser();

    // -- Removes the previously created logout timer
    clearTimeout(timer);

    // -- Removes data from the local storage
    localStorage.removeItem('token'          );
    localStorage.removeItem('tokenExpiration');
  },

  // --- Auto logout the user after the token reach it's expiration time ------------------------ autoLogout
  autoLogout() {

    // -- Removes the user from the localStorage and vueX
    this.commitClearUser();

    /* 
    Eventually redirects the user to an allowed page by setting a value in the store that will be
    watched in the App.vue component to redirect the user.
    */

    this.commitSetDidAutoLogout(true);
  },

  // --- Calls the auth function with the login mode (not supported yet) ------------------------ login
  async login(payload) {
    return this.auth({
      ...payload,              
      mode: 'login'          
    });
  },

  // --- Calls the auth function with the signup mode (not supported yet) ----------------------- signup
  async signup(payload) {
    return this.auth({
      ...payload,               
      mode: 'signup'         
    });
  },

  // --- Allows to connect a user with the credentials entered in the login / signup form ------- auth
  // The signup mode is not supported for now so the mode (CF login/signup methods) is not used
  async auth(payload) {
    const userStore = useUserStore();
    const authStore = useAuthStore();
    const applicationStore = useApplicationStore();
    applicationStore.setDataLoaded(true);
    console.log("Début auth depuis auth store");
    let passwordEncoded = encodeURIComponent(payload.password);

    let serverAddress = userStore.getServerAddress;
    
    let url = serverAddress + '/artizen/JWTServlet?login=' + payload.subscriber + '/' + payload.user + '&password=' + passwordEncoded;

    const response = await fetch(url,
        {
          method: 'GET'
        });

    if (!response.ok) {
      const isError = (await response.text()).includes('(BLOQUE)');
      if(isError){
        throw new Error('Vous avez été bloqué, veuillez contacter un administrateur');
      } else {
        throw response.status;
      }
    }

    let token = await response.text();

    console.log('********** response.body **********');
    console.log(response.body);
    console.log('********** response.statusText **********');
    console.log(response.statusText);
    console.log('********** response.status **********');
    console.log(response.status);
    console.log('********** token **********');
    console.log(token);

    // -- Calculation of the token's expiration date
    const expiresIn = + 60 * 60 * 1000;       // - 60 minutes * 60 seconds * 1000 ms
    //const expiresIn   = 10000;
    const expirationDate = new Date().getTime() + expiresIn;
    console.log('********** expirationDate **********');
    console.log(expirationDate);

    localStorage.setItem('token',           token             );
    localStorage.setItem('subscriber',      payload.subscriber);
    localStorage.setItem('user',            payload.user      );
    localStorage.setItem('tokenExpiration', expirationDate    );

    timer = setTimeout(function() {
      authStore.autoLogout();
      }, expiresIn);

    this.setUser({
      token:      token,
      subscriber: payload.subscriber,
      user:       payload.user,
    });
  },

  // --- Tries to automatically log the user in the app if credentials can be found
  // --- in the localStorage -------------------------------------------------------------------- tryLogin
  async tryLogin() {
    const authStore        = useAuthStore();
    const userStore        = useUserStore();
    const applicationStore = useApplicationStore();
    const token           = localStorage.getItem('token');
    const subscriber      = localStorage.getItem('subscriber');
    const user            = localStorage.getItem('user');
    const tokenExpiration = localStorage.getItem('tokenExpiration');

    console.log('********** tryLogin **********');
    console.log('********** token **********');
    console.log(token);
    console.log('********** subscriber **********');
    console.log(subscriber);
    console.log('********** user **********');
    console.log(user);
    console.log('********** tokenExpiration **********');
    console.log(tokenExpiration);

    // Calculation of time remaining before the token expires
    const expiresIn = +tokenExpiration - new Date().getTime();

    console.log('********** expiresIn **********');
    console.log(expiresIn);

    // Prevents autologin if the token can't be found OR if it expired
    if (expiresIn < 0 || !token) {
      return;
    }
    // If the token is not yet expired, we create a new timer to logout
    // the user when the token expires
    timer = setTimeout(function() {
      authStore.autoLogout();
    }, expiresIn);

    if (token && subscriber && user) {
      this.setUser({
        token:      token,
        subscriber: subscriber,
        user:       user,
      });
    }
    try{
      await userStore.retrieveUser();
      await userStore.retrieveMenus();
      await userStore.retrieveFavMenus();
      await applicationStore.retrieveRoles();
      await agendaStore.retrieveParametresAffichage();
      applicationStore.setDataLoaded(true);
    } catch(error){
      if(error == 511){
        this.clearUser();
        router.push('/auth');
      } else {
        this.clearUser();
        router.push('/erreur');
      }
    }
    
  },

  // --- Setter for the current user's datas (token, subscriber, user, didAutoLogout) ----------- setUser
  setUser(payload) {
    this.commitSetToken(payload.token);
    this.commitSetSubscriber(payload.subscriber);
    this.commitSetUser(payload.user);
    this.commitSetDidAutoLogout(false);
  },
  
  // Removes the user's datas from the store ---------------------------------------------------- commitClearUser
  commitClearUser() {
    const applicationStore = useApplicationStore();
    this.commitSetToken(null);
    this.commitSetSubscriber(null);
    this.commitSetUser(null);
    this.commitSetDidAutoLogout(false);
    applicationStore.setDataLoaded(false);
  },

  // --- Setter for didAutoLogout variable ------------------------------------------------------ commitSetDidAutoLogout
  commitSetDidAutoLogout(value) {
    this.didAutoLogout = value;
  },

  // --- Setter for token variable -------------------------------------------------------------- commitSetToken
  commitSetToken(token){
    this.token = token;
  },

  // --- Setter for user variable --------------------------------------------------------------- commitSetUser
  commitSetUser(user){
    this.user = user;
  },

  // --- Setter for subscriber variable --------------------------------------------------------- commitSetSubscriber
  commitSetSubscriber(subscriber){
    this.subscriber = subscriber;
  }

};