import { defineStore } from 'pinia';

import getters   from './getters';
import actions   from './actions';

export const useAuthStore = defineStore('auth',{
  
  state: () => {
    return {
      token:         null,
      subscriber:    null,
      user:          null,
      firstName:     null,
      lastName:      null,
      didAutoLogout: false
    };
  },

  // set getters from the "getters" object
  getters: getters,

  // same thing for actions
  actions
});