export default {
    getDataset(){
        return this.dataset;
    },

    getZone(){
        return this.zone;
    },

    getZoneError() {
        return this.zoneError;
    },

    getZonesUpdateReturnCode(){
        return this.zoneUpdateReturnCode;
    },

    getTypesDeZones(){
        return this.typesDeZones;
    },

    getObjetsGraphiques(){
        return this.objetsGraphiques
    }
}
