import { useAuthStore }        from "../auth/auth";
import { useUserStore }        from "../user/user";
import { useApplicationStore } from "../application/application";

export default {

    
    // ----------------------------------------------------------------------------------
    // Completely remove the data for the current module
    // ----------------------------------------------------------------------------------
    clearModule() {
        console.log('action users.clearModule - begin');
        console.log('mutation users.clearModule - begin');

        this.users                = [];
        this.user                 = {};
        this.searchValues         = {};
        this.pageTotal            = 1;
        this.pageCurrent          = 1;
        this.pagePrevious         = 0;
        this.pageNext             = 0;
        this.userErrors           = null;
        this.userUpdateReturnCode = 0;
        this.roles                = "";
        this.loaded               = false;

        console.log('mutation users.clearModule - end');
        console.log('action users.clearModule - end');
    },

    // ----------------------------------------------------------------------------------
    // Remove the users from the store
    // ----------------------------------------------------------------------------------
    clearUsers() {
        console.log('action users.clearUsers - begin');
        console.log('mutation users.clearUsers - begin');
        this.users     = [];
        console.log('mutation users.clearUsers - end');
        console.log('action users.clearUsers - end');
    },

    // ----------------------------------------------------------------------------------
    // Remove the user from the store
    // ----------------------------------------------------------------------------------
    clearUser() {
        console.log('action users.clearUser - begin');
        console.log('mutation users.clearUser - begin');
        this.users     = [];
        console.log('mutation users.clearUser - end');
        console.log('action users.clearUser - end');
    },

    // ----------------------------------------------------------------------------------
    // Get all the roles the user can use
    // ----------------------------------------------------------------------------------
    async retrieveRoles() {
        const userStore = useUserStore();
        const authStore = useAuthStore();
        console.log('action getRoles - begin');

        // --- get the address of the web service server ------------------------------------------
        let serverAddress = userStore.getServerAddress;

        // --- construct the url ------------------------------------------------------------------
        let url = serverAddress + '/artizen/ws/utilisateurs/listeroles';

        // retrieve the token from auth store
        let token = authStore.getToken;

        // call the ws and wait for his answer
        const response = await fetch(url,
          {
              method: 'GET',
              headers: {
                  Authorization: 'JWT ' + token
              }
//        body: JSON.stringify({
//          email: payload.email,
//          password: payload.password,
//          returnSecureToken: true
//        })
          });

        // if we had an error calling the ws
        if (!response.ok) {
            if(response.status == 511){
                authStore.tryLogin();
                } else {
                    // if we had an error while calling the web service
                    const error = new Error('Failed to set new favorites. Check your login data.');
                    throw error;
                }
        }

        // retrieve the response from the ws
        let userResponse = await response.text();

        console.log('********** userResponse **********');
        console.log(userResponse);

        // parse the response as a json message
        const jsonUserResponse = JSON.parse(userResponse);

        // retrieve values from the json object
        let jsonRoles        = jsonUserResponse.listeDesRoles;
        console.log(jsonRoles);

        let rolesList = "";
        let separator = "";
        jsonRoles.forEach(jsonRole => {
            rolesList = rolesList + separator + jsonRole.role;
            separator = ",";
        })

        console.log("users - actions - rolesList = " + rolesList);

        // we store the infos in the vueX calling the users mutation
        this.setRoles(rolesList);

        console.log('action getRoles - end');
    },

    // ----------------------------------------------------------------------------------
    // Get the users with the search criteria
    // ----------------------------------------------------------------------------------
    async retrieveData(payload=null) {
        const applicationStore = useApplicationStore();
        const userStore        = useUserStore();
        const authStore        = useAuthStore();
        console.log('users - action getUser - begin');
        
        // let searchSubscriber = payload.search.subscriber;
        // let searchUser       = payload.search.user;
        // let searchLastname   = payload.search.lastname;
        // let searchFirstname  = payload.search.firstname;
        // let searchActive     = payload.search.active;
        // let searchDeleted    = payload.search.deleted;
        // let searchPage       = payload.page;

        // --- get the address of the web service server ------------------------------------------
        let serverAddress = userStore.getServerAddress;

        console.log("users - action getUser - serverAddress="+serverAddress);

        // --- construct the url ------------------------------------------------------------------
        let url = serverAddress + '/artizen/ws/utilisateurs/listedatatable';
        
        // --- sets automatically the query string for the call to the ws -------------------------
        if(payload!=null && Object.keys(payload).length > 0){ // --- we first verify if our payload is not null and if it's not empty
            url+="?";
            let index = 0;

            // --- we now iterate over the payload containing the search filters the user chose
            for(const data in payload){
                url+=data+"="+payload[data]; // --- data corresponds to the name of the attribute (user for instance)
                                             // --- and payload[data] gets the value in the payload linked to the property name

                if(index < Object.keys(payload).length-1){ // --- in the end we check if we reached the end of the payload or not
                    url+="&";                              // --- and if it's not, we add & to continue adding query elements
                }
            }
        }
        console.log("users - action getUser - url="+url);

        // --- retrieve the token from auth store -------------------------------------------------
        let token = authStore.getToken;

        // call the ws and wait for his answer
        const response = await fetch(url,
            {
                method: 'GET',
                headers: {
                    Authorization: 'JWT ' + token
                }
            });

        // if we had an error calling the ws
        if (!response.ok) {
            console.log('goes into condition');
            if (response.status == 511) {
                authStore.tryLogin();
            } else {
                const error = new Error('Failed to get user info. Check your login data.');
                throw error;
            }
        }

        // retrieve the response from the ws
        let userResponse = await response.text();

        console.log('********** userResponse **********');
        console.log("users - action getUser - userResponse="+userResponse);
        console.log("users - action getUser - userResponse.type="+typeof userResponse);
        
        // --- parse the response as a json message -----------------------------------------------
        const jsonUserResponse = JSON.parse(userResponse);
        
        let payloadUsers = jsonUserResponse.dataset;
        applicationStore.commitSetMessage(jsonUserResponse.message);
        
        console.log("users - action getUser - payloadUsers="+payloadUsers);
        console.log("users - action getUser - payloadUsers.type="+typeof payloadUsers);
        
        try {
            let jsonPayloadUsers = JSON.parse(payloadUsers);
            if(jsonPayloadUsers.rows.length>0){
                console.log(jsonPayloadUsers.rows[0].code);
            } else {
                console.log('Recherche introuvable');
            }
            

            console.log("users - action getUser - jsonPayloadUsers     ="+JSON.stringify(jsonPayloadUsers));
            console.log("users - action getUser - jsonPayloadUsers.type="+typeof jsonPayloadUsers);
            // --- set the dataset value in the context -----------------------------------------------
            this.setDataset(jsonPayloadUsers);

            console.log("users - action getUser - after commit setDataSet=");

        }
        catch (e) {
            console.log("error parsing payloadUsers=" + e.toString())
        }
        finally {
            console.log('action getUser - end');
        }

    },

    // ----------------------------------------------------------------------------------
    // Get the user from the infos in the payload
    // ----------------------------------------------------------------------------------
    async retrieveUser(payload) {
        const userStore = useUserStore();
        const authStore = useAuthStore();
        // context.commit('setUser', payload);

        // --- get the address of the web service server ------------------------------------------
        let serverAddress = userStore.getServerAddress;

        // --- construct the url ------------------------------------------------------------------
        let url = serverAddress + '/artizen/ws/utilisateurs/lire?abonne=' + payload.subscriber + '&utilisateur=' + payload.user;

        // --- retrieve the token from auth store
        let token = authStore.getToken;

        // call the ws and wait for his answer
        const response = await fetch(url,
            {
                method: 'GET',
                headers: {
                    Authorization: 'JWT ' + token
                }
            });

        // if we had an error calling the ws
        if (!response.ok) {
            if(response.status == 511){
                authStore.tryLogin();
            } else {
                const error = new Error('Failed to get user info. Check your login data.');
                throw error;
            }
        }

        // retrieve the reponse from the ws
        let userResponse = await response.text();

        console.log('********** userResponse **********');
        console.log(userResponse);

        // parse the response as a json message
        const jsonUserResponse = JSON.parse(userResponse);
        console.log(jsonUserResponse);
        // we store the infos in the vueX calling the setUser mutation
        this.commitSetUser({
            subscriber:   jsonUserResponse.abonne,
            user:         jsonUserResponse.utilisateur,
            firstname:    jsonUserResponse.prenom,
            lastname:     jsonUserResponse.nom,
            email:        jsonUserResponse.email,
            roles:        jsonUserResponse.roles,
            image:        jsonUserResponse.idImage,
            active:       (jsonUserResponse.actif==='O'),
            deleted:      (jsonUserResponse.supprime==='O'),
            editable:     (jsonUserResponse.modifiable==='O'),
            deletable:    (jsonUserResponse.supprimable==='O'),
            password:     '',
            passwordConf: '',
            selected:     false,
        });
        console.log('action getUser - fin');

    },

    // ----------------------------------------------------------------------------------
    // Delete the user from the infos in the payload
    // ----------------------------------------------------------------------------------
    async deleteElement(payload) {
        const userStore = useUserStore();
        const authStore = useAuthStore();
        console.log('action users.deleteUtilisateur - begin');
        console.log(payload);


        // --- get the address of the web service server ------------------------------------------
        let serverAddress = userStore.getServerAddress;

        // --- construct the url ------------------------------------------------------------------
        let url = serverAddress + '/artizen/ws/utilisateurs/supprimer?abonne='+ payload.subscriber + '&utilisateur=' + payload.user;

        // retrieve the token from auth store
        let token = authStore.getToken;

        // call the ws and wait for his answer
        const response = await fetch(url,
          {
              method: 'PUT',
              headers: {
                  Authorization: 'JWT ' + token
              },
              // body: JSON.stringify(
              //   {
              //       "abonne":         subscriber,
              //       "utilisateur":    user,
              //       "email":          "",
              //       "idImage":        0,
              //       "motDePasse":     "",
              //       "motDePasseConf": "string",
              //       "nom":            "",
              //       "prenom":         "",
              //       "roles":          "",
              //       "actif":          "",
              //       "supprime":       ""
              //   })
          });

        // if we had an error calling the ws
        if (!response.ok) {
            if(response.status == 511){
                authStore.tryLogin();
            } else {
                const error = new Error('Failed to get user info. Check your login data.');
                throw error;
            }
        }

        // retrieve the reponse from the ws
        let userResponse = await response.text();

        console.log('********** userResponse **********');
        console.log(userResponse);

        console.log('action users.deleteUtilisateur - end');

    },

    // ----------------------------------------------------------------------------------
    // Set the user from the infos in the payload
    // ----------------------------------------------------------------------------------
    async setUser(payload) {

        const userStore = useUserStore();
        const authStore = useAuthStore();
        console.log('action users.setUser - start');


        // --- get the address of the web service server ------------------------------------------
        let serverAddress = userStore.getServerAddress;

        // --- construct the url ------------------------------------------------------------------
        let url = serverAddress + '/artizen/ws/utilisateurs/modifier';

        // retrieve the token from auth store
        let token = authStore.getToken;

        let myActive  = (payload.active ?  'O':'N')
        let myDeleted = (payload.deleted ?  'O':'N')

        // call the ws and wait for his answer
        const response = await fetch(url,
            {
                method: 'PUT',
                headers: {
                    "Authorization": 'JWT ' + token,
                    "accept":        "application/json",
                    "Content-Type":  "application/json",
                },
                body: JSON.stringify({
                    abonne:         payload.subscriber,
                    utilisateur:    payload.user,
                    prenom:         payload.firstname,
                    nom:            payload.lastname,
                    email:          payload.email,
                    roles:          payload.roles,
                    actif:          myActive,
                    supprime:       myDeleted,
                    idImage:        2205,
                    motDePasse:     payload.password,
                    motDePasseConf: payload.passwordConf,
                })
            });

        if (!response.ok) {

            // if we had an error while calling the web service
            console.log("setUser, response is not ok");
            console.log(response);

            if (response.status === 404) {
                let errorResponse = await response.text();

                console.log("errorResponse=");
                console.log(errorResponse);

                let jsonErrorResponse = JSON.parse(errorResponse);

                console.log("jsonErrorResponse=");
                console.log(jsonErrorResponse);

                this.setUserErrors(jsonErrorResponse);
                this.setUserUpdateReturnCode(404)
            } else if(response.status == 511){
                this.setUserErrors(null);
                this.setUserUpdateReturnCode(511);
                throw 511;
            } else {
                this.setUserErrors(null);
                this.setUserUpdateReturnCode(999)
                const error = new Error('Failed to set user info. Check your login data.');
                throw error;
            }
        }
        else {
            // result is ok for the user update
            this.setUserErrors(null);
            this.setUserUpdateReturnCode(200)

            // we store the infos in the vueX calling the setUser mutation
            this.commitSetUser({
                subscriber: payload.subscriber,
                user:       payload.user,
                firstname:  payload.prenom,
                lastname:   payload.nom,
                email:      payload.email,
                roles:      payload.roles,
                active:     payload.actif,
                deleted:    payload.supprime,
                image:      payload.image,
            });
        }

        console.log('action users.setUser - end');
    },

    // ----------------------------------------------------------------------------------
    // Update the user in the module from the infos in the payload
    // ----------------------------------------------------------------------------------
    async updateUser(payload) {

        console.log('action users.updateUser - start');

        // we update the infos in the vueX calling the updateUser mutation
        this.commitUpdateUser(payload);

        console.log('action users.updateUser - end');
    },

    // ----------------------------------------------------------------------------------
    // Create the user with the infos in the payload
    // ----------------------------------------------------------------------------------
    async createUser(payload) {
        const userStore = useUserStore();
        const authStore = useAuthStore();
        console.log('action users.createUser - start');

        // --- get the address of the web service server ------------------------------------------
        let serverAddress = userStore.getServerAddress;

        // --- construct the url ------------------------------------------------------------------
        let url = serverAddress + '/artizen/ws/utilisateurs/creer';

        // --- retrieve the token from auth store -------------------------------------------------
        let token = authStore.getToken;

        let myActive  = (payload.active ?  'O':'N')
        let myDeleted = (payload.deleted ?  'O':'N')

        // --- call the ws and wait for his answer ------------------------------------------------
        const response = await fetch(url,
          {
              method: 'PUT',
              headers: {
                  "Authorization": 'JWT ' + token,
                  "accept":        "application/json",
                  "Content-Type":  "application/json",
              },
              body: JSON.stringify({
                  abonne:         payload.subscriber,
                  utilisateur:    payload.user,
                  prenom:         payload.firstname,
                  nom:            payload.lastname,
                  email:          payload.email,
                  roles:          payload.roles,
                  actif:          myActive,
                  supprime:       myDeleted,
                  idImage:        2205,
                  motDePasse:     payload.password,
                  motDePasseConf: payload.passwordConf,
              })
          });

        if (!response.ok) {

            // --- if we had an error while calling the web service -------------------------------
            console.log("createUser, response is not ok");
            console.log(response);

            if (response.status === 404) {
                let errorResponse = await response.text();

                console.log("errorResponse=");
                console.log(errorResponse);

                let jsonErrorResponse = JSON.parse(errorResponse);

                console.log("jsonErrorResponse=");
                console.log(jsonErrorResponse);

                this.setUserErrors(jsonErrorResponse);
                this.setUserUpdateReturnCode(404);
            } else if(response.status == 511){
                this.setUserErrors(null);
                this.setUserUpdateReturnCode(511);
                throw 511;
            }
            else {
                this.setUserErrors(null);
                this.setUserUpdateReturnCode(999)
                const error = new Error('Failed to set user info. Check your login data.');
                throw error;
            }
        }
        else {
            // result is ok for the user creation
            this.setUserErrors(null);
            this.setUserUpdateReturnCode(200)
        }

        console.log('action users.createUser - end');
    },


    // ----------------------------------------------------------------------------------
    // Clear the search values
    // ----------------------------------------------------------------------------------
    clearSearchValues() {
        console.log('action users.clearSearchValues - begin');
        this.setSearchValues();
        console.log('action users.clearSearchValues - end');
    },

    // ----------------------------------------------------------------------------------
    // Set the search values
    // ----------------------------------------------------------------------------------
    setSearchValues(payload=null) {
        console.log('action users.setSearchValues - begin');
        this.searchValues = payload;
        console.log('action users.setSearchValues - end');
    },

    // ----------------------------------------------------------------------------------
    // Initialize the users from the payload
    // ----------------------------------------------------------------------------------
    commitSetUsers(payload) {
        console.log('mutation users.setUsers - begin');
        this.users = payload;
        console.log('mutation users.setUsers - end');
    },

    // ----------------------------------------------------------------------------------
    // update the user object in the store
    // ----------------------------------------------------------------------------------
    commitSetUser(payload) {
        console.log('mutation users.setUser - begin');
        this.user = payload;
        console.log('mutation users.setUser - end');
    },

    // ----------------------------------------------------------------------------------
    // update the dataset object in the store
    // ----------------------------------------------------------------------------------
    setDataset(payload) {
        console.log('mutation users.setDataset - begin');
        this.dataset = payload;
        console.log('mutation users.setDataset='+JSON.stringify(this.dataset));
        console.log('mutation users.setDataset - end');
    },

    // ----------------------------------------------------------------------------------
    // update the roles object in the store
    // ----------------------------------------------------------------------------------
    setRoles(payload) {
        console.log('mutation users.setRoles - begin');
        this.roles = payload;
        console.log('mutation users.setRoles - end');
    },

    // ----------------------------------------------------------------------------------
    // update the user object in the store
    // ----------------------------------------------------------------------------------
    setUserErrors(payload) {
        console.log('mutation users.setUserErrors - begin');
        this.userErrors = payload;

        console.log("state.userErrors=");
        console.log(this.userErrors);

        console.log('mutation users.setUserErrors - end');
    },

    // ----------------------------------------------------------------------------------
    // update the userUpdateReturnCode object in the store
    // ----------------------------------------------------------------------------------
    setUserUpdateReturnCode(payload) {
        console.log('mutation users.setUserUpdateReturnCode - begin');
        this.userUpdateReturnCode = payload;

        console.log("state.userUpdateReturnCode=");
        console.log(this.userUpdateReturnCode);

        console.log('mutation users.setUserUpdateReturnCode - end');
    },

    // ----------------------------------------------------------------------------------
    // update one user in the users object of the store
    // ----------------------------------------------------------------------------------
    commitUpdateUser(payload) {
        console.log('mutation users.updateUser - begin');

        console.log(">>> users.updateUser - payload");
        console.log(payload);
        console.log(">>> dataset before changes <<<")
        console.log(this.dataset.rows);

        let localSubscriber =  payload.subscriber;
        let localUser       =  payload.user ;


        this.dataset.rows.forEach((element, index) => {
            if ((this.dataset.rows[index].abonne === localSubscriber) && (this.dataset.rows[index].code === localUser) ) {
                this.dataset.rows[index].actif = payload.active ? 'O' : 'N',
                this.dataset.rows[index].email = payload.email,
                this.dataset.rows[index].groupe = payload.roles,
                this.dataset.rows[index].nom = payload.lastname,
                this.dataset.rows[index].prenom = payload.firstname,
                this.dataset.rows[index].supprime = payload.deleted ? 'O' : 'N'
            }
        });

        console.log(">>> users.updateUser - dataset");
        console.log(this.dataset.rows);

        console.log('mutation users.updateUser - end');
    },
    // ----------------------------------------------------------------------------------
    // update the pageTotal value in the store
    // ----------------------------------------------------------------------------------
    setPageTotal(payload) {
        this.pageTotal = payload;
    },

    // ----------------------------------------------------------------------------------
    // update the pageCurrent value in the store
    // ----------------------------------------------------------------------------------
    setPageCurrent(payload) {
        this.pageCurrent = payload;
    },

    // ----------------------------------------------------------------------------------
    // update the pagePrevious value in the store
    // ----------------------------------------------------------------------------------
    setPagePrevious(payload) {
        this.pagePrevious = payload;
    },

    // ----------------------------------------------------------------------------------
    // update the pageNext value in the store
    // ----------------------------------------------------------------------------------
    setPageNext(payload) {
        this.pageNext = payload;
    },

    // ----------------------------------------------------------------------------------
    // clear the search values in the store
    // ----------------------------------------------------------------------------------
    commitClearSearchValues() {
        this.searchValues = {};
    },

};