import { defineStore } from 'pinia'

import getters from './getters';
import actions from './actions';

export const useParametresStore = defineStore('parametres', {
  state: () => ({
    tablesparametres:          [],
    parametre:                 {},
    loaded:                false,
    parametreErrors:           null,
    parametreUpdateReturnCode: 0,
    dataset:               {
        columns : ['Actions', 'Abonné', 'Utilisateur', 'Table', 'Code', 'Valeur Alpha', 'Valeur Num.', 'Actif'],
        rows    : []
    }
  }),
  getters,
  actions,
})