<template>
  <MDBTooltip v-model="showTooltip" tag="a">
    <template #reference>
      <i class="fas fa-exclamation-circle" style="color: red"></i>
    </template>
    <template #tip>
      <slot></slot>
    </template>
  </MDBTooltip>
</template>

<script>
import {MDBTooltip} from "mdb-vue-ui-kit";
import {computed, ref,watch} from "vue";
import {useModelesZonesStore} from "@/pinia/modeleszones/modeleszones";

export default {
  name: "BaseZoneError",
  components: {
    MDBTooltip
  },
  props: {
    value: {
      required: true,
      type: Object,
    },
  },
  setup(props){
    let showTooltip = ref(false);

    return {
      showTooltip
    }
  }
}
</script>

<style scoped>

</style>