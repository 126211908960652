<template>

  <MDBCard class="h-100" :text="textColorValue()" :bg="bgColorValue()" :role="roleValue()" v-on:click="menuSelected">
    <MDBCardBody>
      <MDBRow class="ms-3 me-1">
        <MDBCol col="1" class="d-flex justify-content-center align-items-center">
          <p id="icon" v-html="icon"></p>
        </MDBCol>
        <MDBCol col="10" class="mt-2 ms-3">
          <MDBCardTitle class="d-flex justify-content-between {{hauteur}}">
            {{title}}
            <div class="favIcons" v-if="name!=='retour'">
              <i class="far fa-heart" v-if="!isFavorite" @click.stop="editFavorites"></i>
              <i class="fas fa-heart" v-else @click.stop="editFavorites"></i>
            </div>
          </MDBCardTitle>
          <MDBCardText>
            {{text}}
          </MDBCardText>
        </MDBCol>
      </MDBRow>
    </MDBCardBody>
    <MDBCardFooter v-if="!!footer">
      <small >{{footer}}</small>
    </MDBCardFooter>
  </MDBCard>

</template>

<script>
// USAGE :
// <menu-card icon="admin"
//            title="Administration"
//            text="Accédez aux fonctions d'administration de votre entreprise."
//            footer="Utilisateurs, ..."
//            v-on:action="menuSelected"
// ></menu-card>

import { computed    } from 'vue';
import { ref         } from "vue";
import { defineEmits } from 'vue';
import {
  MDBCol,
  MDBRow,
  MDBCardGroup,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBCardImg,
  MDBCardFooter,
  MDBIcon
} from "mdb-vue-ui-kit";

export default {
  name: "BaseMenuCard",
  components: {
    MDBCol,
    MDBRow,
    MDBCardGroup,
    MDBCard,
    MDBCardBody,
    MDBCardTitle,
    MDBCardText,
    MDBCardImg,
    MDBCardFooter,
    MDBIcon
  },
  // props: ['name','title','text','footer'],
  props: {
    name: {
      type:     String,
      required: true,
    },
    type: {
      type:     String,
      required: true,
    },
    parent: {
      type:     String,
      required: true,
    },
    title: {
      type:     String,
      required: true,
    },
    icon:{
      type:     String,
      required: false,
      default: `<i class='fas fa-user-friends'></i>`
    },
    text: {
      type:     String,
      required: true,
    },
    footer: {
      type:     String,
      required: true,
    },
    textcolor: {
      type:     String,
      required: false,
      default:  "white",
    },
    bgcolor: {
      type:     String,
      required: false,
      default:  "primary",
    },
    bordercolor: {
      type:     String,
      required: false,
      default:  "",
    },
    path: {
      type:     String,
      required: false,
      default:  "",
    },
    tree: {
      type:     String,
      required: true,
      default:  "",
    },
    active: {
      type:     Boolean,
      required: false,
      default:  true,
    },
    isFavorite: {
      type     : Boolean,
      required : false,
      default  : false
    },
    code: {
      type     : String,
      required : false,
      default  : ''
    }
  },
  emits: ['action', 'favClicked'],

  setup(props, { emit }) {

    // --- References ----------------------------------------------------------------------------- REFERENCES
    const maVar           = ref(false);
    let icon    = ref(props.icon);
    // --- Computed ------------------------------------------------------------------------------- COMPUTED
    // -------------------------------------------------------------------------------------------- monComputed
    const monComputed = computed(function() {
      // ___ get the active menu item from the vueX context
      return 'itemAuthentication';
    });
    let hauteur = ref(!!props.footer ? 'h-100' : 'h-75');

    // --- Methods -------------------------------------------------------------------------------- METHODS
    // -------------------------------------------------------------------------------------------- roleValue
    function roleValue() {
      if (props.active) {
        return "button"
      } else {
        return "false"
      }
    }

    // -------------------------------------------------------------------------------------------- textColorValue
    function textColorValue() {
      if (props.active) {
        return props.textcolor
      }
      else {
        return ""
      }
    }
    // -------------------------------------------------------------------------------------------- bgColorValue
    function bgColorValue() {
      if (props.active) {
        return props.bgcolor
      }
      else {
        return "light"
      }
    }
    // -------------------------------------------------------------------------------------------- menuSelected
    function menuSelected() {
      if (props.active) {
        emit('action',
            { name:   props.name,
              type:   props.type,
              parent: props.parent,
              path:   props.path,
              tree:   props.tree,
            });
      }
    }

    function editFavorites(){
      emit('favClicked', {
        menuBlocCode : props.name,
        menuCode     : props.code
      })
    }

    // --- Returned objects ----------------------------------------------------------------------- RETURNED
    return {
      maVar,
      monComputed,
      roleValue,
      textColorValue,
      bgColorValue,
      menuSelected,
      icon,
      hauteur,
      editFavorites
    }
  },
};
</script>

<style scoped>
  #icon{
    font-size: 50px;
  }

</style>
