import {useAuthStore} from "@/pinia/auth/auth";
import {useUserStore} from "@/pinia/user/user";
import user from "@/views/user/User.vue";
export default {

    /*
    * Retrieves the list of all messages for the current user. --------------------------------------------------------- retrieveMessages
    * messagesLus = false : only shows the messages that hasn't been read by the user
    * messagesLus = true : show all the messages, even the ones the user already read
    * */
    async retrieveMessages(messagesLus=false){
        const authStore  = useAuthStore();
        const userStore  = useUserStore();
        const token      = authStore.getToken;
        const server     = userStore.getServerAddress;
        const user       = userStore.getUser;
        const subscriber = userStore.getSubscriber;
        // Depending on the value of messagesLus, we either call the ws with voirmessageslus=O(if messagesLus=true)
        // or with voirmessageslus=N(if messagesLus=false)
        const url        = server + '/artizen/ws/messages/liste?voirmessageslus='+(messagesLus ? 'O' : 'N')
            +'&abonne='+subscriber+'&utilisateur='+user;

        // We can now call the ws with the url we built the line above
        let response     = await fetch(url, {
            method: 'GET',
            headers: {
                Authorization: 'JWT ' + token
            }
        })
        let jsonResponse = await response.json();
        let messages = jsonResponse.listeDesElements;
        // Once we retrieved the list of messages, we change the lu status of the messages from a character (O:N) to
        // the corresponding Boolean (true:false)
        for(let message of messages){
            message.lu = message.lu=='O' ? true : false;
        }
        this.setMessagesVariable(messages);
        console.log(this.messages);

    },

    /*
    * This method calls the /combo/utilisateurs combo to retrieve a list of users which the current user can send a ---- retrieveUsers
    * message to
    * */
    async retrieveUsers(){
        const userStore = useUserStore();
        const authStore = useAuthStore();
        const token     = authStore.getToken;
        const url       = userStore.getServerAddress + '/artizen/ws/combo/utilisateurs';
        const response  = await fetch(url, {
            method: 'GET',
            headers: {
                Authorization: 'JWT ' + token
            }
        });
        let jsonResponse = await response.json();
        let usersList = [];
        let usersObjList = [];
        let i = 0;
        for(const user of jsonResponse.listeDesElements){
            usersObjList.push(user);
            usersList.push(
                {
                    value: i,
                    text: user.nom + ' - ' + user.utilisateur
                }
            )
            i++;
        }
        console.log(usersList);
        this.setUsersObj(usersObjList);
        this.setUsers(usersList);
    },

    // Retrieves the list of actions possible for the new message ONLY AVAILABLE FOR ADMINHAZ -------------------------- retrieveMessageActions
    async retrieveMessageActions(){
        const userStore = useUserStore();
        const authStore = useAuthStore();
        const token     = authStore.getToken;
        const url       = userStore.getServerAddress + '/artizen/ws/combo/htables?table=MESSAGE_ACTIONS';
        const response  = await fetch(url, {
            method: 'GET',
            headers: {
                Authorization: 'JWT ' + token
            }
        });
        const jsonResponse = await response.json();
        const actionsList  = jsonResponse.listeDesElements;
        let actions        = [];
        let i              = 0;
        for(const action of actionsList){
            actions.push({
                value: action.code,
                text: action.valeur
            })
            i++;
        }
        this.setActions(actions);
    },

    /*
    * This function allows to change the read status of a message. ----------------------------------------------------- changeMessageReadStatus
    * isRead : the old status of the message (read, unread)
    * id     : the id of the message
    * Example : We clicked on the message of id=4, which switch its read state to true,
    * we then call changeMessageReadStatus(false, 4) and it will call the marquerlu ws.
    * */
    async changeMessageReadStatus(isRead, id){
        console.log('changeMessageReadStatus');
        const authStore  = useAuthStore();
        const userStore  = useUserStore();
        const token      = authStore.getToken;
        const server     = userStore.getServerAddress;
        const user       = userStore.getUser;
        const subscriber = userStore.getSubscriber;
        // We define the web service to call depending on the value of the read state of the message.
        // if isRead is false, we call marquerlu to mark the message as read, on the opposite,
        // if isRead is true, we call marquernonlu to indicate the message should be unread
        const url        = server + '/artizen/ws/messages/'+(!isRead ? 'marquerlu' : 'marquernonlu')
            + '?abonne='+subscriber+'&utilisateur='+user+'&id='+id;

        // we call the ws with the url we built previously
        const response   = await fetch(url, {
            method: 'PUT',
            headers: {
                Authorization: 'JWT ' + token
            }
        })
        console.log(response);
    },

    // Calls the /messages/envoyer ws to send a new message to the selected users -------------------------------------- sendMessage
    async sendMessage(message){
        const userStore = useUserStore();
        const authStore = useAuthStore();
        const token     = authStore.getToken;
        const url       = userStore.getServerAddress + '/artizen/ws/messages/envoyer';
        let usersObj = [];
        for(const userCode of message.utilisateurs){
            let userObj = this.usersObj[userCode];
            usersObj.push({
                abonne: userObj.abonne,
                utilisateur: userObj.utilisateur
            })
        }
        message.utilisateurs = usersObj;
        message = JSON.stringify(message);
        console.log(message);
        const response  = await fetch(url, {
            method: 'POST',
            headers: {
                accept: 'application/json',
                Authorization: 'JWT ' + token,
                'Content-Type': 'application/json'
            },
            body: message
        })
        console.log(await response.text());
    },

    // Sets the store's messages variable ------------------------------------------------------------------------------ setMessagesVariable
    setMessagesVariable(messages){
        this.messages = messages;
    },

    // Sets the store's users variable --------------------------------------------------------------------------------- setUsers
    setUsers(users){
        this.users = users;
    },

    // Sets the store's actions variable ------------------------------------------------------------------------------- setActions
    setActions(actions){
        this.actions = actions;
    },

    // Sets the store's usersObj variable ------------------------------------------------------------------------------ setUsersObj
    setUsersObj(usersObj){
        this.usersObj = usersObj;
    },


}