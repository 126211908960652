import router from "@/router";
import { useAuthStore } from "../auth/auth";

export default {

    // ----------------------------------------------------------------------------------
    // Completely remove the data for the current module
    // ----------------------------------------------------------------------------------
    clearModule() {
        console.log('action user.clearModule - begin');
        console.log('mutation user.clearModule - begin');

        this.subscriber    = null;
        this.user          = null;
        this.firstname     = null;
        this.lastname      = null;
        this.email         = null;
        this.roles         = null;
        this.model         = null;
        this.serverAddress = null;
        this.menus         = null;

        console.log('mutation user.clearModule - end');
        console.log('action user.clearModule - end');
    },

    // ----------------------------------------------------------------------------------
    // remove the infos of the current user from the store
    // ----------------------------------------------------------------------------------
    clearUser() {
        
        // remove data from vueX by calling the setUser mutation
        const authStore = useAuthStore();
        authStore.clearUser();
    },

    // ----------------------------------------------------------------------------------
    // retrieve the infos of the current user
    // ----------------------------------------------------------------------------------
    async retrieveUser() {

        // --- get the address of the web service server ----------------------------------------------
        const authStore = useAuthStore();
        let serverAddress = this.getServerAddress;

        // --- construct the url ----------------------------------------------------------------------
        let url = serverAddress + '/artizen/ws/utilisateurs/paramsession';

        // --- retrieve the token from auth store -------------------------------------------------
        let token = authStore.getToken;

        // call the ws and wait for his answer
        const response = await fetch(url,
            {
                method: 'GET',
                headers: {
                    Authorization: 'JWT ' + token
                }
//        body: JSON.stringify({
//          email: payload.email,
//          password: payload.password,
//          returnSecureToken: true
//        })
            });

        // if we had an error calling the ws
        if (!response.ok) {
            if(response.status == 511){
                throw 511;
            } else {
                throw new Error('Error while fetching datas')
            }
        }

        // retrieve the response from the ws
        let userResponse = await response.text();

        console.log('********** userResponse **********');
        console.log(userResponse);

        // parse the response as a json message
        const jsonUserResponse = JSON.parse(userResponse);

        // we store the infos in the vueX calling the setUser mutation
        this.commitSetUser({
            subscriber: jsonUserResponse.abonne,
            user:       jsonUserResponse.utilisateur,
            firstname:  jsonUserResponse.prenom,
            lastname:   jsonUserResponse.nom,
            email:      jsonUserResponse.email,
            roles:      jsonUserResponse.roles,
            model:      jsonUserResponse.modele,
        });
    },


    // ----------------------------------------------------------------------------------
    // retrieve the menus of the current user
    // ----------------------------------------------------------------------------------
    async retrieveMenus() {
        const authStore = useAuthStore();
        // --- get the address of the web service server ----------------------------------------------
        let serverAddress = this.getServerAddress;

        // --- construct the url ----------------------------------------------------------------------
        let url = serverAddress + '/artizen/ws/menu/menu';

        // --- retrieve the token from auth store -------------------------------------------------
        let token = authStore.getToken;

        // call the ws and wait for his answer
        const response = await fetch(url,
            {
                method: 'GET',
                headers: {
                    Authorization: 'JWT ' + token
                }
//        body: JSON.stringify({
//          email: payload.email,
//          password: payload.password,
//          returnSecureToken: true
//        })
            });

        // if we had an error calling the ws
        if (!response.ok) {
            if(response.status == 511){
                throw 511;
            } else {
                throw new Error('Error while fetching datas')
            }
        }

        // --- retrieve the response from the ws --------------------------------------------------
        let menusResponse = await response.text();

        console.log('********** menusResponse **********');
        console.log(menusResponse);

        // --- parse the response as a json message -----------------------------------------------
        const jsonMenusResponse = JSON.parse(menusResponse);
        console.log(jsonMenusResponse);
        let payloadMenus = [];
        jsonMenusResponse.listeDesBlocsMenus.forEach(jsonMenu => {

            payloadMenus.push(
                {
                    menuCode:        jsonMenu.codemenu,
                    menuBlocCode:    jsonMenu.codeBlocMenu,
                    order:           jsonMenu.numeroOrdre,
                    title:           jsonMenu.enteteBloc,
                    text:            jsonMenu.designationBloc,
                    footer:          jsonMenu.piedBloc,
                    blocType:        jsonMenu.typeBloc,
                    parentMenuCode:  jsonMenu.codeMenuParent,
                    path:            jsonMenu.chemin,
                    colorBorder:     jsonMenu.couleurBordure,
                    colorBackground: jsonMenu.couleurBG,
                    colorText:       jsonMenu.couleurTexte,
                    elements:        jsonMenu.nbElements,
                    icon:            jsonMenu.icone,
                    arborescence:    jsonMenu.arborescence
                }
            )
        });

        // --- we store the infos in the vueX calling the setUser mutation ------------------------
        this.menus = payloadMenus;
    },

    async retrieveFavMenus() {
        const authStore = useAuthStore();
        console.log('Début récupération menus favoris');
        // --- get the address of the web service server ----------------------------------------------
        let serverAddress = this.getServerAddress;

        // --- construct the url ----------------------------------------------------------------------
        let url = serverAddress + '/artizen/ws/menu/favoris';

        // --- retrieve the token from auth store -------------------------------------------------
        let token = authStore.getToken;

        // call the ws and wait for his answer
        const response = await fetch(url,
            {
                method: 'GET',
                headers: {
                    "Authorization": 'JWT ' + token,
                    "accept": "application/json"
                }
//        body: JSON.stringify({
//          email: payload.email,
//          password: payload.password,
//          returnSecureToken: true
//        })
            });

        // if we had an error calling the ws
        if (!response.ok) {
            if(response.status == 511){
                throw 511;
            } else {
                throw new Error('Error while fetching datas')
            }
        }

        // --- retrieve the response from the ws --------------------------------------------------
        let menusResponse = await response.text();

        console.log('********** menusFavResponse **********');
        console.log(menusResponse);

        // --- parse the response as a json message -----------------------------------------------
        const jsonMenusResponse = JSON.parse(menusResponse);
        console.log(jsonMenusResponse);
        let payloadMenus = [];
        jsonMenusResponse.listeDesBlocsMenus.forEach(jsonMenu => {

            payloadMenus.push(
                {
                    menuCode:        jsonMenu.codemenu,
                    menuBlocCode:    jsonMenu.codeBlocMenu,
                    order:           jsonMenu.numeroOrdre,
                    title:           jsonMenu.enteteBloc,
                    text:            jsonMenu.designationBloc,
                    footer:          jsonMenu.piedBloc,
                    blocType:        jsonMenu.typeBloc,
                    parentMenuCode:  jsonMenu.codeMenuParent,
                    path:            jsonMenu.chemin,
                    colorBorder:     jsonMenu.couleurBordure,
                    colorBackground: jsonMenu.couleurBG,
                    icon:            jsonMenu.icone,
                    colorText:       jsonMenu.couleurTexte,
                    elements:        jsonMenu.nbElements,
                    arborescence:    jsonMenu.arborescence
                }
            )
        });

        // --- we store the infos in the vueX calling the setUser mutation ------------------------
        this.commitSetFavoriteMenus(payloadMenus);
    },

    async updateFavoriteMenus(payload){
        const authStore = useAuthStore();
        let serverAddress = this.getServerAddress;

        let url = serverAddress + '/artizen/ws/menu/favorismodifier';
        let menusToUpload = []
        for (const menu of payload){
            menusToUpload.push({
                codemenu: menu.menuCode,
                codeBlocMenu: menu.menuBlocCode
            })
        }

        console.log(JSON.stringify(menusToUpload));
        const listeDesFavoris = JSON.stringify({"listeDesFavoris":menusToUpload});
        console.log(listeDesFavoris);
        let token = authStore.getToken;
        
        const response = await fetch(url, 
            {
                method: 'PUT',
                headers: {
                    "Authorization": 'JWT ' + token,
                    "accept":        "application/json",
                    "Content-Type":  "application/json",
                },
                body: JSON.stringify({
                    'listeDesFavoris': menusToUpload
                })
            }
            )
            if (!response.ok) {
                if(response.status == 511){
                    throw 511;
                } else {
                    // if we had an error while calling the web service
                    const error = new Error('Failed to set new favorites. Check your login data.');
                    throw error;
                }

            }
    
            // we retrive the response
            console.log(response);
    
            // console.log('********** response.body **********');
            // console.log(response.body);
            // console.log('********** response.statusText **********');
            // console.log(response.statusText);
            // console.log('********** response.status **********');
            // console.log(response.status);
            // console.log('********** userResponse **********');
            // console.log(userResponse);
    
            // user is modified, we store the infos in the vueX
            this.commitSetFavoriteMenus(payload)
            console.log('action setUser - end');
    },


    async setUser(payload) {
        const authStore = useAuthStore();
        console.log('action setUser - start');

        // --- get the address of the web service server ------------------------------------------
        let serverAddress = this.getServerAddress;

        // --- construct the url ------------------------------------------------------------------
        let url = serverAddress + '/artizen/ws/utilisateurs/' + payload.subscriber + '/' + payload.user;

        console.log('User url:');
        console.log(url);

        let token = authStore.getToken;

        let myActive = (payload.active ?  'O':'N')

        const response = await fetch(url,
            {
                method: 'PUT',
                headers: {
                    Authorization: 'JWT ' + token
                },
                body: JSON.stringify({
                    abonne:      payload.subscriber,
                    utilisateur: payload.user,
                    nom:         payload.lastname,
                    prenom:      payload.firstname,
                    email:       payload.email,
                    roles:       '',
                    idImage:     -1,
                    actif:       myActive,
                })
            });

        if (!response.ok) {
            if(response.status == 511){
                throw 511;
            } else {
                throw new Error('Error while fetching datas')
            }

        }

        // we retrive the response
        let userResponse = await response.text();

        console.log('********** response.body **********');
        console.log(response.body);
        console.log('********** response.statusText **********');
        console.log(response.statusText);
        console.log('********** response.status **********');
        console.log(response.status);
        console.log('********** userResponse **********');
        console.log(userResponse);

        // user is modified, we store the infos in the vueX
        this.commitSetUser({
            subscriber: payload.subscriber,
            user:       payload.user,
            firstname:  payload.prenom,
            lastname:   payload.nom,
            email:      payload.email,
            roles:      payload.roles,
            image:      -1,
            active:     payload.actif
        });
        console.log('action setUser - end');
    },


    // --------------------------------------------------------------------------------------------
    // Initialize the user from the payload
    // --------------------------------------------------------------------------------------------
    commitSetUser(payload) {

        console.log('mutation setUser - begin');
        this.subscriber    = payload.subscriber;
        this.user          = payload.user;
        this.firstname     = payload.firstname;
        this.lastname      = payload.lastname;
        this.email         = payload.email;
        this.roles         = payload.roles;
        this.model         = payload.model;
        console.log('mutation setUser - end');
    },

    commitSetFavoriteMenus(payload){
        this.favoriteMenus = payload;
    },

    setSubscribedAs(subscriber){
        this.subscribedAs = subscriber;
    }

};